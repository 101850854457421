import React from 'react'
import { CRow, CCol, CWidgetProgressIcon } from '@coreui/react'
import numeral from 'numeral'

const OneTimeKPI = (props) => {
  const { styleWithData, data1, data2 } = props

  return (
    <>
      <CRow>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data1 && data2
                ? `${numeral(data1.totalRevenue).format('$ 0.00 a')} -->${numeral(
                    data2.totalRevenue,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Total Revenue"
            value="100"
            color={styleWithData.pcTotalRevenue ? styleWithData.pcTotalRevenue : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scTotalRevenue ? styleWithData.scTotalRevenue : 'grey',
              }}
            >
              {styleWithData.totalRevenuePercent !== 0
                ? styleWithData.totalRevenuePercentBehavior === 'down'
                  ? `${styleWithData.totalRevenuePercent} %`
                  : `${styleWithData.totalRevenuePercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={data1 && data2 ? `${data1.totalOrders} -->${data2.totalOrders}` : 'NaN'}
            text="Total Orders"
            value="100"
            color={styleWithData.pcTotalOrders ? styleWithData.pcTotalOrders : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scTotalOrders ? styleWithData.scTotalOrders : 'grey',
              }}
            >
              {styleWithData.totalOrdersPercent !== 0
                ? styleWithData.totalOrdersPercentBehavior === 'down'
                  ? `${styleWithData.totalOrdersPercent} %`
                  : `${styleWithData.totalOrdersPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data1 && data2
                ? `${numeral(data1.totalCost).format('$ 0.00 a')} -->${numeral(
                    data2.totalCost,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Total Cost"
            value="100"
            color={styleWithData.pcTotalOrders ? styleWithData.pcTotalOrders : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scTotalCost ? styleWithData.scTotalCost : 'grey',
              }}
            >
              {styleWithData.totalCostPercent !== 0
                ? styleWithData.totalCostPercentBehavior === 'down'
                  ? `${styleWithData.totalCostPercent} %`
                  : `${styleWithData.totalCostPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data1 && data2
                ? `${numeral(data1.totalProfit).format('$ 0.00 a')} -->${numeral(
                    data2.totalProfit,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Total Profit"
            value="100"
            color={styleWithData.pcTotalProfit ? styleWithData.pcTotalProfit : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scTotalProfit ? styleWithData.scTotalProfit : 'grey',
              }}
            >
              {styleWithData.totalProfitPercent !== 0
                ? styleWithData.totalProfitPercentBehavior === 'down'
                  ? `${styleWithData.totalProfitPercent} %`
                  : `${styleWithData.totalProfitPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data1 && data2
                ? `${numeral(data1.spendPerCustomer).format('$ 0.00 a')} -->${numeral(
                    data2.spendPerCustomer,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Spend Per Customer"
            value="100"
            color={
              styleWithData.pcSpendPerCustomer ? styleWithData.pcSpendPerCustomer : 'secondary'
            }
          >
            <h4
              style={{
                color: styleWithData.scSpendPerCustomer ? styleWithData.scSpendPerCustomer : 'grey',
              }}
            >
              {styleWithData.spendPerCustomerPercent !== 0
                ? styleWithData.spendPerCustomerPercentBehavior === 'down'
                  ? `${styleWithData.spendPerCustomerPercent} %`
                  : `${styleWithData.spendPerCustomerPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={data1 && data2 ? `${data1.totalContacts} -->${data2.totalContacts}` : 'NaN'}
            text="Total Contacts"
            value="100"
            color={styleWithData.pcTotalContacts ? styleWithData.pcTotalContacts : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scTotalContacts ? styleWithData.scTotalContacts : 'grey',
              }}
            >
              {styleWithData.totalContactsPercent !== 0
                ? styleWithData.totalContactsPercentBehavior === 'down'
                  ? `${styleWithData.totalContactsPercent} %`
                  : `${styleWithData.totalContactsPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data1 && data2
                ? `${numeral(data1.totalRevenueByFilterDaily).format('$ 0.00 a')} -->${numeral(
                    data2.totalRevenueByFilterDaily,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Revenue By Filter Daily"
            value="100"
            color={
              styleWithData.pcTotalRevenueByFilterDaily
                ? styleWithData.pcTotalRevenueByFilterDaily
                : 'secondary'
            }
          >
            <h4
              style={{
                color: styleWithData.scTotalRevenueByFilterDaily
                  ? styleWithData.scTotalRevenueByFilterDaily
                  : 'grey',
              }}
            >
              {styleWithData.totalRevenueByFilterDailyPercent !== 0
                ? styleWithData.totalRevenueByFilterDailyBehavior === 'down'
                  ? `${styleWithData.totalRevenueByFilterDailyPercent} %`
                  : `${styleWithData.totalRevenueByFilterDailyPercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm="6" md="2">
          <CWidgetProgressIcon
            header={
              data2
                ? `${data2.refundValue.value1Percent} % -->${data2.refundValue.value2Percent} %`
                : 'NaN'
            }
            text="Refund Last 7 Days"
            value="100"
            color={styleWithData.pcRefundValue ? styleWithData.pcRefundValue : 'secondary'}
          >
            <h4
              style={{
                color: styleWithData.scRefundValue ? styleWithData.scRefundValue : 'grey',
              }}
            >
              {styleWithData.refundValuePercent !== 0
                ? styleWithData.refundValueBehavior === 'down'
                  ? `${styleWithData.refundValuePercent} %`
                  : `${styleWithData.refundValuePercent} %`
                : 'NaN'}
            </h4>
          </CWidgetProgressIcon>
        </CCol>
      </CRow>
    </>
  )
}

export default OneTimeKPI
