import React, { useState, useEffect } from 'react'
import Modal from '../components/Modal'
import ModalInput from '../components/ModalInput'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@coreui/coreui/dist/css/coreui.css'
import { EVENT_DELETE, EVENT_ADD } from '../constant/Operations'
import { SETTING_LABEL } from '../constant/SettingsType'
import ModalSelect from '../components/ModalSelect'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import {
  backupLabelOperation,
  findBackupData,
  findSettingsByType,
  uploadBackup,
} from '../api/apiCalls'
import CIcon from '@coreui/icons-react'

toast.configure()
const EmailBackupPage = () => {
  // Modal Types
  const MODAL_CREATE_LABEL = 'MODAL_CREATE_LABEL'
  const MODAL_BACKUP_UPLOAD = 'MODAL_BACKUP_UPLOAD'

  const [isShow, setIsShow] = useState({
    MODAL_CREATE_LABEL: false,
    MODAL_BACKUP_UPLOAD: false,
  })

  const [notFound, setNotFound] = useState(false)
  const [labels, setLabels] = useState([])
  const [isDisabledUpload, setIsDisabledUpload] = useState(true)
  const [labelsTableData, setLabelsTableData] = useState([])
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [currentFile, setCurrentFile] = useState(undefined)
  const [selected, setSelected] = useState({
    labelName: {
      value: null,
      label: null,
    },
  })
  const [label, setLabel] = useState({
    labelName: null,
  })

  // Error
  const [inputError, setInputError] = useState({
    labelNameError: null,
  })

  // Table Integration
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(100)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tableFilterValue, setTableFilterValue] = useState('')
  /*const [columnFilterValue, setColumnFilterValue] = useState();
	const [sorterValue, setSorterValue] = useState();
	const [fetchTrigger, setFetchTrigger] = useState(0);*/
  const params = {
    page,
    itemsPerPage,
    tableFilterValue,
  }

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  const pendingApiCall = useApiProgress(API.API_BACKUP_UPLOAD)

  useEffect(() => {
    loadSettings()
  }, [])

  useEffect(() => {
    loadBackupData()
  }, [page, itemsPerPage, tableFilterValue])

  if (pendingApiCall) {
    return <Spinner />
  }

  const loadSettings = () => {
    const getSettings = async () => {
      try {
        const response = await findSettingsByType(SETTING_LABEL)
        createSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSettings()
  }

  const loadBackupData = async () => {
    setLoading(true)
    try {
      const response = await findBackupData(params)
      setItems(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const createSettingsTable = (data) => {
    let tableData = []
    let labels = data.properties['labels']
    for (var i = 0; i < labels.length; i++) {
      var tableObj = {
        label: labels[i],
      }
      tableData.push(tableObj)
    }
    setLabelsTableData(tableData)
    setLabels(labels)
  }

  const onClickUploadData = () => {
    if (selectedFiles) {
      upload()
    } else {
      notify('Please select file!', 'w')
    }
  }

  const handleLabelEvent = (event, label) => {
    const labelEvent = async (event, label) => {
      try {
        await backupLabelOperation(event, label)
        clearError()
        loadSettings()
        hide(MODAL_CREATE_LABEL)
        notify('Label ' + event + ' Succesfull!', 's')
      } catch (error) {
        hide(MODAL_CREATE_LABEL)
        clearError()
        notify('Error received while ' + event + ' label!', 'e')
      }
    }
    labelEvent(event, label)
  }

  // MODAL OPERATIONS
  const show = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: true }))
  const hide = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: false }))

  const onChange = (evt) => {
    const { name, value } = evt.target
    if (isShow[MODAL_CREATE_LABEL]) {
      setLabel((newLabel) => ({ ...newLabel, [name]: value }))
    } else if (isShow[MODAL_BACKUP_UPLOAD]) {
    }
  }

  const onChangeSelect = (evt, selectType) => {
    if (selectType === 'labelName') {
      setSelected({
        ...selected,
        labelName: evt,
      })
      setIsDisabledUpload(false)
    } else if (selectType === 'file') {
      setSelected({
        ...selected,
      })
      selectFile(evt)
    }
  }

  const openModal = (modalType) => {
    if (modalType === MODAL_CREATE_LABEL) {
      setLabel({
        labelName: null,
      })
    } else if (modalType === MODAL_BACKUP_UPLOAD) {
    }
    clearError()
    show(modalType)
  }

  const closeModal = (modalType) => {
    if (modalType === MODAL_CREATE_LABEL) {
      setLabel({
        labelName: null,
      })
    } else if (modalType === MODAL_BACKUP_UPLOAD) {
      setSelected({
        ...selected,
        labelName: {
          value: null,
          label: null,
        },
      })
      setIsDisabledUpload(true)
    }
    clearError()
    hide(modalType)
  }

  const clearError = () => {
    if (isShow[MODAL_CREATE_LABEL]) {
      setInputError({
        labelName: null,
      })
    } else if (isShow[MODAL_BACKUP_UPLOAD]) {
      setCurrentFile(undefined)
    }
  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
  }

  const upload = async () => {
    let currentFile = selectedFiles[0]
    setCurrentFile(currentFile)

    try {
      await uploadBackup(selected.labelName.value, currentFile)
      clearError()
      loadSettings()
      loadBackupData()
      hide(MODAL_BACKUP_UPLOAD)
      notify('File upload succesfull!', 's')
    } catch (error) {
      hide(MODAL_BACKUP_UPLOAD)
      clearError()
      notify('Could not upload the file!', 'e')
      setCurrentFile(undefined)
    }

    setCurrentFile(undefined)
  }

  const fields = [
    { key: 'label', _style: { width: '90%' } },
    {
      key: 'event',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div>
        <CCard>
          <CCardHeader className="text-right">
            <CButton size="sm" color="info" onClick={() => openModal(MODAL_BACKUP_UPLOAD)}>
              Upload Data
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CTabs activeTab="backup_list">
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="backup_list">Active Email Users</CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink data-tab="labels">Labels</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="backup_list">
                  <CButton
                    className="mt-2"
                    size="sm"
                    color="primary"
                    href={`${API.BACKEND_HOST_PROD}api/1.0/backup/download`}
                    target="_blank"
                  >
                    <CIcon inline className="mr-1" name="cil-cloud-download" size="lg" />
                    Download Backup
                  </CButton>
                  <CDataTable
                    size="sm"
                    items={items}
                    fields={['firstName', 'lastName', 'email', 'label']}
                    loading={loading}
                    hover
                    cleaner
                    //columnFilter={{ external: true }}
                    //columnFilterValue={columnFilterValue}
                    //onColumnFilterChange={setColumnFilterValue}
                    tableFilter={{ external: true }}
                    tableFilterValue={tableFilterValue}
                    onTableFilterChange={setTableFilterValue}
                    sorter
                    //sorterValue={sorterValue}
                    //onSorterValueChange={setSorterValue}
                    itemsPerPageSelect={{ external: true }}
                    itemsPerPage={itemsPerPage}
                    onPaginationChange={setItemsPerPage}
                  />
                  <CPagination
                    size="sm"
                    pages={pages}
                    activePage={page}
                    onActivePageChange={setPage}
                    className={pages < 2 ? 'd-none' : ''}
                  />
                </CTabPane>
                <CTabPane data-tab="labels">
                  <CCard className="mt-2">
                    <CCardHeader className="text-right">
                      <CButton size="sm" color="info" onClick={() => openModal(MODAL_CREATE_LABEL)}>
                        Create Label
                      </CButton>
                    </CCardHeader>
                    <CCardBody>
                      <CDataTable
                        size="sm"
                        items={labelsTableData}
                        fields={fields}
                        hover
                        scopedSlots={{
                          event: (item) => (
                            <td className="py-2">
                              <CButton
                                color="danger"
                                size="sm"
                                onClick={() => {
                                  handleLabelEvent(EVENT_DELETE, item.label)
                                }}
                              >
                                Delete
                              </CButton>
                            </td>
                          ),
                        }}
                      ></CDataTable>
                    </CCardBody>
                  </CCard>
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>

        <Modal
          title="Create Label"
          show={isShow[MODAL_CREATE_LABEL]}
          onHide={() => closeModal(MODAL_CREATE_LABEL)}
          onClickSave={() => handleLabelEvent(EVENT_ADD, label.labelName)}
          body={
            <div className="container">
              <ModalInput
                name="labelName"
                label="Label Name"
                onChange={(evt) => onChange(evt)}
                defaultValue={label.labelName}
                error={inputError.labelNameError}
              ></ModalInput>
            </div>
          }
        />

        <CModal onClosed={() => closeModal(MODAL_BACKUP_UPLOAD)} show={isShow[MODAL_BACKUP_UPLOAD]}>
          <CModalHeader closeButton>Upload Email Data</CModalHeader>
          <CModalBody>
            <div className="container">
              <ModalSelect
                name="labelName"
                label="Label"
                placeHolder="Choose Label.."
                onChange={(evt) => onChangeSelect(evt, 'labelName')}
                options={labels.map((item) => {
                  return { value: item, label: item }
                })}
                value={selected.labelName}
              />

              {!isDisabledUpload && (
                <div>
                  <label className="btn btn-default">
                    <input type="file" onChange={(evt) => onChangeSelect(evt, 'file')} />
                  </label>
                </div>
              )}
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={onClickUploadData}>
              Save
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    </div>
  )
}

export default EmailBackupPage
