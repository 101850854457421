import React from 'react'
import { CChartBar } from '@coreui/react-chartjs'

const brandInfo = '#f87979'

const RefundBarChart = (props) => {
  const { refundData } = props
  let chartLabels = []
  let labelsWithString = []

  const defaultDatasets = (() => {
    let yAxis = []

    if (refundData.length > 0) {
      chartLabels = Object.keys(refundData.refundData)
      let chartValue = []
      for (var i = 0; i < chartLabels.length; i++) {
        chartValue.push(refundData.refundData[chartLabels[i]].percent)
      }

      for (i = 0; i < chartLabels.length; i++) {
        labelsWithString.push('Week of ' + chartLabels[i])
      }

      let element = {
        label: 'Refund percent',
        backgroundColor: brandInfo,
        pointHoverBackgroundColor: brandInfo,
        barPercentage: 0.5,
        categoryPercentage: 1,
        data: chartValue,
      }
      yAxis.push(element)
    }

    return yAxis
  })()

  const defaultOptions = (() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: true,
          },
        ],
        yAxes: [
          {
            display: true,
          },
        ],
      },
    }
  })()

  // render
  return (
    <CChartBar
      {...props}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={labelsWithString}
    />
  )
}

export default RefundBarChart
