import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@coreui/coreui/dist/css/coreui.css'
import { findContactData, unsubscribeContact } from '../api/apiCalls'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

toast.configure()
const ContactUnsubscribePage = () => {
  const [notFound, setNotFound] = useState(false)

  // Table Integration
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(100)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tableFilterValue, setTableFilterValue] = useState('')

  const params = {
    page,
    itemsPerPage,
    tableFilterValue,
  }

  useEffect(() => {
    setNotFound(false)
  }, [])

  useEffect(() => {
    loadContactData()
  }, [page, itemsPerPage, tableFilterValue])

  const loadContactData = async () => {
    setLoading(true)
    try {
      const response = await findContactData(params)
      setItems(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleUnsubscribeContact = async (item) => {
    setLoading(true)
    try {
      const res = await unsubscribeContact(item.id)
      notify(res.data.message, 's')
      loadContactData()
    } catch (error) {
      setLoading(false)
      notify('While unsubscribing got an error! Please get in touch with admin!!', 's')
    }
  }

  const fields = [
    { key: 'firstName', _style: { width: '20%' } },
    { key: 'lastName', _style: { width: '20%' } },
    { key: 'phone', _style: { width: '20%' } },
    { key: 'subscribed', _style: { width: '10%' } },
    { key: 'sentSmsCount', label: 'SMS Count', _style: { width: '10%' } },
    { key: 'clicker', _style: { width: '10%' } },
    {
      key: 'event',
      label: 'Unsubscribe',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div>
        <CCard>
          <CCardHeader className="text-right">
            <CButton
              className="btn-sm mr-2"
              size="sm"
              color="info"
              onClick={() => loadContactData()}
            >
              Refresh List
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CTabs activeTab="contacts">
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="contacts">Contacts</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="contacts">
                  <CDataTable
                    size="sm"
                    items={items}
                    fields={fields}
                    loading={loading}
                    hover
                    cleaner
                    tableFilter={{ external: true }}
                    tableFilterValue={tableFilterValue}
                    onTableFilterChange={setTableFilterValue}
                    sorter
                    itemsPerPageSelect={{ external: true }}
                    itemsPerPage={itemsPerPage}
                    onPaginationChange={setItemsPerPage}
                    scopedSlots={{
                      event: (item) => (
                        <td className="py-2 text-center">
                          <CButton
                            disabled={!item.subscribed}
                            variant="outline"
                            shape="square"
                            color="danger"
                            size="sm"
                            onClick={() => {
                              handleUnsubscribeContact(item)
                            }}
                          >
                            <CIcon size="lg" name="cil-x-circle" />
                          </CButton>
                        </td>
                      ),
                    }}
                  />
                  <CPagination
                    size="sm"
                    pages={pages}
                    activePage={page}
                    onActivePageChange={setPage}
                    className={pages < 2 ? 'd-none' : ''}
                  />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>
      </div>
    </div>
  )
}

export default ContactUnsubscribePage
