import React, { useEffect, useState } from 'react'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import { findUpdateValues, updateMaintenanceReport } from '../api/apiCalls'
import { useTranslation } from 'react-i18next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'

toast.configure()
const ReportsMaintenance = () => {
  const [reports, setReports] = useState([])
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    setNotFound(false)
  }, [reports])

  const pendingApiCall = useApiProgress(API.API_REPORT_MAINTENANCE_LIST)
  const pendingApiCall2 = useApiProgress(API.API_REPORT_MAINTENANCE)

  useEffect(() => {
    handleRefreshList()
  }, [])

  const { t } = useTranslation()

  if (pendingApiCall || pendingApiCall2) {
    return <Spinner />
  }

  const currencyFormatter = (number) => {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
  }

  const handleRefreshList = () => {
    const applyFilter = async () => {
      try {
        const response = await findUpdateValues()
        notify(response.data.message, 's')
        setReports(response.data.data)
      } catch (error) {}
    }
    applyFilter()
  }

  const updateReportRow = (oldValue, newValue, row, column) => {
    const updateRow = async (row) => {
      try {
        const response = await updateMaintenanceReport(row)
        notify(response.data.message, 's')
      } catch (error) {}
    }

    if (isNaN(newValue)) {
      notify('You entered an incorrect value!', 'e')
    } else {
      updateRow(row)
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columns = [
    {
      dataField: 'funnelName',
      filter: textFilter(),
      text: 'Funnel',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'profitPercent',
      text: 'Net Revenue Calculator',
      editable: true,
      sort: true,
      type: 'number',
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        return currencyFormatter(cell) + '$'
      },
    },
    {
      dataField: 'adCost',
      text: 'Ad Cost',
      editable: true,
      sort: true,
      type: 'number',
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        return currencyFormatter(cell) + '$'
      },
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        if (cell) {
          let dateObj = cell
          if (dateObj) {
            if (typeof cell !== 'object') {
              dateObj = new Date(cell)
            }
            return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
              '0' + dateObj.getUTCDate()
            ).slice(-2)}/${dateObj.getUTCFullYear()}`
          }
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
  ]

  const options = {
    sizePerPageList: [
      {
        text: '200',
        value: 200,
      },
      {
        text: 'All',
        value: reports != null ? reports.length : 0,
      },
    ],
  }

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Reports not found!')}
        </div>
      </div>
    )
  }

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm text-left">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={handleRefreshList}
          >
            Refresh List
          </button>
        </div>
      </div>

      <hr />
      <div className="container">
        <ToolkitProvider keyField="id" data={reports} columns={columns} search>
          {(props) => (
            <div>
              <BootstrapTable
                striped={true}
                hover={true}
                condensed={true}
                pagination={paginationFactory(options)}
                filter={filterFactory()}
                cellEdit={cellEditFactory({
                  mode: 'click',
                  afterSaveCell: (oldValue, newValue, row, column) => {
                    updateReportRow(oldValue, newValue, row, column)
                  },
                })}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default ReportsMaintenance
