import Container from 'react-bootstrap/Container'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'

const SmsMessage = (props) => {
  const { label, inputText, handleChange, limit, rows, name, disabled } = props

  const calculateCount = () => {
    return Math.floor(inputText.length / 153) + 1
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Form>
            <Form.Group className="mb-3" controlId={name}>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                disabled={disabled}
                as="textarea"
                rows={rows}
                value={inputText}
                onChange={handleChange}
                isInvalid={inputText.length > limit}
              />
              <Badge
                className="mt-3 mr-2"
                bg={`${inputText.length > limit ? 'danger' : 'primary'}`}
              >
                {inputText.length}/{limit}
              </Badge>
              <Badge className="mt-3" bg={`${calculateCount() > 1 ? 'danger' : 'primary'}`}>
                SMS Count: {calculateCount()}
              </Badge>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default SmsMessage
