import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import { deleteJobHistory, findJobHistory, findJobs, scheduleJob } from '../api/apiCalls'
import { Alert, ButtonGroup } from 'react-bootstrap'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import ConfirmModal from '../components/ConfirmModal'
import CIcon from '@coreui/icons-react'

toast.configure()
const SchedulerPage = () => {
  const [jobs, setJobs] = useState([])
  const [jobHistories, setJobHistories] = useState([])
  const [deleteModalShow, setDeleteModelShow] = useState(false)
  const [selectedDeleteRow, setSelectedDeleteRow] = useState({})
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    setNotFound(false)
  }, [])

  const pendingApiCall = useApiProgress(API.API_JOB_FIND_JOBS)
  const pendingApiCall2 = useApiProgress(API.API_JOB_HISTORY_FIND_JOB_HISTORIES)
  const pendingApiCall3 = useApiProgress(API.API_SCHEDULER_JOB)

  useEffect(() => {
    refreshJobs()
    refreshJobHistories()
  }, [])

  if (pendingApiCall || pendingApiCall2 || pendingApiCall3) {
    return <Spinner />
  }

  const deleteShow = () => setDeleteModelShow(true)
  const deleteHide = () => setDeleteModelShow(false)

  const deleteModalClose = () => {
    deleteHide()
  }

  // On change methods

  const refreshJobs = () => {
    const loadJobs = async () => {
      try {
        const response = await findJobs()
        notify(response.data.message, 's')
        setJobs(response.data.data)
      } catch (error) {
        notify('Not found any scheduler job data', 'e')
      }
    }
    loadJobs()
  }

  const refreshJobHistories = () => {
    const loadJobHistories = async () => {
      try {
        const response = await findJobHistory()
        notify(response.data.message, 's')
        setJobHistories(response.data.data)
      } catch (error) {
        notify('Not found any job histories data', 'e')
      }
    }
    loadJobHistories()
  }

  const handleScheduler = (clickStatus, row) => {
    const addScheduler = async (row) => {
      const body = {
        job: row,
        dateTime: null,
        timeZone: 'America/New_York',
      }

      try {
        const response = await scheduleJob(body)
        notify(response.data.message, 's')
        refreshJobHistories()
      } catch (error) {
        notify('Not found any job scheduler data', 'e')
      }
    }
    addScheduler(row)
  }

  const handleDeleteHistory = () => {
    const deleteHistory = async (row) => {
      try {
        const response = await deleteJobHistory(row.id)
        notify(response.data.message, 's')
        deleteModalClose()
        refreshJobHistories()
      } catch (error) {
        deleteModalClose()
        notify('Error deleting job history!', 'e')
      }
    }
    deleteHistory(selectedDeleteRow)
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const statusBadge = (cell, row) => {
    if (row.jobStatus === 'scheduled') {
      return <span className="badge badge-pill badge-warning">Scheduled</span>
    } else if (row.jobStatus === 'in_progress') {
      return <span className="badge badge-pill badge-success">In Progress</span>
    } else if (row.jobStatus === 'completed') {
      return <span className="badge badge-pill badge-primary">Completed</span>
    }
  }

  const columnCustomButtonsJob = (cell, row) => {
    return (
      <ButtonGroup className="my-custom-class" sizeclass="btn-group-sm">
        <button
          className="btn btn-info"
          data-toggle="tooltip"
          data-placement="top"
          title="Schedule Job"
          size="sm"
          onClick={(evt) => {
            handleScheduler(true, row)
          }}
        >
          <CIcon size="lg" name="cilHistory" style={{ marginRight: '6px' }} />
          Schedule Job
        </button>
      </ButtonGroup>
    )
  }

  const columnsJob = [
    {
      dataField: 'jobName',
      text: 'Job Name',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'columnCustomButtons',
      text: 'Schedule',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '20px' }
      },
      formatter: columnCustomButtonsJob,
    },
  ]

  const columnCustomButtonsJobHistory = (cell, row) => {
    return (
      <ButtonGroup className="my-custom-class" sizeclass="btn-group-sm">
        <button
          className="btn btn-danger d-inline-flex "
          data-toggle="tooltip"
          data-placement="top"
          title="Delete"
          size="sm"
          disabled={row.jobStatus !== 'completed' ? true : false}
          onClick={(evt) => {
            setSelectedDeleteRow(row)
            deleteShow()
          }}
        >
          <CIcon size="lg" name="cil-x-circle" />
        </button>
      </ButtonGroup>
    )
  }

  const columnsJobHistory = [
    {
      dataField: 'jobName',
      text: 'Job Name',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'jobStartTime',
      text: 'Start Time',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'jobEndTime',
      text: 'End Time',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'jobStatus',
      text: 'Status',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '60px' }
      },
      formatter: statusBadge,
    },
    {
      dataField: 'columnCustomButtons',
      text: '',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '35px' }
      },
      formatter: columnCustomButtonsJobHistory,
    },
  ]

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm text-left">
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={refreshJobs}>
            Refresh Job List
          </button>
        </div>
      </div>
      <hr />
      <div>
        <ToolkitProvider keyField="id" data={jobs} columns={columnsJob} search>
          {(props) => (
            <div>
              <BootstrapTable striped={true} hover={true} condensed={true} {...props.baseProps} />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm text-left">
          <div>
            <Alert
              className="d-inline-flex"
              key="1"
              variant="warning"
              style={{ marginBottom: '10px' }}
            >
              <i className="material-icons">warning</i>
              Job history list will be automatically deleted every day, also user can delete it
              manually.
            </Alert>
          </div>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={refreshJobHistories}
          >
            Refresh Job History List
          </button>
        </div>
      </div>
      <hr />
      <div>
        <ToolkitProvider keyField="id" data={jobHistories} columns={columnsJobHistory} search>
          {(props) => (
            <div>
              <BootstrapTable
                striped={true}
                hover={true}
                condensed={true}
                pagination={paginationFactory()}
                filter={filterFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>

      <ConfirmModal
        title="Delete Job History"
        show={deleteModalShow}
        onHide={deleteModalClose}
        onClickYes={handleDeleteHistory}
        onClickNo={deleteModalClose}
        body={<div className="container">Are you sure you want to do this ?</div>}
      ></ConfirmModal>
    </div>
  )
}

export default SchedulerPage
