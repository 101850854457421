import React from 'react'
import { CCol, CRow, CWidgetDropdown } from '@coreui/react'
import ChartLineSimple from '../charts/ChartLineSimple'
import CIcon from '@coreui/icons-react'
import Spinner from '../../components/Spinner'
import ChartBarSimple from '../charts/ChartBarSimple'

const SmsKPI = (props) => {
  const { data, status } = props
  const defaultDataPoints = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  if (status) {
    return <Spinner />
  }

  return (
    <>
      <CRow>
        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-primary"
            header={
              data && data.totalSubscriber && data.totalSubscriber !== 0
                ? `${data.totalSubscriber}`
                : 'NaN'
            }
            text="Total Subscriber"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.totalSubscriberDataPoints && data.totalSubscriberDataPoints.length !== 0
                      ? data.totalSubscriberDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Subscribers"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-people" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-info"
            header={
              data && data.totalRevenue && data.totalRevenue !== 0 ? `${data.totalRevenue}` : 'NaN'
            }
            text="Total Revenue"
            footerSlot={
              <>
                <ChartBarSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.totalRevenueDataPoints && data.totalRevenueDataPoints.length !== 0
                      ? data.totalRevenueDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Revenue"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-money" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-warning"
            header={
              data && data.totalMessagesSent && data.totalMessagesSent !== 0
                ? `${data.totalMessagesSent}`
                : 'NaN'
            }
            text="Total Messages"
            footerSlot={
              <>
                <ChartBarSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.totalMessagesSentDataPoints &&
                    data.totalMessagesSentDataPoints.length !== 0
                      ? data.totalMessagesSentDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Sms Message"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-envelope-closed" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-danger"
            header={
              data && data.newSubscribers && data.newSubscribers !== 0
                ? `${data.newSubscribers}`
                : 'NaN'
            }
            text="New Subscriber"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.newSubscribersDataPoints && data.newSubscribersDataPoints.length !== 0
                      ? data.newSubscribersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="New Subscriber"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-people" height="30" />
          </CWidgetDropdown>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-dark"
            header={
              data && data.unsubscribers && data.unsubscribers !== 0
                ? `${data.unsubscribers}`
                : 'NaN'
            }
            text="Unsubscribers"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.unsubscribersDataPoints && data.unsubscribersDataPoints.length !== 0
                      ? data.unsubscribersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Unsubscriber"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-user-unfollow" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-primary"
            header={data && data.clicker && data.clicker !== 0 ? `${data.clicker}` : 'NaN'}
            text="Clicker"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.clickerDataPoints && data.clickerDataPoints.length !== 0
                      ? data.clickerDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Clicker"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-cursor" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="3">
          <CWidgetDropdown
            color="gradient-info"
            header={data && data.delivered && data.delivered !== 0 ? `${data.delivered}` : 'NaN'}
            text="Delivered"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.deliveredDataPoints && data.deliveredDataPoints.length !== 0
                      ? data.deliveredDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Delivered"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-check-circle" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="5" lg="3">
          <CWidgetDropdown
            color="gradient-dark"
            header={
              data && data.undelivered && data.undelivered !== 0 ? `${data.undelivered}` : 'NaN'
            }
            text="Undelivered"
            footerSlot={
              <>
                <ChartBarSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data.undeliveredDataPoints && data.undeliveredDataPoints.length !== 0
                      ? data.undeliveredDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Undelivered"
                  labels="days"
                />
              </>
            }
          >
            <CIcon name="cil-x-circle" height="30" />
          </CWidgetDropdown>
        </CCol>
      </CRow>
    </>
  )
}

export default SmsKPI
