import React, { useState, useEffect } from 'react'
import Modal from '../components/Modal'
import ModalInput from '../components/ModalInput'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@coreui/coreui/dist/css/coreui.css'
import CIcon from '@coreui/icons-react'
import { EMAIL_REGEX } from '../constant/ValidationRegex'
import {
  createBlockEmail,
  deleteBlockById,
  findEmailBlockData,
  uploadEmailBlock,
} from '../api/apiCalls'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'

toast.configure()
const EmailBlockPage = () => {
  // Modal Types
  const MODAL_CREATE_EMAIL_BLOCK = 'MODAL_CREATE_EMAIL_BLOCK'
  const MODAL_MASS_EMAIL_BLOCK_UPLOAD = 'MODAL_MASS_EMAIL_BLOCK_UPLOAD'

  const [isShow, setIsShow] = useState({
    MODAL_CREATE_EMAIL_BLOCK: false,
    MODAL_MASS_EMAIL_BLOCK_UPLOAD: false,
  })

  const [notFound, setNotFound] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [currentFile, setCurrentFile] = useState(undefined)

  const [data, setData] = useState({
    email: null,
  })

  // Error
  const [inputError, setInputError] = useState({
    emailError: null,
  })

  // Table Integration
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(100)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tableFilterValue, setTableFilterValue] = useState('')

  const params = {
    page,
    itemsPerPage,
    tableFilterValue,
  }

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  const pendingApiCall = useApiProgress(API.API_EMAIL_BLOCK_UPLOAD)

  useEffect(() => {
    loadEmailBlockData()
  }, [page, itemsPerPage, tableFilterValue])

  if (pendingApiCall) {
    return <Spinner />
  }

  const loadEmailBlockData = async () => {
    setLoading(true)
    try {
      const response = await findEmailBlockData(params)
      setItems(response.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleCreateEmailBlock = async () => {
    if (validation(data.email)) {
      hide(MODAL_CREATE_EMAIL_BLOCK)
      setLoading(true)
      try {
        const response = await createBlockEmail(data)
        notify(response.data.message, response.data.code)
        loadEmailBlockData()
        clearError()
        hide(MODAL_CREATE_EMAIL_BLOCK)
        setLoading(false)
      } catch (error) {
        clearError()
        hide(MODAL_CREATE_EMAIL_BLOCK)
        setLoading(false)
        notify('Error received while block email!', 'e')
      }
    }
  }

  const handleDeleteBlockedUser = async (item) => {
    setLoading(true)
    try {
      await deleteBlockById(item.id)
      loadEmailBlockData()
    } catch (error) {
      setLoading(false)
    }
  }

  // MODAL OPERATIONS
  const show = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: true }))
  const hide = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: false }))

  const onChange = (evt) => {
    const { name, value } = evt.target
    if (isShow[MODAL_CREATE_EMAIL_BLOCK]) {
      setData((newLabel) => ({ ...newLabel, [name]: value }))
    } else if (isShow[MODAL_MASS_EMAIL_BLOCK_UPLOAD]) {
    }

    validation(value)
  }

  const validation = (value) => {
    if (value) {
      const emailRegex = new RegExp(EMAIL_REGEX)
      if (!emailRegex.test(value)) {
        setInputError({
          emailError: 'Please enter a valid email',
        })
        return false
      } else {
        clearError()
        return true
      }
    } else {
      setInputError({
        emailError: 'Please enter a valid email',
      })
      return false
    }
  }

  const onChangeSelect = (evt, selectType) => {
    if (selectType === 'file') {
      selectFile(evt)
    }
  }

  const openModal = (modalType) => {
    if (modalType === MODAL_CREATE_EMAIL_BLOCK) {
      setData({
        email: null,
      })
    } else if (modalType === MODAL_MASS_EMAIL_BLOCK_UPLOAD) {
    }
    clearError()
    show(modalType)
  }

  const closeModal = (modalType) => {
    if (modalType === MODAL_CREATE_EMAIL_BLOCK) {
      setData({
        email: null,
      })
    } else if (modalType === MODAL_MASS_EMAIL_BLOCK_UPLOAD) {
    }
    clearError()
    hide(modalType)
  }

  const clearError = () => {
    if (isShow[MODAL_CREATE_EMAIL_BLOCK]) {
      setInputError({
        emailError: null,
      })
    } else if (isShow[MODAL_MASS_EMAIL_BLOCK_UPLOAD]) {
      setCurrentFile(undefined)
    }
  }

  const onClickUploadData = () => {
    if (selectedFiles) {
      upload()
    } else {
      notify('Please select file!', 'w')
    }
  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
  }

  const upload = async () => {
    let currentFile = selectedFiles[0]
    setCurrentFile(currentFile)

    setLoading(true)
    try {
      await uploadEmailBlock(currentFile)
      clearError()
      loadEmailBlockData()
      hide(MODAL_MASS_EMAIL_BLOCK_UPLOAD)
      notify('File upload succesfull!', 's')
    } catch (error) {
      hide(MODAL_MASS_EMAIL_BLOCK_UPLOAD)
      clearError()
      notify('Could not upload the file!', 'e')
      setCurrentFile(undefined)
    }
  }

  const fields = [
    { key: 'email', _style: { width: '50%' } },
    { key: 'createDate', _style: { width: '40%' } },
    {
      key: 'event',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <div>
        <CCard>
          <CCardHeader className="text-right">
            <CButton
              className="btn-sm mr-2"
              size="sm"
              color="info"
              onClick={() => openModal(MODAL_CREATE_EMAIL_BLOCK)}
            >
              Enter Email
            </CButton>
            <CButton
              size="sm"
              color="info"
              onClick={() => openModal(MODAL_MASS_EMAIL_BLOCK_UPLOAD)}
            >
              Upload Bulk Email
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CTabs activeTab="email_block_list">
              <CNav variant="tabs">
                <CNavItem>
                  <CNavLink data-tab="email_block_list">Email Block Users</CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent>
                <CTabPane data-tab="email_block_list">
                  <CDataTable
                    size="sm"
                    items={items}
                    fields={fields}
                    loading={loading}
                    hover
                    cleaner
                    tableFilter={{ external: true }}
                    tableFilterValue={tableFilterValue}
                    onTableFilterChange={setTableFilterValue}
                    sorter
                    itemsPerPageSelect={{ external: true }}
                    itemsPerPage={itemsPerPage}
                    onPaginationChange={setItemsPerPage}
                    scopedSlots={{
                      createDate: (row) => (
                        <td className="py-2">
                          {`${('0' + (new Date(row.createDate).getUTCMonth() + 1)).slice(-2)}/${(
                            '0' + new Date(row.createDate).getUTCDate()
                          ).slice(-2)}/${new Date(row.createDate).getUTCFullYear()}`}
                        </td>
                      ),
                      event: (item) => (
                        <td className="py-2">
                          <CButton
                            variant="outline"
                            shape="square"
                            color="danger"
                            size="sm"
                            onClick={() => {
                              handleDeleteBlockedUser(item)
                            }}
                          >
                            <CIcon size="lg" name="cil-x-circle" />
                          </CButton>
                        </td>
                      ),
                    }}
                  />
                  <CPagination
                    size="sm"
                    pages={pages}
                    activePage={page}
                    onActivePageChange={setPage}
                    className={pages < 2 ? 'd-none' : ''}
                  />
                </CTabPane>
              </CTabContent>
            </CTabs>
          </CCardBody>
        </CCard>

        <Modal
          title="Block Email"
          show={isShow[MODAL_CREATE_EMAIL_BLOCK]}
          onHide={() => closeModal(MODAL_CREATE_EMAIL_BLOCK)}
          onClickSave={() => handleCreateEmailBlock()}
          body={
            <div className="container">
              <ModalInput
                name="email"
                label="Email"
                onChange={(evt) => onChange(evt)}
                defaultValue={data.email}
                error={inputError.emailError}
              ></ModalInput>
            </div>
          }
        />

        <CModal
          onClosed={() => closeModal(MODAL_MASS_EMAIL_BLOCK_UPLOAD)}
          show={isShow[MODAL_MASS_EMAIL_BLOCK_UPLOAD]}
        >
          <CModalHeader closeButton>Upload Bulk Email</CModalHeader>
          <CModalBody>
            <div className="container">
              <div>
                <label className="btn btn-default">
                  <input type="file" onChange={(evt) => onChangeSelect(evt, 'file')} />
                </label>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="success" onClick={onClickUploadData}>
              Save
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    </div>
  )
}

export default EmailBlockPage
