import React from 'react'

const ProgressBar = (props) => {
  const { bgcolor } = props

  const containerStyles = {
    height: 15,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 10,
  }

  const fillerStyles = {
    height: '100%',
    width: '100%',
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  )
}

export default ProgressBar
