import React, { useEffect, useState } from 'react'
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CCollapse,
  CRow,
} from '@coreui/react'
import { searchDashboard } from '../api/apiCalls'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as API from '../api/PathConstants'
import ProgressBar from '../components/ProgressBar'
import moment from 'moment'
import Select from 'react-select'
import WidgetsKPI from '../views/widgets/WidgetsKPI'

import RevenueLineChart from '../views/charts/RevenueLineChart'
import ProfitLineChart from '../views/charts/ProfitLineChart'
import SalesListOfWeek from '../views/list/SalesListOfWeek'
import { useTranslation } from 'react-i18next'
import { useApiProgress } from '../shared/ApiProgress'
import Spinner from '../components/Spinner'

import dummyData from '../views/list/DashboardData'
import { DateRangePicker } from 'react-dates'
import RefundBarChart from '../views/charts/RefundBarChart'

const today = moment()

const Dashboard = () => {
  // set value for default selection
  const [dashboardData, setDashboardData] = useState(dummyData)
  const [revenueSelectedValue, setRevenueSelectedValue] = useState([])
  const [profitSelectedValue, setProfitSelectedValue] = useState([])
  const [revenueFunnels, setRevenueFunnels] = useState([])
  const [profitFunnels, setProfitFunnels] = useState([])
  const [accordion, setAccordion] = useState(1)
  const [cbMembership, setCbMembership] = useState(true)
  const [cbOnetime, setCbOnetime] = useState(true)
  const [notFound, setNotFound] = useState(false)

  // First Filter State
  const [firstFilterDates, setFirstFilterDates] = useState({
    startDate: moment().subtract(2, 'month').subtract(1, 'day'),
    endDate: moment().subtract(1, 'month').subtract(1, 'day'),
  })
  const [firstFocusedInput, setFirstFocusedInput] = useState()

  // Second Filter State
  const [secondFilterDates, setSecondFilterDates] = useState({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
  })
  const [secondFocusedInput, setSecondFocusedInput] = useState()

  // Labels
  const [optionRevenue, setOptionRevenue] = useState('Week'.toLowerCase())
  const [optionProfit, setOptionProfit] = useState('Week'.toLowerCase())

  useEffect(() => {
    setNotFound(false)
  }, [])

  useEffect(() => {
    handleFilterSearch()
  }, [])

  const { t } = useTranslation()

  const currencyFormatter = (number) => {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
  }

  const pendingApiCall = useApiProgress(API.API_DASHBOARD)

  if (pendingApiCall) {
    return <Spinner />
  }

  const handleFirstDateChange = (startDate, endDate) => {
    console.log(startDate)
    console.log(endDate)
    setFirstFilterDates({
      ...firstFilterDates,
      startDate: moment(startDate),
      endDate: moment(endDate),
    })
  }

  const handleSecondDateChange = (startDate, endDate) => {
    console.log(startDate)
    console.log(endDate)
    setSecondFilterDates({
      ...secondFilterDates,
      startDate: moment(startDate),
      endDate: moment(endDate),
    })
  }

  const onClickRevenueViewOption = (evt) => {
    let str = evt.currentTarget.innerText
    setOptionRevenue(str.toLowerCase())
  }

  const onClickProfitViewOption = (evt) => {
    let str = evt.currentTarget.innerText
    setOptionProfit(str.toLowerCase())
  }

  const handleFilterSearch = () => {
    const search = async () => {
      try {
        let filter = {
          startDate: firstFilterDates.startDate,
          endDate: firstFilterDates.endDate,
          startDate2: secondFilterDates.startDate,
          endDate2: secondFilterDates.endDate,
          showMembershipData: cbMembership,
          showOnetimeData: cbOnetime,
        }
        const response = await searchDashboard(filter)
        let data = response.data.data
        setDashboardData(data)
        notify(response.data.message, 's')
      } catch (error) {
        setNotFound(true)
        notify('Not found any dashboard data!', 'e')
      }
    }
    search()
  }

  const createRevenueFunnel = (funnel) => (
    <CCol>
      <div className="text-muted">{funnel.label}</div>
      <ProgressBar bgcolor={funnel.color}></ProgressBar>
    </CCol>
  )

  const createRevenueFunnels = () => {
    return revenueFunnels.map(createRevenueFunnel)
  }

  const createProfitFunnel = (funnel) => (
    <CCol>
      <div className="text-muted">{funnel.label}</div>
      <ProgressBar bgcolor={funnel.color}></ProgressBar>
    </CCol>
  )

  const createProfitFunnels = () => {
    return profitFunnels.map(createProfitFunnel)
  }

  // handle onChange event of the dropdown
  const handleRevenueSelectedChange = (e) => {
    setRevenueSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])

    // Create Revenue funnels
    setRevenueFunnels(
      Array.isArray(e) ? e.map((x) => ({ label: x.label, color: x.color, value: x.value })) : [],
    )
    createRevenueFunnels()
  }

  const handleProfitSelectedChange = (e) => {
    setProfitSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])

    // Create Revenue funnels
    setProfitFunnels(
      Array.isArray(e) ? e.map((x) => ({ label: x.label, color: x.color, value: x.value })) : [],
    )
    createProfitFunnels()
  }

  const handleMembershipCbChange = (event) => {
    setCbMembership(!cbMembership)
  }

  const handleOnetimeCbChange = (event) => {
    setCbOnetime(!cbOnetime)
  }

  const presets = [
    {
      text: 'Previous Week',
      start: moment().add(-14, 'day'),
      end: moment().add(-7, 'day'),
    },
    {
      text: 'Month to Date',
      start: moment().add(-today.toDate().getDay(), 'day'),
      end: today,
    },
    {
      text: 'Last 30 Days',
      start: moment().add(-30, 'day'),
      end: today,
    },
    {
      text: 'Last 2 Months',
      start: moment().add(-2, 'month'),
      end: today,
    },
  ]

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div
      className="c-body"
      style={{
        background: '#ebedef',
      }}
    >
      <div style={{ margin: '20px' }}>
        <CCard>
          <CCardHeader id="headingOne">
            <CButton
              block
              className="text-left m-0 p-0"
              onClick={() => setAccordion(accordion === 0 ? null : 0)}
            >
              <h5 className="m-0 p-0">Filter</h5>
            </CButton>
          </CCardHeader>
          <CCollapse show={accordion === 0} className="mt-3">
            <CCardBody>
              <CRow>
                <CCol sm="3">
                  <DateRangePicker
                    startDate={firstFilterDates.startDate}
                    startDateId="firstStartDate"
                    endDate={firstFilterDates.endDate}
                    endDateId="firstEndDate"
                    isOutsideRange={() => null}
                    onDatesChange={({ startDate, endDate }) => {
                      handleFirstDateChange(startDate, endDate)
                    }}
                    focusedInput={firstFocusedInput}
                    onFocusChange={(focusedInput) => setFirstFocusedInput(focusedInput)}
                  />
                </CCol>
                <CCol sm="4"></CCol>
                <CCol sm="5">
                  <div className="float-right">
                    <DateRangePicker
                      startDate={secondFilterDates.startDate}
                      startDateId="secondStartDate"
                      endDate={secondFilterDates.endDate}
                      endDateId="secondEndDate"
                      isOutsideRange={() => null}
                      onDatesChange={({ startDate, endDate }) => {
                        handleSecondDateChange(startDate, endDate)
                      }}
                      focusedInput={secondFocusedInput}
                      onFocusChange={(focusedInput) => setSecondFocusedInput(focusedInput)}
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="3"></CCol>
                <CCol sm="4"></CCol>
                <CCol sm="5">
                  <div className="col-sm text-right" style={{ marginTop: '10px' }}>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-md"
                      onClick={handleFilterSearch}
                    >
                      Search
                    </button>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
        <WidgetsKPI
          styleWithData={dashboardData.styleWithData ? dashboardData.styleWithData : {}}
          membershipData1={cbMembership ? dashboardData.membershipData : dummyData.membershipData}
          membershipData2={cbMembership ? dashboardData.membershipData2 : dummyData.membershipData2}
          onetimeData1={cbOnetime ? dashboardData.onetimeData : dummyData.onetimeData}
          onetimeData2={cbOnetime ? dashboardData.onetimeData2 : dummyData.onetimeData2}
          cbOnetime={cbOnetime}
          cbMembership={cbMembership}
        />
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 id="sales" className="card-title mb-0">
                  Revenue
                </h4>
                <div className="small text-muted"></div>
              </CCol>
              <CCol>
                <CButtonGroup className="float-right mr-3 ml-3">
                  {['Day', 'Week', 'Month'].map((value) => (
                    <CButton
                      onClick={onClickRevenueViewOption.bind(this)}
                      color="outline-secondary"
                      key="revenue"
                      className="mx-0"
                      active={value.toLowerCase() === optionRevenue}
                    >
                      {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <RevenueLineChart
              option={optionRevenue}
              revenueFunnels={revenueFunnels}
              revenueData={
                dashboardData.chartData.revenue.revenueData ? dashboardData.chartData.revenue : []
              }
              style={{ height: '400px', marginTop: '40px' }}
            />
            <Select
              className="mt-3"
              placeholder="Select Revenue Funnel.."
              value={
                dashboardData.chartData
                  ? dashboardData.chartData.revenue.revenueData
                    ? dashboardData.chartData.revenue.revenueData.filter((obj) =>
                        revenueSelectedValue.includes(obj.value),
                      )
                    : 'No Data!'
                  : 'No Data!'
              }
              options={
                dashboardData.chartData
                  ? dashboardData.chartData.revenue.revenueData
                    ? dashboardData.chartData.revenue.revenueData
                    : []
                  : []
              }
              onChange={handleRevenueSelectedChange}
              isMulti
              isClearable
            />
            <CCardFooter className="mt-3">
              <CRow className="text-center">{createRevenueFunnels()}</CRow>
            </CCardFooter>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 id="cost" className="card-title mb-0">
                  Profit
                </h4>
                <div className="small text-muted"></div>
              </CCol>
              <CCol sm="7" className="d-none d-md-block">
                <CButtonGroup className="float-right mr-3">
                  {['Day', 'Week', 'Month'].map((value) => (
                    <CButton
                      onClick={onClickProfitViewOption.bind(this)}
                      color="outline-secondary"
                      key="profit"
                      className="mx-0"
                      active={value.toLowerCase() === optionProfit}
                    >
                      {value}
                    </CButton>
                  ))}
                </CButtonGroup>
              </CCol>
            </CRow>
            <ProfitLineChart
              option={optionProfit}
              profitFunnels={profitFunnels}
              profitData={
                dashboardData.chartData.profit.profitData ? dashboardData.chartData.profit : []
              }
              style={{ height: '400px', marginTop: '40px' }}
            />
            <Select
              className="mt-3"
              placeholder="Select Profit Funnel.."
              value={
                dashboardData.chartData
                  ? dashboardData.chartData.profit.profitData
                    ? dashboardData.chartData.profit.profitData.filter((obj) =>
                        profitSelectedValue.includes(obj.value),
                      )
                    : 'No Data!'
                  : 'No Data!'
              }
              options={
                dashboardData.chartData
                  ? dashboardData.chartData.profit.profitData
                    ? dashboardData.chartData.profit.profitData
                    : []
                  : []
              }
              onChange={handleProfitSelectedChange}
              isMulti
              isClearable
            />
            <CCardFooter className="mt-3">
              <CRow className="text-center">{createProfitFunnels()}</CRow>
            </CCardFooter>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="5">
                <h4 id="traffic" className="card-title mb-0">
                  Refund
                </h4>
              </CCol>
            </CRow>
            <RefundBarChart
              refundData={
                dashboardData.chartData.refund.refundData ? dashboardData.chartData.refund : []
              }
              style={{ height: '400px', marginTop: '40px' }}
            />
          </CCardBody>
          <CCardFooter>
            <CRow className="text-center">
              {dashboardData.chartData.refund.reasons
                ? dashboardData.chartData.refund.reasons.map((reason) => {
                    return (
                      <CCol md sm="12" className="mb-sm-2 mb-0">
                        <div className="text-muted">{reason.label}</div>
                        <strong>
                          ${currencyFormatter(reason.amount)} - % {reason.percent}
                        </strong>
                      </CCol>
                    )
                  })
                : []}
            </CRow>
          </CCardFooter>
        </CCard>
        <SalesListOfWeek
          salesData={dashboardData.listData.salesList ? dashboardData.listData.salesList : []}
        />
      </div>
    </div>
  )
}

export default Dashboard
