import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonGroup } from 'react-bootstrap'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import {
  findCheckSubscriptionWithPagination,
  findSubscriptionWithPagination,
  updateView,
} from '../api/apiCalls'

toast.configure()
const SubscriptionsPage = () => {
  // Table Integration
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(20)
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [tableFilterValue, setTableFilterValue] = useState('')
  const [activeTab, setActiveTab] = useState('subscriptions')
  const [notFound, setNotFound] = useState(false)

  const [checkSubscriptions, setCheckSubscriptions] = useState([])
  const [selected, setSelected] = useState([])
  const [currentItems, setCurrentItems] = useState([])
  const checkSubscriptionsData = checkSubscriptions.map((item, id) => {
    const _selected = selected.includes(id)
    return {
      ...item,
      id,
      _selected,
      _classes: [item._classes, _selected && 'table-selected'],
    }
  })

  // Check Subscriptions Filter state
  const [checkPage, setCheckPage] = useState(0)
  const [checkPages, setCheckPages] = useState(20)
  const [checkItemsPerPage, setCheckItemsPerPage] = useState(100)
  const [checkTableFilterValue, setCheckTableFilterValue] = useState('')

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    refreshSubscriptionData()
  }, [page, itemsPerPage, tableFilterValue])

  useEffect(() => {
    refreshCheckSubscriptionData()
    setSelected([])
  }, [checkPage, checkItemsPerPage, checkTableFilterValue])

  const refreshSubscriptionData = () => {
    const loadSubscriptions = async () => {
      setLoading(true)
      try {
        const response = await findSubscriptionWithPagination(tableFilterValue, page, itemsPerPage)
        setPages(response.data.totalPages)
        setSubscriptions(response.data.data)
        setLoading(false)
      } catch (error) {
        setNotFound(true)
        notify('Not found any data', 'e')
      }
    }
    loadSubscriptions()
  }

  const refreshCheckSubscriptionData = () => {
    const loadCheckSubscriptions = async () => {
      setLoading(true)
      try {
        const response = await findCheckSubscriptionWithPagination(
          checkTableFilterValue,
          checkPage,
          checkItemsPerPage,
        )
        setCheckPages(response.data.totalPages)
        setCheckSubscriptions(response.data.data)
        setLoading(false)
      } catch (error) {
        setNotFound(true)
        notify('Not found any data', 'e')
      }
    }
    loadCheckSubscriptions()
  }

  const sendUpdateCheckSubscriptionView = () => {
    setLoading(true)
    let checkIds = []
    for (var i = 0; i < selected.length; i++) {
      checkIds.push(currentItems[selected[i]].checkId)
    }
    const sendUpdate = async () => {
      try {
        await updateView(checkIds)
        notify('Update Successfull!', 's')
        setSelected([])
        refreshCheckSubscriptionData()
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notify('Receive an error while updating views!', 'e')
      }
    }
    sendUpdate()
  }

  const check = (e, id) => {
    if (e.target.checked) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((itemId) => itemId !== id))
    }
  }

  const checkAll = (e) => {
    if (e.target.checked) {
      setSelected(currentItems.map((item) => item.id))
    } else {
      setSelected([])
    }
  }

  const columnCustomButtons = (row) => {
    return (
      <ButtonGroup className="my-custom-class" sizeclass="btn-group-sm">
        <Link to={`/subscriptionsUpdate/${row.id}`} key={row.id}>
          <div>
            <button className="btn btn-success" data-toggle="tooltip" data-placement="top">
              <CIcon size="lg" name="cil-pencil" />
            </button>
          </div>
        </Link>
        <Link to={`/transactionDetail/${row.subscriptionId}`} key={row.subscriptionId}>
          <div>
            <button className="btn btn-primary" data-toggle="tooltip" data-placement="top">
              <CIcon size="lg" name="cil-wallet" />
            </button>
          </div>
        </Link>
      </ButtonGroup>
    )
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const statusBadge = (row) => {
    if (row.check === 'matched_system') {
      return <span className="badge badge-pill badge-success">MATCHED</span>
    } else if (row.check === 'not_include_clickfunnel') {
      return <span className="badge badge-pill badge-danger">NOT INCLUDED</span>
    } else if (row.check === 'include_clickfunnel') {
      return <span className="badge badge-pill badge-warning">INCLUDED</span>
    }
  }

  const fieldsSubscriptions = [
    { key: 'createdAt', _style: { width: '5%' } },
    { key: 'email', _style: { width: '10%' } },
    { key: 'fullName', _style: { width: '10%' } },
    { key: 'address1', _style: { width: '10%' } },
    { key: 'phone', _style: { width: '7%' } },
    { key: 'productNames', _style: { width: '10%' } },
    { key: 'status', _style: { width: '1%' } },
    {
      key: 'buttons',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const fieldsCheckSubscriptions = [
    { key: 'select', label: '', filter: false, _style: { width: '1%' } },
    { key: 'subscriptionId', _style: { width: '10%' } },
    { key: 'fullName', _style: { width: '10%' } },
    { key: 'email', _style: { width: '10%' } },
    { key: 'transactionCreationDate', _style: { width: '10%' } },
    { key: 'status', _style: { width: '1%' } },
    { key: 'check', label: 'System State', _style: { width: '5%' } },
  ]

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Subscriptions not found!')}
        </div>
      </div>
    )
  }

  return (
    <div className="ml-auto" style={{ padding: '40px' }}>
      <CTabs activeTab="subscriptions" onActiveTabChange={setActiveTab}>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab="subscriptions">Subscription Transactions</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab="validations">Transaction Validations</CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane data-tab="subscriptions">
            <CCard>
              <CCardBody>
                <CDataTable
                  items={subscriptions}
                  fields={fieldsSubscriptions}
                  loading={loading}
                  hover
                  cleaner
                  tableFilter={{ external: true }}
                  tableFilterValue={tableFilterValue}
                  onTableFilterChange={setTableFilterValue}
                  sorter
                  itemsPerPageSelect={{
                    label: 'Items per page:',
                    values: [10, 20, 50, 100],
                  }}
                  itemsPerPage={itemsPerPage}
                  onPaginationChange={setItemsPerPage}
                  scopedSlots={{
                    createdAt: (row) => (
                      <td className="py-2">
                        {`${('0' + (new Date(row.createdAt).getUTCMonth() + 1)).slice(-2)}/${(
                          '0' + new Date(row.createdAt).getUTCDate()
                        ).slice(-2)}/${new Date(row.createdAt).getUTCFullYear()}`}
                      </td>
                    ),
                    buttons: (row) => <td className="py-2">{columnCustomButtons(row)}</td>,
                  }}
                  columnFilter
                ></CDataTable>
                <CPagination
                  size="sm"
                  pages={pages}
                  activePage={page}
                  onActivePageChange={setPage}
                  className={pages < 2 ? 'd-none' : ''}
                />
              </CCardBody>
            </CCard>
          </CTabPane>
          <CTabPane data-tab="validations">
            <CCard>
              <CCardHeader>
                <CButton
                  className="btn btn-outline-primary btn-sm"
                  size="sm"
                  onClick={sendUpdateCheckSubscriptionView}
                >
                  Don't show me selected records!
                </CButton>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  items={checkSubscriptionsData}
                  fields={fieldsCheckSubscriptions}
                  loading={loading}
                  hover
                  cleaner
                  sorter
                  tableFilter={{ external: true }}
                  tableFilterValue={checkTableFilterValue}
                  onTableFilterChange={setCheckTableFilterValue}
                  onFilteredItemsChange={setCurrentItems}
                  itemsPerPageSelect={{
                    label: 'Items per page:',
                    values: [10, 20, 50, 100],
                  }}
                  itemsPerPage={checkItemsPerPage}
                  onPaginationChange={setCheckItemsPerPage}
                  scopedSlots={{
                    select: (item) => {
                      return (
                        <td>
                          <CFormGroup variant="custom-checkbox">
                            <CInputCheckbox
                              custom
                              id={`checkbox${item.id}`}
                              checked={item._selected}
                              onChange={(e) => check(e, item.id)}
                            />
                            <CLabel variant="custom-checkbox" htmlFor={`checkbox${item.id}`} />
                          </CFormGroup>
                        </td>
                      )
                    },
                    transactionCreationDate: (row) => (
                      <td className="py-2">
                        {`${('0' + (new Date(row.createdAt).getUTCMonth() + 1)).slice(-2)}/${(
                          '0' + new Date(row.createdAt).getUTCDate()
                        ).slice(-2)}/${new Date(row.createdAt).getUTCFullYear()}`}
                      </td>
                    ),
                    check: (row) => <td className="py-2">{statusBadge(row)}</td>,
                  }}
                  columnFilter
                  columnFilterSlot={{
                    select: (
                      <CFormGroup className="ml-2" variant="custom-checkbox">
                        <CInputCheckbox custom id="checkAll" onChange={checkAll} />
                        <CLabel variant="custom-checkbox" htmlFor="checkAll" />
                      </CFormGroup>
                    ),
                  }}
                ></CDataTable>
                <CPagination
                  size="sm"
                  pages={checkPages}
                  activePage={checkPage}
                  onActivePageChange={setCheckPage}
                  className={checkPages < 2 ? 'd-none' : ''}
                />
              </CCardBody>
            </CCard>
          </CTabPane>
        </CTabContent>
      </CTabs>
    </div>
  )
}

export default SubscriptionsPage
