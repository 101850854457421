import React from 'react'
import { CChartLine } from '@coreui/react-chartjs'

const ProfitLineChart = (props) => {
  const { profitData, option, profitFunnels } = props
  let chartLabels = []

  const defaultDatasets = (() => {
    let yAxis = []

    if (profitData) {
      if (profitFunnels) {
        for (let j = 0; j < profitFunnels.length; j++) {
          var labelArray = profitData.data[profitFunnels[j].value][profitFunnels[j].label][option]

          if (labelArray) {
            chartLabels = Object.keys(labelArray)
            let chartData = Object.values(labelArray)

            let element = {
              label: profitFunnels[j].label,
              backgroundColor: 'transparent',
              borderColor: profitFunnels[j].color,
              pointHoverBackgroundColor: profitFunnels[j].color,
              borderWidth: 1,
              data: chartData,
            }
            yAxis.push(element)
          }
        }
      }
    }

    return yAxis
  })()

  const defaultOptions = (() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              maxTicksLimit: 20,
              stepSize: Math.ceil(1000),
              max: 50000,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }
  })()

  // render
  return (
    <CChartLine
      {...props}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={chartLabels}
    />
  )
}

export default ProfitLineChart
