import React from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'

const SMMPModal = (props) => {
  const { body, title, onClickSave, updating } = props
  return (
    <Modal
      {...props}
      backdrop="static"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onClickSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SMMPModal
