import React, { useEffect, useState } from 'react'
import {
  CWidgetDropdown,
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react'
import numeral from 'numeral'

import CIcon from '@coreui/icons-react'
import ChartLineSimple from '../charts/ChartLineSimple'

const MembershipKPI = (props) => {
  const { styleWithData, data1, data2 } = props
  const defaultDataPoints = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  const [dropdownDataLabel, setDropdownDataLabel] = useState()
  const [dropdownDataPercent, setDropdownDataPercent] = useState()
  const [dropdownDataBehavior, setDropdownDataBehavior] = useState()
  const [dropdownData1ChurnRate, setDropdownData1ChurnRate] = useState(null)
  const [dropdownData2ChurnRate, setDropdownData2ChurnRate] = useState(null)

  const [dropdownData1ChurnRateDataPoints, setDropdownData1ChurnRateDataPoints] = useState([])
  const [dropdownData2ChurnRateDataPoints, setDropdownData2ChurnRateDataPoints] = useState([])

  useEffect(() => {
    let dataPoints1 = []
    let dataPoints2 = []
    for (var i = 0; i < 10; i++) {
      dataPoints1.push(dropdownData1ChurnRate)
      dataPoints2.push(dropdownData2ChurnRate)
    }
    setDropdownData1ChurnRateDataPoints(dataPoints1)
    setDropdownData2ChurnRateDataPoints(dataPoints2)
  }, [dropdownData1ChurnRate, dropdownData2ChurnRate])

  const currencyFormatter = (number) => {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
  }

  const onClickDropdownItem = (event) => {
    let key = event.currentTarget.text
    setDropdownDataLabel(key)

    let index
    for (var i = 0; i < data1.membershipChurnRates.length; i++) {
      if (data1.membershipChurnRates[i].membershipName === key) {
        index = i
        setDropdownData1ChurnRate(data1.membershipChurnRates[i].churnRate)
        break
      }
    }

    for (i = 0; i < data2.membershipChurnRates.length; i++) {
      if (data2.membershipChurnRates[i].membershipName === key) {
        setDropdownData2ChurnRate(data2.membershipChurnRates[i].churnRate)
        break
      }
    }

    for (i = 0; i < styleWithData.churnRatesByMembershipPercent.length; i++) {
      if (styleWithData.churnRatesByMembershipPercent[i].id === index) {
        setDropdownDataPercent(styleWithData.churnRatesByMembershipPercent[i].percent)
        setDropdownDataBehavior(styleWithData.churnRatesByMembershipPercent[i].behavior)
        break
      }
    }
  }

  return (
    <>
      <CRow>
        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-primary"
            header={
              data1.newMembers !== 0 && data2.newMembers !== 0
                ? `${data1.newMembers} --> ${data2.newMembers}`
                : 'NaN'
            }
            text="New Members"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.newMembersDataPoints.length !== 0
                      ? data1.newMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Members"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.newMembersDataPoints.length !== 0
                      ? data2.newMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Members"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.newMembersBehavior === 'down'
                      ? `${styleWithData.newMembersPercent} %`
                      : `${styleWithData.newMembersPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-people" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-info"
            header={
              data1.totalActiveMembers !== 0 && data2.totalActiveMembers !== 0
                ? `${data1.totalActiveMembers} --> ${data2.totalActiveMembers}`
                : 'NaN'
            }
            text="Total Active Members"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.totalActiveMembersDataPoints.length !== 0
                      ? data1.totalActiveMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="info"
                  options={{ elements: { line: { tension: 0.00001 } } }}
                  label="Active Members"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.totalActiveMembersDataPoints.length !== 0
                      ? data2.totalActiveMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="info"
                  options={{ elements: { line: { tension: 0.00001 } } }}
                  label="Active Members"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.totalActiveMembersBehavior === 'down'
                      ? `${styleWithData.totalActiveMembersPercent} %`
                      : `${styleWithData.totalActiveMembersPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-userFollow" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-warning"
            header={
              data1.cancelledMembers !== 0.0 && data2.cancelledMembers !== 0.0
                ? `${data1.cancelledMembers} % --> ${data2.cancelledMembers} %`
                : 'NaN'
            }
            text="Cancelled Members"
            footerSlot={
              <>
                <ChartLineSimple
                  className="mt-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.cancelledMembersDataPoints.length !== 0
                      ? data1.cancelledMembersDataPoints
                      : defaultDataPoints
                  }
                  options={{ elements: { line: { borderWidth: 2.5 } } }}
                  pointHoverBackgroundColor="warning"
                  label="Cancelled Members"
                  labels="months"
                />
                <ChartLineSimple
                  className="mt-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.cancelledMembersDataPoints.length !== 0
                      ? data2.cancelledMembersDataPoints
                      : defaultDataPoints
                  }
                  options={{ elements: { line: { borderWidth: 2.5 } } }}
                  pointHoverBackgroundColor="warning"
                  label="Cancelled Members"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.cancelledMembersPercentBehavior === 'down'
                      ? `${styleWithData.cancelledMembersPercent} %`
                      : `${styleWithData.cancelledMembersPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-user-unfollow" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-danger"
            header={
              data1.unpaidActiveMembers !== 0.0 || data2.unpaidActiveMembers !== 0.0
                ? `${data1.unpaidActiveMembers} % --> ${data2.unpaidActiveMembers} %`
                : 'NaN'
            }
            text="Unpaid Active Members"
            footerSlot={
              <>
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.unpaidActiveMembersDataPoints.length !== 0
                      ? data1.unpaidActiveMembersDataPoints
                      : defaultDataPoints
                  }
                  backgroundColor="rgb(250, 152, 152)"
                  label="Unpaid Active"
                  labels="months"
                />
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.unpaidActiveMembersDataPoints.length !== 0
                      ? data2.unpaidActiveMembersDataPoints
                      : defaultDataPoints
                  }
                  backgroundColor="rgb(250, 152, 152)"
                  label="Unpaid Active"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.unpaidActiveMembersPercentBehavior === 'down'
                      ? `${styleWithData.unpaidActiveMembersPercent} %`
                      : `${styleWithData.unpaidActiveMembersPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-wallet" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-dark"
            header={
              data1.churnRate !== 0 && data2.churnRate !== 0
                ? `${data1.churnRate} --> ${data2.churnRate}`
                : 'NaN'
            }
            text="Churn Rate"
            footerSlot={
              <>
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.churnRateDataPoints.length !== 0
                      ? data1.churnRateDataPoints
                      : defaultDataPoints
                  }
                  backgroundColor="rgb(250, 152, 152)"
                  label="Churn Rate"
                  labels="months"
                />
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.churnRateDataPoints.length !== 0
                      ? data2.churnRateDataPoints
                      : defaultDataPoints
                  }
                  backgroundColor="rgb(250, 152, 152)"
                  label="Churn Rate"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.churnRatePercentBehavior === 'down'
                      ? `${styleWithData.churnRatePercent} %`
                      : `${styleWithData.churnRatePercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-running" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-warning"
            header={
              dropdownData1ChurnRate && dropdownData2ChurnRate
                ? `${dropdownData1ChurnRate} --> ${dropdownData2ChurnRate}`
                : 'NaN'
            }
            text="Churn by Membership"
            footerSlot={
              <>
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    dropdownData1ChurnRateDataPoints.length !== 0
                      ? dropdownData1ChurnRateDataPoints
                      : defaultDataPoints
                  }
                  label={dropdownDataLabel}
                  labels="months"
                />
                <ChartLineSimple
                  className="mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    dropdownData2ChurnRateDataPoints.length !== 0
                      ? dropdownData1ChurnRateDataPoints
                      : defaultDataPoints
                  }
                  label={dropdownDataLabel}
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {dropdownDataPercent ? `${dropdownDataPercent} %` : 'NaN'}
                </h5>
              </>
            }
          >
            <CDropdown>
              <CDropdownToggle caret className="text-white" color="transparent">
                <CIcon name="cil-settings" />
              </CDropdownToggle>
              <CDropdownMenu className="pt-0" placement="bottom-end">
                {Array.isArray(data1.membershipChurnRates) ? (
                  data1.membershipChurnRates.map((model) => {
                    return (
                      <CDropdownItem onClick={onClickDropdownItem}>
                        {model.membershipName}
                      </CDropdownItem>
                    )
                  })
                ) : (
                  <CDropdownItem disabled></CDropdownItem>
                )}
              </CDropdownMenu>
            </CDropdown>
          </CWidgetDropdown>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-primary"
            header={
              data1.mrrMonth1Count !== 0 &&
              data1.mrrMonth1Value !== 0.0 &&
              data2.mrrMonth1Count !== 0 &&
              data2.mrrMonth1Value !== 0.0
                ? `${data1.mrrMonth1Count}/${numeral(data1.mrrMonth1Value).format(
                    '$ 0.00 a',
                  )} --> ${data2.mrrMonth1Count}/${numeral(data2.mrrMonth1Value).format(
                    '$ 0.00 a',
                  )}`
                : 'NaN'
            }
            text="MRR (Month 1)"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.mrrMonth1DataPoints.length !== 0
                      ? data1.mrrMonth1DataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Members"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.mrrMonth1DataPoints.length !== 0
                      ? data2.mrrMonth1DataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="primary"
                  label="Members"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.mrrMonth1PercentBehavior === 'down'
                      ? `${styleWithData.mrrMonth1Percent} %`
                      : `${styleWithData.mrrMonth1Percent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-info"
            header={
              data1.mrrMonth2PlusCount !== 0 &&
              data1.mrrMonth2PlusValue !== 0.0 &&
              data2.mrrMonth2PlusCount !== 0 &&
              data2.mrrMonth2PlusValue !== 0.0
                ? `${data1.mrrMonth2PlusCount}/${numeral(data1.mrrMonth2PlusValue).format(
                    '$ 0.00 a',
                  )} --> ${data2.mrrMonth2PlusCount}/${numeral(data2.mrrMonth2PlusValue).format(
                    '$ 0.00 a',
                  )}`
                : 'NaN'
            }
            text="MRR (Month 2+)"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.mrrMonth2PlusDataPoints.length !== 0
                      ? data1.mrrMonth2PlusDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="info"
                  label="MRR (Month 2+)"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.mrrMonth2PlusDataPoints.length !== 0
                      ? data2.mrrMonth2PlusDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="info"
                  label="MRR (Month 2+)"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.mrrMonth2PlusPercentBehavior === 'down'
                      ? `${styleWithData.mrrMonth2PlusPercent} %`
                      : `${styleWithData.mrrMonth2PlusPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="4" lg="2">
          <CWidgetDropdown
            color="gradient-warning"
            header={
              data1.totalMrrValue !== 0.0 && data2.totalMrrValue !== 0.0
                ? `${numeral(data1.totalMrrValue).format('$ 0.00 a')} -->${numeral(
                    data2.totalMrrValue,
                  ).format('$ 0.00 a')}`
                : 'NaN'
            }
            text="Total MRR"
            footerSlot={
              <>
                <ChartLineSimple
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.totalMrrDataPoints.length !== 0
                      ? data1.totalMrrDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="warning"
                  label="MRR"
                  labels="months"
                />
                <ChartLineSimple
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.totalMrrDataPoints.length !== 0
                      ? data2.totalMrrDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="warning"
                  label="MRR"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.totalMrrPercentBehavior === 'down'
                      ? `${styleWithData.totalMrrPercent} %`
                      : `${styleWithData.totalMrrPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          ></CWidgetDropdown>
        </CCol>

        <CCol sm="5" lg="3">
          <CWidgetDropdown
            color="gradient-danger"
            header={
              data1.lifeTimeValue !== 0 && data2.lifeTimeValue !== 0
                ? `${data1.lifeTimeValue} --> ${data2.lifeTimeValue}`
                : 'NaN'
            }
            text="Life Time Value"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.lifeTimeValueDataPoints.length !== 0
                      ? data1.lifeTimeValueDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="light"
                  label="Value"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.lifeTimeValueDataPoints.length !== 0
                      ? data2.lifeTimeValueDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="light"
                  label="Value"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.lifeTimeValuePercentBehavior === 'down'
                      ? `${styleWithData.lifeTimeValuePercent} %`
                      : `${styleWithData.lifeTimeValuePercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-people" height="30" />
          </CWidgetDropdown>
        </CCol>

        <CCol sm="5" lg="3">
          <CWidgetDropdown
            color="gradient-dark"
            header={
              data1.lifeTimeCycleOfMembers !== 0 && data2.lifeTimeCycleOfMembers !== 0
                ? `${data1.lifeTimeCycleOfMembers} --> ${data2.lifeTimeCycleOfMembers}`
                : 'NaN'
            }
            text="Life Time Cycle of Members"
            footerSlot={
              <>
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data1.lifeTimeCycleOfMembersDataPoints.length !== 0
                      ? data1.lifeTimeCycleOfMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="light"
                  label="Members"
                  labels="months"
                />
                <ChartLineSimple
                  pointed
                  className="c-chart-wrapper mt-3 mx-3"
                  style={{ height: '80px' }}
                  dataPoints={
                    data2.lifeTimeCycleOfMembersDataPoints.length !== 0
                      ? data2.lifeTimeCycleOfMembersDataPoints
                      : defaultDataPoints
                  }
                  pointHoverBackgroundColor="light"
                  label="Members"
                  labels="months"
                />
                <h5 className="text-right mr-4">
                  {styleWithData
                    ? styleWithData.lifeTimeCycleOfMembersPercentBehavior === 'down'
                      ? `${styleWithData.lifeTimeCycleOfMembersPercent} %`
                      : `${styleWithData.lifeTimeCycleOfMembersPercent} %`
                    : 'NaN'}
                </h5>
              </>
            }
          >
            <CIcon name="cil-people" height="30" />
          </CWidgetDropdown>
        </CCol>
      </CRow>
    </>
  )
}

export default MembershipKPI
