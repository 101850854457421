import React, { useEffect, useState } from 'react'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import { Type } from 'react-bootstrap-table2-editor'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { getCheckSubscriptions, updateView } from '../api/apiCalls'
import UploadFiles from '../components/UploadFiles'

toast.configure()
const CheckSystemPage = () => {
  const [checkSubscriptions, setCheckSubscriptions] = useState([])
  const [notFound, setNotFound] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const { ExportCSVButton } = CSVExport

  useEffect(() => {
    setNotFound(false)
    setSelectedRows([])
  }, [checkSubscriptions])

  const pendingApiCall = useApiProgress(API.API_CHECK_SUBSCRIPTIONS)
  const pendingApiCall2 = useApiProgress(API.API_DOCUMENT_UPLOAD)
  const pendingApiCall3 = useApiProgress(API.API_CHECK_SUBSCRIPTION_UPDATE_VIEW)

  useEffect(() => {
    handleFindCheckSubscriptions()
  }, [])

  const { t } = useTranslation()

  if (pendingApiCall || pendingApiCall2 || pendingApiCall3) {
    return <Spinner />
  }

  const handleFindCheckSubscriptions = () => {
    const findCheckSubscriptions = async () => {
      try {
        const response = await getCheckSubscriptions()
        notify(response.data.message, 's')
        setCheckSubscriptions(response.data.data)
      } catch (error) {}
    }
    findCheckSubscriptions()
  }

  const sendUpdateCheckSubscriptionView = () => {
    const sendUpdate = async () => {
      try {
        const response = await updateView(selectedRows)
        notify(response.data.message, 's')
        setCheckSubscriptions(response.data.data)
      } catch (error) {}
    }
    sendUpdate()
  }

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row.checkId])
    } else {
      const index = selectedRows.indexOf(row.checkId)
      if (index > -1) {
        selectedRows.splice(index, 1)
      }
      setSelectedRows([...selectedRows])
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.checkId)
    if (isSelect) {
      setSelectedRows(ids)
    } else {
      setSelectedRows([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelectAll: handleOnSelectAll,
    onSelect: handleOnSelect,
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const statusBadge = (cell, row) => {
    if (row.check === 'matched_system') {
      return <span className="badge badge-pill badge-success">MATCHED</span>
    } else if (row.check === 'not_include_clickfunnel') {
      return <span className="badge badge-pill badge-danger">NOT INCLUDED</span>
    } else if (row.check === 'include_clickfunnel') {
      return <span className="badge badge-pill badge-warning">INCLUDED</span>
    }
  }

  const selectStatusOptions = {
    paid: 'Paid',
    not_paid: 'Not Paid',
  }

  const selectSystemStateOptions = {
    matched_system: 'Matched System',
    not_include_clickfunnel: 'Not Included ClickFunnel',
    include_clickfunnel: 'Include ClickFunnel',
  }

  const columns = [
    {
      dataField: 'subscriptionId',
      text: 'Subscription Id',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'fullName',
      text: 'Full Name',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'email',
      text: 'E-Mail',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'purchase',
      text: 'Purchase',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'transactionCreationDate',
      text: 'Create Date',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      formatter: (cell) => {
        let dateObj = cell
        if (dateObj) {
          if (typeof cell !== 'object') {
            dateObj = new Date(cell)
          }
          return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
            '0' + dateObj.getUTCDate()
          ).slice(-2)}/${dateObj.getUTCFullYear()}`
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '80px' }
      },
      filter: selectFilter({
        options: selectStatusOptions,
      }),
    },
    {
      dataField: 'check',
      text: 'System State',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: statusBadge,
      filter: selectFilter({
        options: selectSystemStateOptions,
      }),
    },
  ]

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Subscriptions not found!')}
        </div>
      </div>
    )
  }

  const options = {
    sizePerPageList: [
      {
        text: '100',
        value: 100,
      },
      {
        text: '200',
        value: 200,
      },
      {
        text: '300',
        value: 300,
      },
      {
        text: 'All',
        value: checkSubscriptions != null ? checkSubscriptions.length : 0,
      },
    ],
  }

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm text-left">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={sendUpdateCheckSubscriptionView}
          >
            Don't show me selected records!
          </button>
        </div>
        <div className="col-sm">
          <UploadFiles page="CheckSystemPage" />
        </div>
        <div className="col-sm text-right">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={handleFindCheckSubscriptions}
          >
            Refresh List
          </button>
        </div>
      </div>

      <div>
        <ToolkitProvider
          keyField="checkId"
          data={checkSubscriptions}
          columns={columns}
          exportCSV
          search
        >
          {(props) => (
            <div>
              <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
              <hr />
              <BootstrapTable
                striped={true}
                hover={true}
                condensed={true}
                pagination={paginationFactory(options)}
                selectRow={selectRow}
                filter={filterFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default CheckSystemPage
