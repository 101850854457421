import React from 'react'
import { CCard, CCardBody, CCardHeader, CDataTable } from '@coreui/react'

const SalesListOfWeek = (props) => {
  const { salesData } = props

  const currencyFormatter = (number) => {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
  }

  return (
    <>
      <CCard>
        <CCardHeader>Sales List</CCardHeader>
        <CCardBody>
          <CDataTable
            items={salesData}
            hover
            striped
            bordered
            size="sm"
            sorter
            tableFilter
            itemsPerPageSelect
            itemsPerPage={10}
            pagination
            scopedSlots={{
              cartValue: (item) => (
                <td>
                  <strong>{currencyFormatter(item.cartValue)}$</strong>
                </td>
              ),
              adCost: (item) => (
                <td>
                  <strong>{currencyFormatter(item.adCost)}$</strong>
                </td>
              ),
              shippingCostPerOrder: (item) => (
                <td>
                  <strong>{currencyFormatter(item.shippingCostPerOrder)}$</strong>
                </td>
              ),
              costPerOrder: (item) => (
                <td>
                  <strong>{currencyFormatter(item.costPerOrder)}$</strong>
                </td>
              ),
              profitPerOrder: (item) => (
                <td>
                  <strong>{currencyFormatter(item.profitPerOrder)}$</strong>
                </td>
              ),
              totalRevenue: (item) => (
                <td>
                  <strong>{currencyFormatter(item.totalRevenue)}$</strong>
                </td>
              ),
              totalProfit: (item) => (
                <td>
                  <strong>{currencyFormatter(item.totalProfit)}$</strong>
                </td>
              ),
              totalNetProfit: (item) => (
                <td>
                  <strong>{currencyFormatter(item.totalNetProfit)}$</strong>
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default SalesListOfWeek
