import React from 'react'
import MembershipKPI from './MembershipKPI'
import OneTimeKPI from './OneTimeKPI'

import dummyData from '../list/DashboardData'
const WidgetsKPI = (props) => {
  const {
    styleWithData,
    membershipData1,
    membershipData2,
    onetimeData1,
    onetimeData2,
    cbOnetime,
    cbMembership,
  } = props
  // render
  return (
    <>
      <MembershipKPI
        styleWithData={cbMembership ? styleWithData : dummyData.styleWithData}
        data1={cbMembership ? membershipData1 : dummyData.membershipData}
        data2={cbMembership ? membershipData2 : dummyData.membershipData2}
      />
      <OneTimeKPI
        styleWithData={cbOnetime ? styleWithData : dummyData.styleWithData}
        data1={cbOnetime ? onetimeData1 : dummyData.onetimeData1}
        data2={cbOnetime ? onetimeData2 : dummyData.onetimeData2}
      />
    </>
  )
}

export default WidgetsKPI
