const dashboardData = {
  membershipData: {
    newMembers: 0,
    newMembersDataPoints: [],
    totalActiveMembers: 0,
    totalActiveMembersDataPoints: [],
    cancelledMembers: 0,
    cancelledMembersDataPoints: [],
    unpaidActiveMembers: 0,
    unpaidActiveMembersDataPoints: [],
    churnRate: 0.0,
    churnRateDataPoints: [],
    mrrMonth1Count: 0,
    mrrMonth1Value: 0.0,
    mrrMonth1DataPoints: [],
    mrrMonth2PlusCount: 0,
    mrrMonth2PlusValue: 0.0,
    mrrMonth2PlusDataPoints: [],
    totalMrrCount: 0,
    totalMrrValue: 0.0,
    totalMrrDataPoints: [],
    lifeTimeValue: 0.0,
    lifeTimeValueDataPoints: [],
    lifeTimeCycleOfMembers: 0.0,
    lifeTimeCycleOfMembersDataPoints: [],
    membershipChurnRates: [],
  },
  membershipData2: {
    newMembers: 0,
    newMembersDataPoints: [],
    totalActiveMembers: 0,
    totalActiveMembersDataPoints: [],
    cancelledMembers: 0,
    cancelledMembersDataPoints: [],
    unpaidActiveMembers: 0,
    unpaidActiveMembersDataPoints: [],
    churnRate: 0.0,
    churnRateDataPoints: [],
    mrrMonth1Count: 0,
    mrrMonth1Value: 0.0,
    mrrMonth1DataPoints: [],
    mrrMonth2PlusCount: 0,
    mrrMonth2PlusValue: 0.0,
    mrrMonth2PlusDataPoints: [],
    totalMrrCount: 0,
    totalMrrValue: 0.0,
    totalMrrDataPoints: [],
    lifeTimeValue: 0.0,
    lifeTimeValueDataPoints: [],
    lifeTimeCycleOfMembers: 0.0,
    lifeTimeCycleOfMembersDataPoints: [],
    membershipChurnRates: [],
  },
  onetimeData: {
    totalRevenue: 0.0,
    totalOrders: 0,
    totalCost: 0.0,
    totalProfit: 0.0,
    spendPerCustomer: 0.0,
    totalContacts: 0,
    totalRevenueByFilterDaily: 0.0,
    refundValue: {
      value1Percent: 0.0,
      value2Percent: 0.0,
    },
    totalClicker: 0,
    totalBuyer: 0,
    totalBuyerRevenue: 0.0,
  },
  onetimeData2: {
    totalRevenue: 0.0,
    totalOrders: 0,
    totalCost: 0.0,
    totalProfit: 0.0,
    spendPerCustomer: 0.0,
    totalContacts: 0,
    totalRevenueByFilterDaily: 0.0,
    refundValue: {
      value1Percent: 0.0,
      value2Percent: 0.0,
    },
    totalClicker: 0,
    totalBuyer: 0,
    totalBuyerRevenue: 0.0,
  },
  styleWithData: {
    newMembersPercent: 0,
    newMembersBehavior: 'equal',
    totalActiveMembersPercent: 0,
    totalActiveMembersBehavior: 'equal',
    cancelledMembersPercent: 0,
    cancelledMembersPercentBehavior: 'equal',
    unpaidActiveMembersPercent: 0,
    unpaidActiveMembersPercentBehavior: 'equal',
    churnRatePercent: 0,
    churnRatePercentBehavior: 'equal',
    mrrMonth1Percent: 0,
    mrrMonth1PercentBehavior: 'equal',
    mrrMonth2PlusPercent: 0,
    mrrMonth2PlusPercentBehavior: 'equal',
    totalMrrPercent: 0,
    totalMrrPercentBehavior: 'equal',
    lifeTimeValuePercent: 0,
    lifeTimeValuePercentBehavior: 'equal',
    lifeTimeCycleOfMembersPercent: 0,
    lifeTimeCycleOfMembersPercentBehavior: 'equal',
    churnRatesByMembershipPercent: [],
    pcTotalRevenue: 'secondary',
    scTotalRevenue: 'grey',
    totalRevenuePercent: 0,
    totalRevenuePercentBehavior: 'equal',
    pcTotalOrders: 'secondary',
    scTotalOrders: 'grey',
    totalOrdersPercent: 0,
    totalOrdersPercentBehavior: 'equal',
    pcTotalCost: 'secondary',
    scTotalCost: 'grey',
    totalCostPercent: 0,
    totalCostPercentBehavior: 'equal',
    pcTotalProfit: 'secondary',
    scTotalProfit: 'grey',
    totalProfitPercent: 0,
    totalProfitPercentBehavior: 'equal',
    pcSpendPerCustomer: 'secondary',
    scSpendPerCustomer: 'grey',
    spendPerCustomerPercent: 0,
    spendPerCustomerPercentBehavior: 'equal',
    pcTotalContacts: 'secondary',
    scTotalContacts: 'grey',
    totalContactsPercent: 0,
    totalContactsPercentBehavior: 'equal',
    pcTotalRevenueByFilterDaily: 'secondary',
    scTotalRevenueByFilterDaily: 'grey',
    totalRevenueByFilterDailyPercent: 0,
    totalRevenueByFilterDailyBehavior: 'equal',
    pcRefundValue: 'secondary',
    scRefundValue: 'grey',
    refundValuePercent: 0,
    refundValueBehavior: 'equal',
    pcTotalClicker: 'secondary',
    scTotalClicker: 'grey',
    totalClickerPercent: 0,
    totalClickerPercentBehavior: 'equal',
    pcTotalBuyer: 'secondary',
    scTotalBuyer: 'grey',
    totalBuyerPercent: 0,
    totalBuyerPercentBehavior: 'equal',
    pcTotalBuyerRevenue: 'secondary',
    scTotalBuyerRevenue: 'grey',
    totalBuyerRevenuePercent: 0,
    totalBuyerRevenuePercentBehavior: 'equal',
  },
  chartData: {
    revenue: {
      revenueData: [],
      data: {},
    },
    profit: {
      profitData: [],
      data: {},
    },
    refund: {
      refundData: {},
      reasons: [],
    },
  },
  listData: {
    salesList: [],
  },
}

export default dashboardData
