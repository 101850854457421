import React from 'react'
import Select from 'react-select'

const ModalSelect = (props) => {
  const { label, value, onChange, placeHolder, options, isDisabled } = props

  return (
    <div className="form-group">
      <label>{label}</label>
      <Select
        placeholder={placeHolder}
        value={value ? value : 'No Data!'}
        options={options}
        onChange={onChange}
        isSearchable={true}
        isDisabled={isDisabled ? isDisabled : false}
        isMulti
        isClearable
      />
    </div>
  )
}

export default ModalSelect
