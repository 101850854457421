import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { findTransactionsBySubscriptionId } from '../api/apiCalls'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { useTranslation } from 'react-i18next'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Type } from 'react-bootstrap-table2-editor'

toast.configure()
const TransactionDetailPage = (props) => {
  const [transactions, setTransactions] = useState([])
  const [notFound, setNotFound] = useState(false)
  const routeParams = useParams()
  const subscriptionId = routeParams.subscriptionId

  const { ExportCSVButton } = CSVExport

  useEffect(() => {
    getTransactionsBySubscriptionId()
  }, [])

  const { t } = useTranslation()

  const pendingApiCall = useApiProgress(API.API_SUBSCRIPTION_TRANSACTIONS)

  if (pendingApiCall) {
    return <Spinner />
  }

  const getTransactionsBySubscriptionId = async () => {
    try {
      const response = await findTransactionsBySubscriptionId(subscriptionId)
      notify(response.data.message, 's')
      setTransactions(response.data.data)
    } catch (error) {
      setNotFound(false)
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: 'No',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
    },
    {
      dataField: 'shipDate',
      text: 'Shipment Date',
      sort: true,
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'fullName',
      text: 'Full Name',
      sort: true,
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'productNames',
      text: 'Product Name',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '200px' }
      },
    },
    {
      dataField: 'subscriptionId',
      text: 'Subscription Id',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'trackingNumber',
      text: 'Tracking Number',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '200px' }
      },
    },
    {
      dataField: 'createdAt',
      text: 'Payment Date',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      formatter: (cell) => {
        let dateObj = cell
        if (dateObj) {
          if (typeof cell !== 'object') {
            dateObj = new Date(cell)
          }
          return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
            '0' + dateObj.getUTCDate()
          ).slice(-2)}/${dateObj.getUTCFullYear()}`
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      dataField: 'status',
      text: 'Payment Status',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'month',
      text: 'Subs. Month',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
  ]

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Subscriptions not found!')}
        </div>
      </div>
    )
  }

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm text-right">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={getTransactionsBySubscriptionId}
          >
            Refresh List
          </button>
        </div>
      </div>
      <hr />
      <div>
        <ToolkitProvider keyField="id" data={transactions} columns={columns} exportCSV search>
          {(props) => (
            <div>
              <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
              <hr />
              <BootstrapTable
                striped={true}
                hover={true}
                condensed={true}
                filter={filterFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default TransactionDetailPage
