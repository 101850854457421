import React from 'react'
import logo from '../assets/click_funnel.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { logoutSuccess } from '../redux/authActions'
import * as ROLE from '../constant/Roles'
import { Nav, Navbar } from 'react-bootstrap'
import '@coreui/coreui/dist/css/coreui.css'

const TopBar = (props) => {
  const { t } = useTranslation()

  const { username, isLoggedIn, roles } = useSelector((store) => ({
    isLoggedIn: store.isLoggedIn,
    username: store.username,
    roles: store.roles,
  }))

  const dispatch = useDispatch()

  const onLogoutSuccess = () => {
    dispatch(logoutSuccess())
  }

  const findRole = (hasRole) => {
    if (Array.isArray(roles) && roles.indexOf(hasRole) !== -1) {
      return true
    } else {
      return false
    }
  }

  // loginLinks
  let defaultLoginLinks = (
    <ul className="navbar-nav mr-auto">
      <li>
        <Link className="nav-link" to={`/user/${username}`}>
          {username}
        </Link>
      </li>
      <li className="nav-link" onClick={onLogoutSuccess} style={{ cursor: 'pointer' }}>
        {t('Logout')}
      </li>
    </ul>
  )

  // Logout Links
  let links = (
    <ul className="navbar-nav ml-auto">
      <li>
        <Link className="nav-link" to="/login">
          {t('Login')}
        </Link>
      </li>
      <li>
        <Link className="nav-link" to="/signup">
          {t('Sign Up')}
        </Link>
      </li>
    </ul>
  )

  if (isLoggedIn) {
    if (findRole(ROLE.ROLE_ADMIN)) {
      links = (
        <ul className="navbar-nav ml-auto">
          <li>
            <Link className="nav-link" to="/product">
              {t('Product')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/customer">
              {t('Customer')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/subscriptions">
              {t('Subscriptions')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/shipstation">
              {t('Shipstation Update')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/scheduler">
              {t('Scheduler')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/reportPage">
              {t('Reports')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/funnelCategoryMapping">
              {t('ESP Funnel Mapping')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/bucket">
              {t('Bucket')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/backup">
              {t('Backup')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/emailblock">
              {t('Email Block')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/campaign">
              {t('SMS Campaign')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/apesurvivalCampaign">
              {t('SMS Campaign ApeSurvival')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/failedTransactions">
              {t('Failed Transactions')}
            </Link>
          </li>
        </ul>
      )
    } else if (findRole(ROLE.ROLE_CUSTOMER_CARE)) {
      links = (
        <ul className="navbar-nav ml-auto">
          <li>
            <Link className="nav-link" to="/customer">
              {t('Customer')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/subscriptions">
              {t('Subscriptions')}
            </Link>
          </li>
        </ul>
      )
    } else if (findRole(ROLE.ROLE_CUSTOMER_SERVICE)) {
      links = (
        <ul className="navbar-nav ml-auto">
          <li>
            <Link className="nav-link" to="/emailblock">
              {t('Email Block')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/failedTransactions">
              {t('Failed Transactions')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/contact">
              {t('Contact')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/campaign">
              {t('SMS Campaign')}
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="/apesurvivalCampaign">
              {t('SMS Campaign ApeSurvival')}
            </Link>
          </li>
        </ul>
      )
    } else if (findRole(ROLE.ROLE_WAREHOUSE)) {
      links = (
        <ul className="navbar-nav ml-auto">
          <li>
            <Link className="nav-link" to="/shipstation">
              {t('Shipstation Update')}
            </Link>
          </li>
        </ul>
      )
    } else {
      links = defaultLoginLinks
    }
  }

  return (
    <div className="shadow-small bg-dark mb-2">
      <nav className="navbar navbar-dark bg-dark navbar-expand"></nav>
      <Navbar bg="dark" variant="dark">
        {findRole(ROLE.ROLE_CUSTOMER_SERVICE) ? (
          <Link className="navbar-brand" to="/emailblock">
            <img src={logo} width="60" alt="Suprise Membership Panel Logo" />
            Suprise Monthly Membership Panel
          </Link>
        ) : (
          <Link className="navbar-brand" to="/dashboard">
            <img src={logo} width="60" alt="Suprise Membership Panel Logo" />
            Suprise Monthly Membership Panel
          </Link>
        )}

        <Nav className="mr-auto">{isLoggedIn === true ? links : ''}</Nav>
        <Nav className="ml-auto">{isLoggedIn === true ? defaultLoginLinks : links}</Nav>
      </Navbar>
    </div>
  )
}

export default TopBar
