import React, { useEffect, useState } from 'react'
import Input from '../components/Input'
import { useParams } from 'react-router-dom'
import { findSubscriptionById, updateSubscription } from '../api/apiCalls'
import ButtonWithProgress from '../components/ButtonWithProgress'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
const SubscriptionUpdate = (props) => {
  const [subscription, setSubscription] = useState({})
  const routeParams = useParams()
  const subscriptionUniqueId = routeParams.id
  const { history } = props
  const { push } = history

  useEffect(() => {
    getSubscriptionById()
  }, [])

  const getSubscriptionById = async () => {
    try {
      const response = await findSubscriptionById(subscriptionUniqueId)
      setSubscription(response.data.data)
      console.log(response)
    } catch (error) {}
  }

  const onChange = (event) => {
    const { name, value } = event.target
    setSubscription((subscription) => ({ ...subscription, [name]: value }))
  }

  const onClickUpdate = async () => {
    try {
      const response = await updateSubscription(subscription)
      notify(response.data.message, 's')
      if (response.data.code === '0002') {
        push('/subscriptions')
      }
    } catch (error) {}
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const pendingApiCall = useApiProgress(API.API_FIND_BY_ID)

  return (
    <div className="container">
      <form className="needs-validation">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <Input
              name="firstName"
              label="First Name"
              onChange={onChange}
              defaultValue={subscription.firstName}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input
              name="lastName"
              label="Last Name"
              onChange={onChange}
              defaultValue={subscription.lastName}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              name="address1"
              label="Address 1"
              onChange={onChange}
              defaultValue={subscription.address1}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              name="address2"
              label="Address 2"
              onChange={onChange}
              defaultValue={subscription.address2}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <Input name="city" label="City" onChange={onChange} defaultValue={subscription.city} />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="state"
              label="State"
              onChange={onChange}
              defaultValue={subscription.state}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="country"
              label="Country"
              onChange={onChange}
              defaultValue={subscription.country}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input
              name="phone"
              label="Phone"
              onChange={onChange}
              defaultValue={subscription.phone}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input
              name="email"
              label="E-Mail"
              onChange={onChange}
              defaultValue={subscription.email}
            />
          </div>
        </div>
      </form>

      <h2>Shippment Address Information</h2>
      <form className="needs-validation">
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <Input
              name="shippingAddress1"
              label="Shipping Address 1"
              onChange={onChange}
              defaultValue={subscription.shippingAddress1}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              name="shippingAddress2"
              label="Shipping Address 2"
              onChange={onChange}
              defaultValue={subscription.shippingAddress2}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <Input
              name="shippingCity"
              label="Shipping City"
              onChange={onChange}
              defaultValue={subscription.shippingCity}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="shippingState"
              label="Shippment State"
              onChange={onChange}
              defaultValue={subscription.shippingState}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="shippingCountry"
              label="Shippment Country"
              onChange={onChange}
              defaultValue={subscription.shippingCountry}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input
              name="shippingPhone"
              label="Shippment Phone"
              onChange={onChange}
              defaultValue={subscription.shippingPhone}
            />
          </div>
        </div>
        <div>
          <ButtonWithProgress
            className="btn btn-primary d-inline-flex"
            onClick={onClickUpdate}
            disabled={pendingApiCall}
            pendingApiCall={pendingApiCall}
            text="Update Subscription"
          ></ButtonWithProgress>
        </div>
      </form>
    </div>
  )
}

export default SubscriptionUpdate
