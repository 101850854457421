import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as API from '../api/PathConstants'
import { ButtonGroup } from 'react-bootstrap'
import Modal from '../components/Modal'
import ModalInput from '../components/ModalInput'
import ConfirmModal from '../components/ConfirmModal'
import CIcon from '@coreui/icons-react'
import { EVENT_DELETE, EVENT_ADD } from '../constant/Operations'
import { SETTING_MEMBERSHIP_LABEL } from '../constant/SettingsType'
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CDataTable,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSelect,
  CSpinner,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import {
  createProduct,
  deleteProduct,
  findProducts,
  findSettingsByType,
  labelUpdate,
  productLabelOperation,
  updateProduct,
  findFunnels,
  updateFunnel,
  deleteSku,
  findSkus,
  updateSku,
  insertSku,
} from '../api/apiCalls'

toast.configure()
const ProductPage = (props) => {
  // Modal Types
  const MODAL_CREATE_LABEL = 'MODAL_CREATE_LABEL'
  const MODAL_CREATE_PRODUCT = 'MODAL_CREATE_PRODUCT'
  const MODAL_DELETE_PRODUCT = 'MODAL_DELETE_PRODUCT'
  const MODAL_EDIT_PRODUCT = 'MODAL_EDIT_PRODUCT'
  const MODAL_EDIT_FUNNEL = 'MODAL_EDIT_FUNNEL'
  const MODAL_CREATE_SKU = 'MODAL_CREATE_SKU'
  const MODAL_DELETE_SKU = 'MODAL_DELETE_SKU'

  const [selectedDeleteRow, setSelectedDeleteRow] = useState({})
  const [products, setProducts] = useState([])
  const [funnels, setFunnels] = useState([])
  const [skuList, setSkuList] = useState([])
  const [funnel, setFunnel] = useState({})
  const [funnelDetails, setFunnelDetails] = useState({})
  const [skuEditDetails, setSkuEditDetails] = useState({})
  const [product, setProduct] = useState({})
  const [sku, setSku] = useState({})
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [status, setStatus] = useState({ state: null })
  const [tableFilterValue, setTableFilterValue] = useState('')
  const [notFound, setNotFound] = useState(false)

  // Temporary
  const [settingsDetails, setSettingsDetails] = useState([])
  const [skuDetails, setSkuDetails] = useState([])
  const [editDetails, setEditDetails] = useState([])

  const [inputError, setInputError] = useState({
    productIdError: null,
    productNameError: null,
    uniqueProductNameError: null,
    skuError: null,
    piecesError: null,
    funnelIdError: null,
    funnelStepError: null,
    funnelNameError: null,
    unitPriceError: null,
    priceError: null,
    labelNameError: null,
  })

  const [isShow, setIsShow] = useState({
    MODAL_CREATE_LABEL: false,
    MODAL_CREATE_PRODUCT: false,
    MODAL_DELETE_PRODUCT: false,
    MODAL_EDIT_PRODUCT: false,
    MODAL_CREATE_SKU: false,
    MODAL_DELETE_SKU: false,
  })

  const [label, setLabel] = useState({
    labelName: null,
  })
  const [labels, setLabels] = useState([])
  const [labelsTableData, setLabelsTableData] = useState([])
  const [membershipProductsTableData, setMembershipProductsTableData] = useState([])

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    refreshProductData()
    refreshFunnelData()
    refreshSkuData()
  }, [])

  useEffect(() => {
    loadSettings()
    //loadMembershipProducts();
  }, [])

  const options = [
    { name: 'Swedish', value: 'sv' },
    { name: 'English', value: 'en' },
    {
      type: 'group',
      name: 'Group name',
      items: [{ name: 'Spanish', value: 'es' }],
    },
  ]

  const loadSettings = () => {
    const getSettings = async () => {
      try {
        const response = await findSettingsByType(SETTING_MEMBERSHIP_LABEL)
        createSettingsTable(response.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any settings data', 'e')
      }
    }
    getSettings()
  }

  const createSettingsTable = (data) => {
    let tableData = []
    let labels = data.properties['labels']
    for (var i = 0; i < labels.length; i++) {
      var tableObj = {
        label: labels[i],
      }
      tableData.push(tableObj)
    }
    setLabelsTableData(tableData)
    setLabels(labels)
  }

  // MODAL OPERATIONS
  const show = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: true }))
  const hide = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: false }))

  const clearError = () => {
    setInputError({
      productIdError: null,
      productNameError: null,
      uniqueProductNameError: null,
      skuError: null,
      piecesError: null,
      funnelIdError: null,
      funnelStepError: null,
      funnelNameError: null,
      unitPriceError: null,
      priceError: null,
      labelNameError: null,
    })
  }

  const currencyFormatter = (number) => {
    number = number / 100
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const onChange = (event) => {
    const { name, value } = event.target
    if (isShow[MODAL_CREATE_LABEL]) {
      setLabel((newLabel) => ({ ...newLabel, [name]: value }))
    } else if (isShow[MODAL_EDIT_FUNNEL]) {
      setFunnel((funnel) => ({ ...funnel, [name]: value }))
    } else if (isShow[MODAL_CREATE_SKU]) {
      setSku((sku) => ({ ...sku, [name]: value }))
    } else {
      setProduct((newProduct) => ({ ...newProduct, [name]: value }))
    }
  }

  const onChangeFunnelDetails = (event, stepIndex) => {
    const { name, value } = event.target
    setFunnelDetails((funnelDetails) => ({ ...funnelDetails, [name]: value }))
  }

  const onChangeSkuDetails = (event, index) => {
    const { name, value } = event.target
    setSkuEditDetails((skuDetails) => ({ ...skuDetails, [name]: value }))
  }

  const onChangeFunnelSkuField = (evt) => {
    let sku = evt.target.value
    if (sku === 'Select sku..') {
      setFunnelDetails((funnelDetails) => ({
        ...funnelDetails,
        uniqueProductName: null,
        sku: null,
        price: 0,
      }))
    } else {
      let selectedSku = skuList.filter((item) => {
        return item.sku === sku
      })

      setFunnelDetails((funnelDetails) => ({
        ...funnelDetails,
        uniqueProductName: selectedSku[0].uniqueProductName,
        sku: selectedSku[0].sku,
        price: parseInt(selectedSku[0].price),
      }))
    }
  }

  const refreshProductData = () => {
    setLoading(true)
    const loadProducts = async () => {
      try {
        const response = await findProducts()
        //notify(response.data.message, "s");
        setProducts(response.data.data)
        loadMembershipProducts(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setNotFound(true)
        notify('Not found any product data', 'e')
      }
    }
    loadProducts()
  }

  const refreshFunnelData = () => {
    setLoading(true)
    const loadFunnels = async () => {
      try {
        const response = await findFunnels()
        setFunnels(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setNotFound(true)
        notify('Not found any funnel data', 'e')
      }
    }
    loadFunnels()
  }

  const refreshSkuData = () => {
    setLoading(true)
    const loadSkus = async () => {
      try {
        const response = await findSkus()
        setSkuList(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setNotFound(true)
        notify('Not found any sku data', 'e')
      }
    }
    loadSkus()
  }

  const onClickSkuEdit = (sku, index) => {
    toggleSkuDetails(sku, index)
  }

  const loadMembershipProducts = (products) => {
    let tableData = []
    for (var i = 0; i < products.length; i++) {
      if (products[i].productName.toLowerCase().indexOf('membership') !== -1) {
        var tableObj = {
          _id: products[i]._id,
          productId: products[i].productId,
          productName: products[i].productName,
          uniqueProductName: products[i].uniqueProductName,
          sku: products[i].sku,
          pieces: products[i].pieces,
          funnelId: products[i].funnelId,
          funnelStep: products[i].funnelStep,
          funnelName: products[i].funnelName,
          unitPrice: products[i].unitPrice,
          membershipLabel: products[i].membershipLabel,
        }
        tableData.push(tableObj)
      }
    }
    setMembershipProductsTableData(tableData)
  }

  const openModal = (modalType) => {
    if (modalType === MODAL_CREATE_LABEL) {
      setLabel({
        labelName: null,
      })
    } else if (modalType === MODAL_CREATE_PRODUCT || modalType === MODAL_EDIT_PRODUCT) {
      setProduct({
        productId: 0,
        productName: null,
        uniqueProductName: null,
        sku: null,
        pieces: 0,
        funnelId: 0,
        funnelStep: 0,
        funnelName: null,
        unitPrice: 0,
      })
    } else if (modalType === MODAL_CREATE_SKU) {
      setSku({
        uniqueProductName: null,
        sku: null,
        price: 0,
      })
    }
    clearError()
    show(modalType)
  }

  const closeModal = (modalType) => {
    if (modalType === MODAL_CREATE_LABEL) {
      setLabel({
        labelName: null,
      })
    } else if (modalType === MODAL_CREATE_PRODUCT || modalType === MODAL_EDIT_PRODUCT) {
      setStatus({ state: null })
      setProduct({
        productId: 0,
        productName: null,
        uniqueProductName: null,
        sku: null,
        pieces: 0,
        funnelId: 0,
        funnelStep: 0,
        funnelName: null,
        unitPrice: 0,
      })
    } else if (modalType === MODAL_CREATE_SKU) {
      setProduct({
        uniqueProductName: null,
        sku: null,
        price: 0,
      })
    }
    clearError()
    hide(modalType)
  }

  const handleEditProduct = (clickStatus, row) => {
    setStatus({ state: 'update' })
    setProduct({
      _id: row._id,
      productId: row.productId,
      productName: row.productName,
      uniqueProductName: row.uniqueProductName,
      sku: row.sku,
      pieces: row.pieces,
      funnelId: row.funnelId,
      funnelStep: row.funnelStep,
      funnelName: row.funnelName,
      unitPrice: row.unitPrice,
    })
    show(MODAL_EDIT_PRODUCT)
  }

  const handleCreateProduct = () => {
    setStatus({ state: 'create' })
    openModal(MODAL_CREATE_PRODUCT)
  }

  const handleDeleteProduct = () => {
    confirmDeleteProduct(selectedDeleteRow)
  }

  const handleDeleteSku = () => {
    confirmDeleteSku(selectedDeleteRow)
  }

  const confirmDeleteSku = (sku) => {
    setLoading(true)
    const deleteSkuRow = async (sku) => {
      try {
        await deleteSku(sku.id)
        refreshSkuData()
        refreshProductData()
        refreshFunnelData()
        hide(MODAL_DELETE_SKU)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setNotFound(true)
        hide(MODAL_DELETE_SKU)
        notify('Could not delete sku!', 'e')
      }
    }
    deleteSkuRow(sku)
  }

  const onClickSaveSku = () => {
    const createSku = async () => {
      try {
        const response = await insertSku(sku)
        notify(response.data.message, response.data.code)
        hide(MODAL_CREATE_SKU)
        clearError()
        refreshSkuData()
      } catch (error) {
        hide(MODAL_CREATE_SKU)
        clearError()
        notify('Error creating sku!', 'e')
      }
    }

    if (sku.uniqueProductName !== null && sku.sku !== null && sku.price !== 0) {
      createSku()
    } else if (sku.sku === null) {
      setInputError({
        skuError: 'Sku field must be filled!',
      })
    } else if (sku.uniqueProductName === null) {
      setInputError({
        uniqueProductNameError: 'Unique Product Name field must be filled!',
      })
    } else if (sku.price === 0) {
      setInputError({
        priceError: 'Price field must be filled!',
      })
    }
  }

  const confirmDeleteProduct = (row) => {
    const handleDelete = async (row) => {
      try {
        const response = await deleteProduct(row._id)
        notify(response.data.message, 's')
        hide(MODAL_DELETE_PRODUCT)
        refreshProductData()
      } catch (error) {
        hide(MODAL_DELETE_PRODUCT)
        notify('Error deleting product!', 'e')
      }
    }
    handleDelete(row)
  }

  const onClickSaveProduct = () => {
    const saveProduct = async () => {
      try {
        let response
        if (status.state === 'create') {
          response = await createProduct(product)
          hide(MODAL_CREATE_PRODUCT)
        } else if (status.state === 'update') {
          response = await updateProduct(product)
          hide(MODAL_EDIT_PRODUCT)
        }
        notify(response.data.message, 's')
        clearError()
        refreshProductData()
      } catch (error) {
        hide(MODAL_CREATE_PRODUCT)
        hide(MODAL_EDIT_PRODUCT)
        clearError()
        notify('Error creating product!', 'e')
      }
    }

    if (
      product.productId !== 0 &&
      product.productName !== null &&
      product.uniqueProductName !== null &&
      product.sku !== null &&
      product.pieces !== 0 &&
      product.funnelId !== 0 &&
      product.funnelStep !== 0 &&
      product.funnelName !== null &&
      product.unitPrice !== 0
    ) {
      saveProduct()
    } else if (product.productId === 0) {
      setInputError({
        productIdError: 'Product Id field must be filled!',
      })
    } else if (product.productName === null) {
      setInputError({
        productNameError: 'Product Name field must be filled!',
      })
    } else if (product.uniqueProductName === null) {
      setInputError({
        uniqueProductNameError: 'Unique Product Name field must be filled!',
      })
    } else if (product.sku === null) {
      setInputError({ skuError: 'SKU field must be filled!' })
    } else if (product.pieces === 0) {
      setInputError({
        piecesError: 'Quantity field must be filled!',
      })
    } else if (product.funnelId === 0) {
      setInputError({
        funnelIdError: 'Funnel Id field must be filled!',
      })
    } else if (product.funnelStep === 0) {
      setInputError({
        funnelStepError: 'Funnel Step field must be filled!',
      })
    } else if (product.funnelName === null) {
      setInputError({
        funnelNameError: 'Funnel Name field must be filled!',
      })
    } else if (product.unitPrice === 0) {
      setInputError({
        unitPriceError: 'Unite Price field must be filled!',
      })
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columnCustomButtons = (item, index) => {
    return (
      <ButtonGroup className="my-custom-class">
        {/*<div>
					<button
						className="btn btn-success"
						data-toggle="tooltip"
						data-placement="top"
						title="Edit Product"
						onClick={(evt) => {
							handleEditProduct(true, item);
						}}
					>
						<CIcon size="lg" name="cil-pencil" />
					</button>
				</div> */}
        <CButton
          color="danger"
          variant="outline"
          shape="square"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Product"
          size="sm"
          onClick={(evt) => {
            setSelectedDeleteRow(item)
            show(MODAL_DELETE_PRODUCT)
          }}
        >
          <CIcon size="lg" name="cil-x-circle" />
        </CButton>
      </ButtonGroup>
    )
  }

  const skuColumnButtons = (sku, index) => {
    return (
      <ButtonGroup className="my-custom-class">
        <CButton
          color="success"
          variant="outline"
          shape="square"
          size="sm"
          onClick={() => {
            onClickSkuEdit(sku, index)
          }}
        >
          {skuDetails.includes(index) ? 'Hide' : ''}
          {skuDetails.includes(index) ? '' : <CIcon size="sm" name="cil-pencil" />}
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          shape="square"
          data-toggle="tooltip"
          size="sm"
          onClick={() => {
            setSelectedDeleteRow(sku)
            show(MODAL_DELETE_SKU)
          }}
        >
          <CIcon size="lg" name="cil-x-circle" />
        </CButton>
      </ButtonGroup>
    )
  }

  const handleLabelEvent = (event, label) => {
    const labelEvent = async (event, label) => {
      try {
        await productLabelOperation(event, label)
        clearError()
        loadSettings()
        hide(MODAL_CREATE_LABEL)
        notify('Label ' + event + ' Succesfull!', 's')
      } catch (error) {
        hide(MODAL_CREATE_LABEL)
        clearError()
        notify('Error received while ' + event + ' label!', 'e')
      }
    }

    if (label !== null) {
      labelEvent(event, label)
    } else {
      setInputError({
        labelNameError: 'Label name field must be filled!',
      })
    }
  }

  const onClickLabelSelect = (product, label, evt) => {
    product.membershipLabel = label
    const updateLabelInProduct = async (product) => {
      setLoading(true)
      try {
        await labelUpdate(product)
        refreshProductData()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        notify('Error updating label for product!', 'e')
      }
    }
    updateLabelInProduct(product)
  }

  const onClickFunnelEdit = (funnel) => {
    setFunnel(funnel)
    openModal(MODAL_EDIT_FUNNEL)
  }

  const handleFunnelSave = () => {
    setLoading(true)
    const saveFunnel = async () => {
      try {
        await updateFunnel(funnel)
        refreshFunnelData()
        clearError()
        hide(MODAL_EDIT_FUNNEL)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        hide(MODAL_EDIT_FUNNEL)
        notify('Error while updating funnel!', 'e')
      }
    }
    saveFunnel()
  }

  const onClickFunnelDetailsUpdate = (funnel, stepIndex) => {
    setUpdating(true)
    let funnelObj = funnel
    funnelObj.steps[stepIndex] = funnelDetails

    const updateDetails = async (funnelObj) => {
      try {
        await updateFunnel(funnelObj)
        refreshFunnelData()
        refreshProductData()
        refreshSkuData()
        toggleEditDetails(funnel.steps[stepIndex], stepIndex)
        setUpdating(false)
      } catch (error) {
        setUpdating(false)
        notify('Error while updating funnel details!', 'e')
      }
    }
    updateDetails(funnelObj)
  }

  const onClickSkuDetailsUpdate = (sku, index) => {
    setUpdating(true)
    let skuObj = skuEditDetails

    const updateDetails = async (skuObj) => {
      try {
        await updateSku(skuObj)
        refreshFunnelData()
        refreshProductData()
        refreshSkuData()
        toggleSkuDetails(index)
        setUpdating(false)
      } catch (error) {
        setUpdating(false)
        notify('Error while updating sku details!', 'e')
      }
    }
    updateDetails(skuObj)
  }

  const onActiveTabChange = (tab) => {
    setTableFilterValue('')
    if (tab !== 'funnels') {
      setSettingsDetails([])
      setEditDetails([])
      setSkuDetails([])
    }
  }

  const onTableFilterChange = () => {
    setSettingsDetails([])
    setEditDetails([])
    setSkuDetails([])
  }

  const toggleSettingsDetails = (index) => {
    const position = settingsDetails.indexOf(index)
    let newDetails = settingsDetails.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [index]
    }
    setSettingsDetails(newDetails)
    setEditDetails([])
  }

  const toggleSkuDetails = (item, index) => {
    const position = skuDetails.indexOf(index)
    let newDetails = skuDetails.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [index]
    }
    setSkuDetails(newDetails)

    // Control for empty unique product name
    if (item.uniqueProductName === null) {
      item.uniqueProductName = ''
    }
    setSkuEditDetails(item)
  }

  const toggleEditDetails = (step, stepIndex) => {
    const position = editDetails.indexOf(stepIndex)
    let newDetails = editDetails.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [stepIndex]
    }
    setEditDetails(newDetails)

    // Control for empty unique product name
    if (step.uniqueProductName === null) {
      step.uniqueProductName = ''
    }
    setFunnelDetails(step)
  }

  const fields = [
    { key: 'uniqueProductName', _style: { width: '10%' } },
    { key: 'funnelId', _style: { width: '5%' } },
    { key: 'productName', _style: { width: '50%' } },
    { key: 'sku', _style: { width: '5%' } },
    { key: 'unitPrice', _style: { width: '5%' } },
    {
      key: 'buttons',
      label: '',
      _style: { width: '2%' },
      sorter: false,
      filter: false,
    },
  ]

  const labelFields = [
    { key: 'label', _style: { width: '90%' } },
    {
      key: 'event',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const mappingFields = [
    { key: 'productName', _style: { width: '30%' } },
    { key: 'membershipLabel', label: 'Label', _style: { width: '1%' } },
  ]

  const skuFields = [
    { key: 'sku', _style: { width: '5%' } },
    { key: 'uniqueProductName', _style: { width: '30%' } },
    { key: 'price', _style: { width: '3%' } },
    {
      key: 'buttons',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  const funnelFields = [
    { key: 'funnelId', _style: { width: '15%' } },
    { key: 'name', label: 'Funnel Name', _style: { width: '75%' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '5%' },
      sorter: false,
      filter: false,
    },
  ]

  const funnelStepFields = [
    { key: 'funnelStep', _style: { width: '10%' } },
    { key: 'name', label: 'Product', _style: { width: '40%' } },
    { key: 'uniqueProductName', _style: { width: '20%' } },
    { key: 'sku', _style: { width: '5%' } },
    { key: 'pieces', _style: { width: '5%' } },
    { key: 'price', _style: { width: '5%' } },
    {
      key: 'edit',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div>
        <CTabs activeTab="products" onActiveTabChange={onActiveTabChange}>
          <CNav variant="tabs">
            <CNavItem>
              <CNavLink data-tab="products">Products</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="funnels">Funnels</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="sku">SKU</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink data-tab="labels">Membership Labels</CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent>
            <CTabPane data-tab="products">
              <CCard>
                <CCardHeader>
                  <div className="row">
                    <div className="col-sm text-left">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={refreshProductData}
                      >
                        Refresh List
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm ml-2"
                        onClick={() =>
                          window.open(API.BACKEND_HOST_PROD + 'api/1.0/product/download')
                        }
                      >
                        Download List
                      </button>
                    </div>
                    {/*<div className="col-sm text-right">
											<button
												type="button"
												className="btn btn-info btn-sm"
												disabled="true"
												onClick={handleCreateProduct}
											>
												Create Product
											</button>
										</div>*/}
                  </div>
                </CCardHeader>
                <CCardBody>
                  <CDataTable
                    items={products}
                    fields={fields}
                    loading={loading}
                    size="sm"
                    hover
                    cleaner
                    striped
                    sorter
                    tableFilter
                    tableFilterValue={tableFilterValue}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    pagination
                    footer
                    scopedSlots={{
                      unitPrice: (item) => {
                        return <td className="py-2">${currencyFormatter(item.unitPrice)}</td>
                      },
                      buttons: (item, index) => {
                        return <td className="py-2">{columnCustomButtons(item, index)}</td>
                      },
                    }}
                  />
                </CCardBody>
              </CCard>
            </CTabPane>
            <CTabPane data-tab="funnels">
              <CCard>
                <CCardBody>
                  <CDataTable
                    items={funnels}
                    fields={funnelFields}
                    loading={loading}
                    size="sm"
                    outlined
                    hover
                    cleaner
                    tableFilter
                    tableFilterValue={tableFilterValue}
                    onTableFilterChange={onTableFilterChange}
                    itemsPerPageSelect
                    itemsPerPage={10}
                    pagination
                    scopedSlots={{
                      show_details: (funnel, funnelIndex) => {
                        return (
                          <td className="py-2">
                            <ButtonGroup className="my-custom-class">
                              <CButton
                                color="success"
                                variant="outline"
                                shape="square"
                                size="sm"
                                onClick={() => {
                                  onClickFunnelEdit(funnel)
                                }}
                              >
                                <CIcon size="sm" name="cil-pencil" />
                              </CButton>
                              <CButton
                                color="primary"
                                variant="outline"
                                shape="square"
                                size="sm"
                                onClick={() => {
                                  toggleSettingsDetails(funnelIndex)
                                }}
                              >
                                {settingsDetails.includes(funnelIndex) ? 'Hide' : 'Steps'}
                              </CButton>
                            </ButtonGroup>
                          </td>
                        )
                      },
                      details: (funnel, funnelIndex) => {
                        return (
                          <CCollapse show={settingsDetails.includes(funnelIndex)}>
                            <CCard>
                              <CCardHeader>
                                <h5>{funnel.name}</h5>
                              </CCardHeader>
                              <CCardBody>
                                <CDataTable
                                  size="sm"
                                  items={funnel.steps}
                                  fields={funnelStepFields}
                                  loading={loading}
                                  striped
                                  scopedSlots={{
                                    price: (step, stepIndex) => {
                                      return (
                                        <td className="py-2">
                                          {currencyFormatter(step.price) + '$'}
                                        </td>
                                      )
                                    },
                                    edit: (step, stepIndex) => {
                                      return (
                                        <td className="py-2">
                                          <ButtonGroup className="my-custom-class">
                                            <CButton
                                              color="success"
                                              variant="outline"
                                              shape="square"
                                              size="sm"
                                              onClick={() => {
                                                toggleEditDetails(step, stepIndex)
                                              }}
                                            >
                                              {editDetails.includes(stepIndex) ? 'Hide' : 'Edit'}
                                            </CButton>
                                          </ButtonGroup>
                                        </td>
                                      )
                                    },
                                    details: (step, stepIndex) => {
                                      return (
                                        <CCollapse show={editDetails.includes(stepIndex)}>
                                          <CCard>
                                            <CCardBody>
                                              <CRow>
                                                <CCol sm="2">
                                                  <CForm>
                                                    <CFormGroup>
                                                      <CLabel>SKU:</CLabel>
                                                      {/*
																											<CInput
																												size="sm"
																												type="text"
																												name="sku"
																												onChange={(evt) =>
																													onChangeFunnelDetails(
																														evt,
																														stepIndex
																													)
																												}
																												defaultValue={
																													funnelDetails.sku
																												}
																											/>
																											*/}
                                                      <CSelect
                                                        size="sm"
                                                        name="sku"
                                                        value={funnelDetails.sku}
                                                        onChange={onChangeFunnelSkuField}
                                                      >
                                                        <option selected>Select sku..</option>
                                                        {editDetails.includes(stepIndex) &&
                                                          skuList.map((sku) => (
                                                            <option value={sku.sku}>
                                                              {`${sku.sku} / ${sku.uniqueProductName}`}
                                                            </option>
                                                          ))}
                                                      </CSelect>
                                                    </CFormGroup>
                                                  </CForm>
                                                </CCol>
                                                <CCol sm="3">
                                                  <CForm>
                                                    <CFormGroup>
                                                      <CLabel>Unique Product Name:</CLabel>
                                                      <CInput
                                                        size="sm"
                                                        type="text"
                                                        name="uniqueProductName"
                                                        disabled
                                                        onChange={(evt) =>
                                                          onChangeFunnelDetails(evt, stepIndex)
                                                        }
                                                        value={funnelDetails.uniqueProductName}
                                                        defaultValue={
                                                          funnelDetails.uniqueProductName
                                                        }
                                                      />
                                                    </CFormGroup>
                                                  </CForm>
                                                </CCol>
                                                <CCol sm="1">
                                                  <CForm>
                                                    <CFormGroup>
                                                      <CLabel>Price:</CLabel>
                                                      <CInput
                                                        size="sm"
                                                        type="number"
                                                        name="price"
                                                        disabled
                                                        onChange={(evt) =>
                                                          onChangeFunnelDetails(evt, stepIndex)
                                                        }
                                                        value={funnelDetails.price}
                                                        defaultValue={funnelDetails.price}
                                                      />
                                                    </CFormGroup>
                                                  </CForm>
                                                </CCol>
                                                <CCol sm="1">
                                                  <CForm>
                                                    <CFormGroup>
                                                      <CLabel>Pieces:</CLabel>
                                                      <CInput
                                                        size="sm"
                                                        type="number"
                                                        name="pieces"
                                                        onChange={(evt) =>
                                                          onChangeFunnelDetails(evt, stepIndex)
                                                        }
                                                        value={funnelDetails.pieces}
                                                        defaultValue={funnelDetails.pieces}
                                                      />
                                                    </CFormGroup>
                                                  </CForm>
                                                </CCol>
                                                <CCol
                                                  sm="3"
                                                  style={{
                                                    alignSelf: 'center',
                                                  }}
                                                >
                                                  <CButton
                                                    color="primary"
                                                    variant="outline"
                                                    shape="square"
                                                    size="sm"
                                                    disabled={!updating ? false : true}
                                                    onClick={() => {
                                                      onClickFunnelDetailsUpdate(funnel, stepIndex)
                                                    }}
                                                  >
                                                    {updating && (
                                                      <CSpinner color="primary" size="sm" />
                                                    )}
                                                    {updating ? '' : 'Update Details'}
                                                  </CButton>
                                                </CCol>
                                              </CRow>
                                            </CCardBody>
                                          </CCard>
                                        </CCollapse>
                                      )
                                    },
                                  }}
                                ></CDataTable>
                              </CCardBody>
                            </CCard>
                          </CCollapse>
                        )
                      },
                    }}
                  />
                </CCardBody>
              </CCard>
            </CTabPane>
            <CTabPane data-tab="sku">
              <div className="container">
                <CCard>
                  <CCardHeader className="text-right">
                    <CButton size="sm" color="info" onClick={() => openModal(MODAL_CREATE_SKU)}>
                      Create SKU
                    </CButton>
                  </CCardHeader>
                  <CCardBody>
                    <CDataTable
                      size="sm"
                      items={skuList}
                      fields={skuFields}
                      loading={loading}
                      hover
                      outlined
                      cleaner
                      tableFilter
                      tableFilterValue={tableFilterValue}
                      sorter
                      itemsPerPageSelect={{
                        label: 'Items per page:',
                        values: [5, 10, 20, 50],
                      }}
                      pagination
                      scopedSlots={{
                        price: (item) => {
                          return <td>${currencyFormatter(item.price)}</td>
                        },
                        buttons: (item, index) => {
                          return <td className="py-2">{skuColumnButtons(item, index)}</td>
                        },
                        details: (sku, index) => {
                          return (
                            <CCollapse show={skuDetails.includes(index)}>
                              <CCard>
                                <CCardBody>
                                  <CRow>
                                    <CCol sm="2">
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>SKU:</CLabel>
                                          <CInput
                                            size="sm"
                                            type="text"
                                            name="sku"
                                            disabled
                                            onChange={(evt) => onChangeSkuDetails(evt, index)}
                                            defaultValue={skuEditDetails.sku}
                                          />
                                        </CFormGroup>
                                      </CForm>
                                    </CCol>
                                    <CCol sm="3">
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Unique Product Name:</CLabel>
                                          <CInput
                                            size="sm"
                                            type="text"
                                            name="uniqueProductName"
                                            onChange={(evt) => onChangeSkuDetails(evt, index)}
                                            defaultValue={skuEditDetails.uniqueProductName}
                                          />
                                        </CFormGroup>
                                      </CForm>
                                    </CCol>
                                    <CCol sm="2">
                                      <CForm>
                                        <CFormGroup>
                                          <CLabel>Price:</CLabel>
                                          <CInput
                                            size="sm"
                                            type="number"
                                            name="price"
                                            onChange={(evt) => onChangeSkuDetails(evt, index)}
                                            defaultValue={skuEditDetails.price}
                                          />
                                        </CFormGroup>
                                      </CForm>
                                    </CCol>
                                    <CCol
                                      sm="3"
                                      style={{
                                        alignSelf: 'center',
                                      }}
                                    >
                                      <CButton
                                        color="primary"
                                        variant="outline"
                                        shape="square"
                                        size="sm"
                                        disabled={!updating ? false : true}
                                        onClick={() => {
                                          onClickSkuDetailsUpdate(sku, index)
                                        }}
                                      >
                                        {updating && <CSpinner color="primary" size="sm" />}
                                        {updating ? '' : 'Update Details'}
                                      </CButton>
                                    </CCol>
                                  </CRow>
                                </CCardBody>
                              </CCard>
                            </CCollapse>
                          )
                        },
                      }}
                    />
                  </CCardBody>
                </CCard>
              </div>
            </CTabPane>
            <CTabPane data-tab="labels">
              <div className="container">
                <CCard>
                  <CCardHeader className="text-right">
                    <CButton size="sm" color="info" onClick={() => openModal(MODAL_CREATE_LABEL)}>
                      Create Membership Label
                    </CButton>
                  </CCardHeader>
                  <CCardBody>
                    <CTabs activeTab="labels-mapping">
                      <CNav variant="tabs">
                        <CNavItem>
                          <CNavLink data-tab="labels-mapping">Product-Label Mapping</CNavLink>
                        </CNavItem>
                        <CNavItem>
                          <CNavLink data-tab="labels-view">Labels</CNavLink>
                        </CNavItem>
                      </CNav>
                      <CTabContent>
                        <CTabPane data-tab="labels-mapping">
                          <CCard className="mt-2">
                            <CCardBody>
                              <CDataTable
                                size="sm"
                                items={membershipProductsTableData}
                                fields={mappingFields}
                                loading={loading}
                                hover
                                cleaner
                                tableFilter
                                tableFilterValue={tableFilterValue}
                                sorter
                                itemsPerPageSelect={{
                                  label: 'Items per page:',
                                  values: [5, 10, 20, 50],
                                }}
                                pagination
                                scopedSlots={{
                                  membershipLabel: (item) => {
                                    return (
                                      <td>
                                        <CDropdown>
                                          <CDropdownToggle caret size="sm" color="secondary">
                                            {item.membershipLabel}
                                          </CDropdownToggle>
                                          <CDropdownMenu>
                                            {labels.map((label) => (
                                              <CDropdownItem
                                                onClick={onClickLabelSelect.bind(this, item, label)}
                                              >
                                                {label}
                                              </CDropdownItem>
                                            ))}
                                          </CDropdownMenu>
                                        </CDropdown>
                                      </td>
                                    )
                                  },
                                }}
                              />
                            </CCardBody>
                          </CCard>
                        </CTabPane>
                        <CTabPane data-tab="labels-view">
                          <CCard className="mt-2">
                            <CCardBody>
                              <CDataTable
                                size="sm"
                                items={labelsTableData}
                                fields={labelFields}
                                hover
                                scopedSlots={{
                                  event: (item) => (
                                    <td className="py-2">
                                      <CButton
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          handleLabelEvent(EVENT_DELETE, item.label)
                                        }}
                                      >
                                        Delete
                                      </CButton>
                                    </td>
                                  ),
                                }}
                              ></CDataTable>
                            </CCardBody>
                          </CCard>
                        </CTabPane>
                      </CTabContent>
                    </CTabs>
                  </CCardBody>
                </CCard>
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </div>

      <Modal
        title={status.state === 'create' ? 'Create Product' : 'Update Product'}
        show={status.state === 'create' ? isShow[MODAL_CREATE_PRODUCT] : isShow[MODAL_EDIT_PRODUCT]}
        onHide={() =>
          closeModal(status.state === 'create' ? MODAL_CREATE_PRODUCT : MODAL_EDIT_PRODUCT)
        }
        onClickSave={onClickSaveProduct}
        body={
          <div className="container">
            <ModalInput
              name="productId"
              label="Product Id"
              onChange={onChange}
              defaultValue={product.productId}
              error={inputError.productIdError}
            ></ModalInput>
            <ModalInput
              name="productName"
              label="Product Name"
              onChange={onChange}
              defaultValue={product.productName}
              error={inputError.productNameError}
            ></ModalInput>
            <ModalInput
              name="uniqueProductName"
              label="Unique Product Name"
              onChange={onChange}
              defaultValue={product.uniqueProductName}
              error={inputError.uniqueProductNameError}
            ></ModalInput>
            <ModalInput
              name="sku"
              label="SKU"
              onChange={onChange}
              defaultValue={product.sku}
              error={inputError.skuError}
            ></ModalInput>
            <ModalInput
              name="pieces"
              label="Quantity"
              type="number"
              onChange={onChange}
              defaultValue={product.pieces}
              error={inputError.piecesError}
            ></ModalInput>
            <ModalInput
              name="funnelId"
              label="Funnel Id"
              type="number"
              onChange={onChange}
              defaultValue={product.funnelId}
              error={inputError.funnelIdError}
            ></ModalInput>
            <ModalInput
              name="funnelStep"
              label="Funnel Step"
              type="number"
              onChange={onChange}
              defaultValue={product.funnelStep}
              error={inputError.funnelStepError}
            ></ModalInput>
            <ModalInput
              name="funnelName"
              label="Funnel Name"
              onChange={onChange}
              defaultValue={product.funnelName}
              error={inputError.funnelNameError}
            ></ModalInput>
            <ModalInput
              name="unitPrice"
              label="Unit Price"
              type="number"
              onChange={onChange}
              defaultValue={product.unitPrice}
              error={inputError.unitPriceError}
            ></ModalInput>
          </div>
        }
      />

      <Modal
        title="Create SKU"
        show={isShow[MODAL_CREATE_SKU]}
        onHide={() => closeModal(MODAL_CREATE_SKU)}
        onClickSave={onClickSaveSku}
        body={
          <div className="container">
            <ModalInput
              name="sku"
              label="SKU"
              onChange={onChange}
              defaultValue={sku.sku}
              error={inputError.skuError}
            ></ModalInput>
            <ModalInput
              name="uniqueProductName"
              label="Unique Product Name"
              onChange={onChange}
              defaultValue={sku.uniqueProductName}
              error={inputError.uniqueProductNameError}
            ></ModalInput>
            <ModalInput
              name="price"
              label="Price"
              type="number"
              onChange={onChange}
              defaultValue={product.price}
              error={inputError.priceError}
            ></ModalInput>
          </div>
        }
      />

      <Modal
        title="Create Label"
        show={isShow[MODAL_CREATE_LABEL]}
        onHide={() => closeModal(MODAL_CREATE_LABEL)}
        onClickSave={() => handleLabelEvent(EVENT_ADD, label.labelName)}
        body={
          <div className="container">
            <ModalInput
              name="labelName"
              label="Label Name"
              onChange={onChange}
              defaultValue={label.labelName}
              error={inputError.labelNameError}
            ></ModalInput>
          </div>
        }
      />

      <Modal
        title="Edit Funnel"
        show={isShow[MODAL_EDIT_FUNNEL]}
        onHide={() => closeModal(MODAL_EDIT_FUNNEL)}
        onClickSave={() => handleFunnelSave()}
        body={
          <div className="container">
            <ModalInput
              name="funnelId"
              label="Funnel Id"
              defaultValue={funnel.funnelId}
              disabled={true}
            ></ModalInput>
            <ModalInput
              name="name"
              label="Funnel Name"
              onChange={(evt) => onChange(evt)}
              defaultValue={funnel.name}
              error={inputError.funnelNameError}
            ></ModalInput>
          </div>
        }
      />

      <ConfirmModal
        title="Delete Product"
        show={isShow[MODAL_DELETE_PRODUCT]}
        onHide={() => closeModal(MODAL_DELETE_PRODUCT)}
        onClickYes={handleDeleteProduct}
        onClickNo={() => closeModal(MODAL_DELETE_PRODUCT)}
        body={<div className="container">Are you sure you want to do this product delete ?</div>}
      ></ConfirmModal>

      <ConfirmModal
        title="Delete Sku"
        show={isShow[MODAL_DELETE_SKU]}
        onHide={() => closeModal(MODAL_DELETE_SKU)}
        onClickYes={handleDeleteSku}
        onClickNo={() => closeModal(MODAL_DELETE_SKU)}
        body={
          <div className="container">
            <CAlert color="danger">
              <CIcon inline size="lg" name="cil-x-circle" className="mr-1" />
              If you perform this operation, the shipment process of the relevant product will stop!
            </CAlert>
            <h6>Are you sure you want to do this sku delete ?</h6>
          </div>
        }
      ></ConfirmModal>
    </div>
  )
}

export default ProductPage
