import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from '../components/Spinner'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import { findCustomerModifications } from '../api/apiCalls'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import { Type } from 'react-bootstrap-table2-editor'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

const CustomerModificationsPage = (props) => {
  const [customerModifications, setCustomerModifications] = useState([])
  const [notFound, setNotFound] = useState(false)
  const routeParams = useParams()
  const subscriptionId = routeParams.subscriptionId

  useEffect(() => {
    setNotFound(false)
    findCustomerModificationsBySubscriptionId()
  }, [])

  const findCustomerModificationsBySubscriptionId = async () => {
    try {
      const response = await findCustomerModifications(subscriptionId)
      setCustomerModifications(response.data.data)
    } catch (error) {
      setNotFound(true)
      notify('Not found any customer modifications data', 'e')
    }
  }

  const pendingApiCall = useApiProgress(API.API_FIND_CUSTOMER_MODIFICATIONS)
  if (pendingApiCall) {
    return <Spinner />
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columns = [
    {
      dataField: 'firstName',
      text: 'First Name',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'email',
      text: 'E-Mail',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '180px' }
      },
    },
    {
      dataField: 'phone',
      text: 'Phone',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'addressState',
      text: 'State',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
    },
    {
      dataField: 'city',
      text: 'City',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'country',
      text: 'Country',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
    },
    {
      dataField: 'customerStatus',
      text: 'Status',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'modifier',
      text: 'Modifier',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'modificationDate',
      text: 'Modification Date',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      formatter: (cell) => {
        let dateObj = cell
        if (dateObj) {
          if (typeof cell !== 'object') {
            dateObj = new Date(cell)
          }
          return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
            '0' + dateObj.getUTCDate()
          ).slice(-2)}/${dateObj.getUTCFullYear()}`
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      dataField: 'modificationDescription',
      text: 'Description',
      editable: false,
      filter: textFilter(),
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '200px' }
      },
    },
  ]

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          Customer Modifications not found!
        </div>
      </div>
    )
  }

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div className="row">
        <div className="col-sm text-left">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm"
            onClick={findCustomerModificationsBySubscriptionId}
          >
            Refresh List
          </button>
        </div>
      </div>
      <hr />
      <div>
        <ToolkitProvider keyField="_id" data={customerModifications} columns={columns} search>
          {(props) => (
            <div>
              <BootstrapTable
                striped={true}
                hover={true}
                condensed={true}
                pagination={paginationFactory()}
                filter={filterFactory()}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default CustomerModificationsPage
