import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './scss/bootstrap-override.scss'
import './i18n'
import App from './container/App'
import { Provider } from 'react-redux'
import configureStore from './redux/configureStore'
import * as serviceWorker from './serviceWorker'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { icons } from '../src/assets/icons'

const store = configureStore()

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <App />,
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
