import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CForm,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import {
  findSubscriptionWithAdvancedFilter,
  findUpdateValues,
  sendReportsViaMail,
  updateMaintenanceReport,
} from '../api/apiCalls'
import Spinner from '../components/Spinner'

toast.configure()
const ReportPage = () => {
  // Table Integration
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(20)
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [tableFilterValue, setTableFilterValue] = useState('')

  const [notFound, setNotFound] = useState(false)

  // Filter State
  const [filter, setFilter] = useState({
    startDate: moment().subtract(7, 'day'),
    endDate: moment(),
  })
  const [focusedInput, setFocusedInput] = useState()
  const [activeTab, setActiveTab] = useState('report')

  // Meintenance
  const [reports, setReports] = useState([])

  useEffect(() => {
    setNotFound(false)
  }, [])

  useEffect(() => {
    refreshReportData()
  }, [filter, page, itemsPerPage, tableFilterValue])

  useEffect(() => {
    handleRefreshList()
  }, [activeTab])

  const { t } = useTranslation()

  const currencyFormatter = (number) => {
    return number ? number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0
  }

  const handleRefreshList = () => {
    setLoading(true)
    const applyFilter = async () => {
      try {
        const response = await findUpdateValues()
        setReports(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notify('Receive an error while fetching maintenance data!', 'e')
      }
    }
    applyFilter()
  }

  const updateReportRow = (oldValue, newValue, row, column) => {
    const updateRow = async (row) => {
      try {
        const response = await updateMaintenanceReport(row)
        notify(response.data.message, 's')
      } catch (error) {}
    }

    if (isNaN(newValue)) {
      notify('You entered an incorrect value!', 'e')
    } else {
      updateRow(row)
    }
  }

  const handleDownloadReport = () => {
    const download = async () => {
      try {
        const response = await sendReportsViaMail(filter)
        notify(response.data.message, 'i')
      } catch (error) {
        setNotFound(true)
        notify('Not found any product data', 'e')
      }
    }
    download()
  }

  const refreshReportData = () => {
    const applyFilter = async () => {
      let fields = {
        startDate: filter.startDate,
        endDate: filter.endDate,
        subscriptionType: 'onetime',
      }

      setLoading(true)
      try {
        const response = await findSubscriptionWithAdvancedFilter(
          tableFilterValue,
          page,
          itemsPerPage,
          fields,
        )
        setPages(response.data.totalPages)
        setSubscriptions(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notify('Not found any data', 'e')
      }
    }
    applyFilter()
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const fields = [
    { key: 'createdAt', _style: { width: '5%' } },
    { key: 'subscriptionId', _style: { width: '5%' } },
    { key: 'firstName', _style: { width: '5%' } },
    { key: 'lastName', _style: { width: '5%' } },
    { key: 'productNames', _style: { width: '15%' } },
    {
      key: 'transactionFrom',
      label: 'Payment System',
      _style: { width: '5%' },
    },
  ]

  const maintenancefields = [
    {
      dataField: 'funnelName',
      filter: textFilter(),
      text: 'Funnel',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'profitPercent',
      text: 'Net Revenue Calculator',
      editable: true,
      sort: true,
      type: 'number',
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        return currencyFormatter(cell) + '$'
      },
    },
    {
      dataField: 'adCost',
      text: 'Ad Cost',
      editable: true,
      sort: true,
      type: 'number',
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        return currencyFormatter(cell) + '$'
      },
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      sort: true,
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
      formatter: (cell) => {
        if (cell) {
          let dateObj = cell
          if (dateObj) {
            if (typeof cell !== 'object') {
              dateObj = new Date(cell)
            }
            return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
              '0' + dateObj.getUTCDate()
            ).slice(-2)}/${dateObj.getUTCFullYear()}`
          }
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
  ]

  const options = {
    sizePerPageList: [
      {
        text: '200',
        value: 200,
      },
      {
        text: 'All',
        value: reports != null ? reports.length : 0,
      },
    ],
  }

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Subscriptions not found!')}
        </div>
      </div>
    )
  }

  return (
    <div className="ml-auto" style={{ padding: '40px' }}>
      <CTabs activeTab="report" onActiveTabChange={setActiveTab}>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab="report">Product Number Report</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab="maintenance">Reports Maintenance</CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane data-tab="report">
            <CCard>
              <CCardHeader className="text-right">
                <CForm inline>
                  <DateRangePicker
                    startDate={filter.startDate}
                    startDateId="startDate"
                    endDate={filter.endDate}
                    endDateId="endDate"
                    isOutsideRange={() => null}
                    onDatesChange={({ startDate, endDate }) =>
                      setFilter({
                        ...filter,
                        startDate: startDate,
                        endDate: endDate,
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  />
                  <CButton
                    size="sm"
                    className="btn btn-outline-primary ml-4"
                    onClick={handleDownloadReport}
                  >
                    Send Reports Via Mail
                  </CButton>
                </CForm>
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  size="sm"
                  items={subscriptions}
                  fields={fields}
                  loading={loading}
                  hover
                  cleaner
                  clickableRows
                  tableFilter={{ external: true }}
                  tableFilterValue={tableFilterValue}
                  onTableFilterChange={setTableFilterValue}
                  sorter
                  itemsPerPageSelect={{
                    label: 'Items per page:',
                    values: [10, 20, 50, 100],
                  }}
                  itemsPerPage={itemsPerPage}
                  onPaginationChange={setItemsPerPage}
                  scopedSlots={{
                    createdAt: (row) => (
                      <td className="py-2">
                        {`${('0' + (new Date(row.createdAt).getUTCMonth() + 1)).slice(-2)}/${(
                          '0' + new Date(row.createdAt).getUTCDate()
                        ).slice(-2)}/${new Date(row.createdAt).getUTCFullYear()}`}
                      </td>
                    ),
                  }}
                ></CDataTable>
                <CPagination
                  size="sm"
                  pages={pages}
                  activePage={page}
                  onActivePageChange={setPage}
                  className={pages < 2 ? 'd-none' : ''}
                />
              </CCardBody>
            </CCard>
          </CTabPane>
          <CTabPane data-tab="maintenance">
            <CCard>
              <CCardHeader className="text-right">
                <button
                  size="sm"
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={handleRefreshList}
                >
                  Refresh List
                </button>
              </CCardHeader>
              <CCardBody>
                {loading ? (
                  <Spinner />
                ) : (
                  <ToolkitProvider keyField="id" data={reports} columns={maintenancefields} search>
                    {(props) => (
                      <div>
                        <BootstrapTable
                          size="sm"
                          striped={true}
                          hover={true}
                          condensed={true}
                          pagination={paginationFactory(options)}
                          filter={filterFactory()}
                          cellEdit={cellEditFactory({
                            mode: 'click',
                            afterSaveCell: (oldValue, newValue, row, column) => {
                              updateReportRow(oldValue, newValue, row, column)
                            },
                          })}
                          {...props.baseProps}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </CCardBody>
            </CCard>
          </CTabPane>
        </CTabContent>
      </CTabs>
    </div>
  )
}

export default ReportPage
