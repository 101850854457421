import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import {
  findDomains,
  findMappings,
  saveDomain,
  updateMapping,
  findFunnelMapping,
  saveFunnel,
} from '../api/apiCalls'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react'
import { ButtonGroup } from 'react-bootstrap'
import CIcon from '@coreui/icons-react'
import Modal from '../components/Modal'
import ModalInput from '../components/ModalInput'
import ModalSelect from '../components/ModalSelect'
import ModalMultiSelect from '../components/ModalMultiSelect'
import Select from 'react-select'

toast.configure()
const FunnelCategoryMappingPage = (props) => {
  // Modal Types
  const MODAL_CREATE_DOMAIN = 'MODAL_CREATE_DOMAIN'
  const MODAL_ADD_MAPPING = 'MODAL_ADD_MAPPING'

  const [isShow, setIsShow] = useState({
    MODAL_CREATE_DOMAIN: false,
    MODAL_ADD_MAPPING: false,
  })
  const [mappingData, setMappingData] = useState([])
  const [domainData, setDomainData] = useState([])
  const [domain, setDomain] = useState({})
  const [mapping, setMapping] = useState({})
  const [productFunnelMapping, setProductFunnelMapping] = useState({})
  const [funnelNameList, setFunnelNameList] = useState([])
  const [funnelIdList, setFunnelIdList] = useState([])
  const [notFound, setNotFound] = useState(false)
  const [isDisabledDomain, setIsDisabledDomain] = useState(true)
  const [domainsSelectedValue, setDomainsSelectedValue] = useState([])
  const [selected, setSelected] = useState({
    funnelName: {
      value: null,
      label: null,
    },
    funnelId: {
      value: null,
      label: null,
    },
    domain: {
      value: null,
      label: null,
    },
  })

  // Domain Error
  const [inputError, setInputError] = useState({
    domainInfoError: null,
    apiKeyError: null,
    apiHashError: null,
    listIdError: null,
    listNameError: null,
    funnelNameError: null,
    funnelIdError: null,
    domainError: null,
  })

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  const pendingApiCall = useApiProgress(API.API_MAPPING)

  useEffect(() => {
    refreshMappingData()
  }, [])

  if (pendingApiCall) {
    return <Spinner />
  }

  const refreshMappingData = () => {
    const loadMappingData = async () => {
      try {
        const mappingResponse = await findMappings()
        const domainResponse = await findDomains()
        notify(mappingResponse.data.message, 's')
        setDomainData(domainResponse.data.data)
        setMappingData(mappingResponse.data.data)
      } catch (error) {
        setNotFound(true)
        notify('Not found any product data', 'e')
      }
    }
    loadMappingData()
  }

  const updateMapData = (map) => {
    const updateData = async () => {
      try {
        const response = await updateMapping(map)
        notify(response.data.message, 's')
      } catch (error) {
        setNotFound(true)
      }
    }
    updateData()
  }

  // MODAL OPERATIONS
  const show = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: true }))
  const hide = (modalType) => setIsShow(() => ({ ...isShow, [modalType]: false }))

  const onChange = (evt) => {
    const { name, value } = evt.target
    if (isShow[MODAL_CREATE_DOMAIN]) {
      setDomain((newDomain) => ({ ...newDomain, [name]: value }))
    } else if (isShow[MODAL_ADD_MAPPING]) {
      setMapping((newMapping) => ({ ...newMapping, [name]: value }))
    }
  }

  const onChangeSelect = (evt, selectType) => {
    if (selectType === 'funnelName') {
      setSelected({
        ...selected,
        funnelName: evt,
        funnelId: { value: null, label: null },
        domain: { value: null, label: null },
      })
      setFunnelIdList(productFunnelMapping[evt.value])
      setIsDisabledDomain(true)
    } else if (selectType === 'funnelId') {
      setSelected({
        ...selected,
        funnelId: evt,
        domain: { value: null, label: null },
      })
      setIsDisabledDomain(false)
    } else if (selectType === 'domain') {
      setSelected({ ...selected, domain: evt })
    }
  }

  const onChangeMultiSelect = (e) => {
    setDomainsSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  }

  const openModal = (modalType) => {
    if (modalType === MODAL_CREATE_DOMAIN) {
      setDomain({
        domainInfo: null,
        apiKey: null,
        apiHash: null,
        listId: null,
        listName: null,
      })
    } else if (modalType === MODAL_ADD_MAPPING) {
      setMapping({
        funnelName: null,
        funnelId: null,
        domain: null,
      })
      getFunnelMapping()
    }
    clearError()
    show(modalType)
  }

  const closeModal = (modalType) => {
    if (modalType === MODAL_CREATE_DOMAIN) {
      setDomain({
        domainInfo: null,
        apiKey: null,
        apiHash: null,
        listId: null,
        listName: null,
      })
    } else if (modalType === MODAL_ADD_MAPPING) {
      setSelected({
        ...selected,
        funnelName: {
          value: null,
          label: null,
        },
        funnelId: {
          value: null,
          label: null,
        },
        domain: {
          value: null,
          label: null,
        },
      })
      setFunnelIdList([])
      setDomainsSelectedValue([])
      setIsDisabledDomain(true)
    }
    clearError()
    hide(modalType)
  }

  const clearError = () => {
    if (isShow[MODAL_CREATE_DOMAIN]) {
      setInputError({
        domainInfo: null,
        apiKey: null,
        apiHash: null,
        listId: null,
        listName: null,
      })
    } else if (isShow[MODAL_ADD_MAPPING]) {
      setSelected({
        ...selected,
        funnelName: {
          value: null,
          label: null,
        },
        funnelId: {
          value: null,
          label: null,
        },
        domain: {
          value: null,
          label: null,
        },
      })
      setFunnelIdList([])
      setDomainsSelectedValue([])
      setIsDisabledDomain(true)
    }
  }

  const onClickSaveDomain = () => {
    if (validation()) {
      const putDomain = async () => {
        try {
          const response = await saveDomain(domain)
          notify(response.data.message, 's')
          clearError()
          refreshMappingData()
          hide(MODAL_CREATE_DOMAIN)
        } catch (error) {
          hide(MODAL_CREATE_DOMAIN)
          clearError()
          notify('Error creating domain!', 'e')
        }
      }
      putDomain()
    }
  }

  const onClickAddMapping = () => {
    if (validation()) {
      const putFunnel = async () => {
        try {
          let funnelMap = {
            funnelId: selected.funnelId.value,
            domains: domainsSelectedValue,
            //domain: selected.domain.value,
          }
          const response = await saveFunnel(funnelMap)
          if (response.data.code === 's') {
            clearError()
            refreshMappingData()
            hide(MODAL_ADD_MAPPING)
          }
          notify(response.data.message, response.data.code)
        } catch (error) {
          hide(MODAL_ADD_MAPPING)
          clearError()
          notify('Error creating funnel mapping!', 'e')
        }
      }
      putFunnel()
    }
  }

  const getFunnelMapping = () => {
    const getMapping = async () => {
      try {
        const response = await findFunnelMapping()
        setProductFunnelMapping(response.data.data.funnelMap)
        setFunnelNameList(Object.keys(response.data.data.funnelMap))
      } catch (error) {}
    }
    getMapping()
  }

  // Validation
  const validation = () => {
    let validation = false
    if (isShow[MODAL_CREATE_DOMAIN]) {
      if (
        domain.domainInfo != null &&
        domain.apiKey != null &&
        domain.apiHash != null &&
        domain.listId != null &&
        domain.listName != null
      ) {
        validation = true
      } else if (domain.domainInfo === null) {
        setInputError({
          domainInfoError: 'Domain Info field must be filled!',
        })
      } else if (domain.apiKey === null) {
        setInputError({
          apiKeyError: 'API Key field must be filled!',
        })
      } else if (domain.apiHash === null) {
        setInputError({
          apiHashError: 'API Hash field must be filled!',
        })
      } else if (domain.listId === null) {
        setInputError({
          listIdError: 'List ID field must be filled!',
        })
      } else if (domain.listName === null) {
        setInputError({
          listNameError: 'List Name field must be filled!',
        })
      }
    } else if (isShow[MODAL_ADD_MAPPING]) {
      if (
        selected.funnelName.value != null &&
        selected.funnelId.value != null &&
        domainsSelectedValue.length > 0
        //selected.domain.value != null
      ) {
        validation = true
      } else {
        notify('All field must be filled!', 'e')
      }
    }

    return validation
  }

  /**
   * This method not using.
   */
  const onClickDomainSelect = (domainInfo, index, evt) => {
    mappingData[index].domain = domainInfo
    let map = mappingData[index]
    updateMapData(map)
  }

  const handleDomainsSelectedChange = (evt, item) => {
    let index = mappingData.findIndex((d) => d.funnelId === item.funnelId)
    mappingData[index].domains = []
    evt.map((domain) => mappingData[index].domains.push(domain.value))

    let map = mappingData[index]
    updateMapData(map)
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columnCustomButtons = (item, index) => {
    return (
      <ButtonGroup className="my-custom-class">
        <div>
          <button
            className="btn btn-success"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit Product"
            onClick={(evt) => {}}
          >
            <CIcon size="lg" name="cil-pencil" />
          </button>
        </div>
        <button
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Product"
          size="sm"
          onClick={(evt) => {}}
        >
          <CIcon size="lg" name="cil-x-circle" />
        </button>
      </ButtonGroup>
    )
  }

  // Multi Column CombBox
  const comboBoxColumns = [
    { field: 'id', header: 'ID', width: '100px' },
    { field: 'name', header: 'Name', width: '300px' },
    { field: 'position', header: 'Position', width: '300px' },
  ]

  const fields = [
    { key: 'funnelName', _style: { width: '25%' } },
    { key: 'funnelId', _style: { width: '10%' } },
    //{ key: "domain", _style: { width: "10%" } },
    { key: 'domains', _style: { width: 'auto' } },
    /*{
			key: "buttons",
			label: "",
			_style: { width: "2%" },
			sorter: false,
			filter: false,
		},*/
  ]

  return (
    <div className="container ml-auto" style={{ padding: '20px' }}>
      <CCard>
        <CCardHeader>
          <div className="row">
            <div className="col-sm text-left">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={refreshMappingData}
              >
                Refresh List
              </button>
            </div>
            <div className="col-sm text-right">
              <button
                type="button"
                className="btn btn-info btn-sm mr-2"
                onClick={() => openModal(MODAL_ADD_MAPPING)}
              >
                Add Mapping
              </button>
              <button
                type="button"
                className="btn btn-info btn-sm"
                onClick={() => openModal(MODAL_CREATE_DOMAIN)}
              >
                Create Domain
              </button>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            size="sm"
            items={mappingData}
            fields={fields}
            hover
            striped
            sorter
            tableFilter
            itemsPerPageSelect
            itemsPerPage={10}
            pagination={{ size: 'sm' }}
            footer
            scopedSlots={{
              /*domain: (item, index) => {
                return (
                  <td style={{ padding: "2px" }}>
                    <CDropdown className="mt-2">
                      <CDropdownToggle caret size="sm" color="secondary">
                        {item.domain}
                      </CDropdownToggle>
                      <CDropdownMenu>
                        {domainData.map((domain) => (
                          <CDropdownItem
                            onClick={onClickDomainSelect.bind(
                              this,
                              domain.domainInfo,
                              index
                            )}
                          >
                            {domain.domainInfo}
                          </CDropdownItem>
                        ))}
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                );
              },*/
              domains: (item, index) => {
                return (
                  <td>
                    <Select
                      placeholder="Select domains.."
                      value={item.domains.map((obj) => ({
                        label: obj,
                        value: obj,
                      }))}
                      options={domainData.map((domain) => ({
                        label: domain.domainInfo,
                        value: domain.domainInfo,
                      }))}
                      onChange={(evt) => handleDomainsSelectedChange(evt, item)}
                      isMulti
                      isClearable
                    />
                  </td>
                )
              },
              buttons: (item, index) => {
                return <td className="py-2">{columnCustomButtons(item, index)}</td>
              },
            }}
          />
        </CCardBody>
      </CCard>

      <Modal
        title="Create Domain"
        show={isShow[MODAL_CREATE_DOMAIN]}
        onHide={() => closeModal(MODAL_CREATE_DOMAIN)}
        onClickSave={onClickSaveDomain}
        body={
          <div className="container">
            <ModalInput
              name="domainInfo"
              label="Domain Name"
              onChange={(evt) => onChange(evt)}
              defaultValue={domain.domainInfo}
              error={inputError.domainInfoError}
            ></ModalInput>
            <ModalInput
              name="apiKey"
              label="API Key"
              onChange={(evt) => onChange(evt)}
              defaultValue={domain.apiKey}
              error={inputError.apiKeyError}
            ></ModalInput>
            <ModalInput
              name="apiHash"
              label="API Hash"
              onChange={(evt) => onChange(evt)}
              defaultValue={domain.apiHash}
              error={inputError.apiHashError}
            ></ModalInput>
            <ModalInput
              name="listId"
              label="List ID"
              onChange={(evt) => onChange(evt)}
              defaultValue={domain.listId}
              error={inputError.listIdError}
            ></ModalInput>
            <ModalInput
              name="listName"
              label="List Name"
              onChange={(evt) => onChange(evt)}
              defaultValue={domain.listName}
              error={inputError.listNameError}
            ></ModalInput>
          </div>
        }
      />

      <CModal onClosed={() => closeModal(MODAL_ADD_MAPPING)} show={isShow[MODAL_ADD_MAPPING]}>
        <CModalHeader closeButton>Add Mapping</CModalHeader>
        <CModalBody>
          <div className="container">
            <ModalSelect
              name="funnelName"
              label="Funnel Name"
              placeHolder="Select Funnel Name.."
              onChange={(evt) => onChangeSelect(evt, 'funnelName')}
              options={funnelNameList.map((item) => {
                return { value: item, label: item }
              })}
              value={selected.funnelName}
            />
            <ModalSelect
              name="funnelId"
              label="Funnel ID"
              placeHolder="Select Funnel ID.."
              onChange={(evt) => onChangeSelect(evt, 'funnelId')}
              options={funnelIdList.map((item) => {
                return { value: item, label: item }
              })}
              value={selected.funnelId}
            />
            <ModalMultiSelect
              name="domain"
              label="Domain"
              placeHolder="Select domain.."
              onChange={(evt) => onChangeMultiSelect(evt)}
              options={domainData.map((domain) => {
                return { value: domain.domainInfo, label: domain.domainInfo }
              })}
              value={domainsSelectedValue.map((item) => {
                return { value: item, label: item }
              })}
              isDisabled={isDisabledDomain}
              isClearable
              isMulti
            />
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={onClickAddMapping}>
            Save
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default FunnelCategoryMappingPage
