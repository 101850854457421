import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import numeral from 'numeral'
import {
  deleteCustomer,
  findCustomerWithAdvancedFilter,
  getCustomersWithPagination,
  unsubscribeMail,
} from '../api/apiCalls'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { selectFilter } from 'react-bootstrap-table2-filter'
import ConfirmModal from '../components/ConfirmModal'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupPrepend,
  CInputGroupText,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CSelect,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import { DateRangePicker } from 'react-dates'

toast.configure()
const CustomerPage = (props) => {
  const [selectedDeleteRow, setSelectedDeleteRow] = useState({})
  const [selectedUnSubscribeRow, setSelectedUnSubscribeRow] = useState({})
  const [deleteModalShow, setDeleteModelShow] = useState(false)
  const [unsubscribeModalShow, setUnsubscribeModalShow] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const { history } = props
  const { push } = history

  // Table Integration
  const [customers, setCustomers] = useState([])
  const [advancedCustomers, setAdvancedCustomers] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(20)
  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [tableFilterValue, setTableFilterValue] = useState('')

  // Advanced Filter state
  const [advancedPage, setAdvancedPage] = useState(0)
  const [advancedPages, setAdvancedPages] = useState(20)
  const [advancedItemsPerPage, setAdvancedItemsPerPage] = useState(100)
  const [advancedTableFilterValue, setAdvancedTableFilterValue] = useState('')

  // Filter State
  const [filter, setFilter] = useState({
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
    subscriptionType: null,
    spentAmount: 0,
  })
  const [focusedInput, setFocusedInput] = useState()
  const [activeTab, setActiveTab] = useState('customers')

  useEffect(() => {
    setNotFound(false)
  }, [])

  const { t } = useTranslation()

  useEffect(() => {
    refreshCustomerData()
  }, [page, itemsPerPage, tableFilterValue])

  useEffect(() => {
    search()
  }, [advancedPage, advancedItemsPerPage, advancedTableFilterValue])

  const deleteShow = () => setDeleteModelShow(true)
  const deleteHide = () => setDeleteModelShow(false)
  const unsubscribeShow = () => setUnsubscribeModalShow(true)
  const unsubscribeHide = () => setUnsubscribeModalShow(false)

  const csvContent = customers.map((item) => Object.values(item).join(',')).join('\n')
  const csvCode = 'data:text/csv;charset=utf-8,SEP=,%0A' + encodeURIComponent(csvContent)

  const deleteModalClose = () => {
    deleteHide()
  }

  const unsubscribeModalClose = () => {
    unsubscribeHide()
  }

  const refreshCustomerData = () => {
    const loadCustomers = async () => {
      setLoading(true)
      try {
        const response = await getCustomersWithPagination(tableFilterValue, page, itemsPerPage)
        setPages(response.data.totalPages)
        setCustomers(response.data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        notify('Not found any data', 'e')
      }
    }
    loadCustomers()
  }

  const handleEditCustomer = (clickStatus, row) => {
    if (row.customerStatus === 'active') {
      push(`/customerUpdate/${row._id}`)
    }
    // <Link to={`/customerUpdate/${row._id}`} key={row._id}></Link>
  }

  const handleDeleteCustomer = () => {
    const onDeleteCustomer = async (row) => {
      try {
        const response = await deleteCustomer(row._id)
        notify(response.data.message, 's')
        deleteModalClose()
        refreshCustomerData()
      } catch (error) {
        deleteModalClose()
        setNotFound(true)
        notify('Customer deletion could not be completed!', 'e')
      }
    }
    onDeleteCustomer(selectedDeleteRow)
  }

  const handleUnsubscribeMail = () => {
    const onUnsubscribeMail = async (row) => {
      try {
        const response = await unsubscribeMail(row._id)
        if (response.data.code === 's') {
          unsubscribeModalClose()
          refreshCustomerData()
        }
        notify(response.data.message, response.data.code)
      } catch (error) {
        unsubscribeModalClose()
        setNotFound(true)
        notify('Unsubscription could not be completed!', 'e')
      }
    }
    onUnsubscribeMail(selectedUnSubscribeRow)
  }

  const onClickTypeChange = (evt) => {
    let type = evt.target.value
    if (type !== '0') {
      setFilter({
        ...filter,
        subscriptionType: type,
      })
    } else {
      setFilter({
        ...filter,
        subscriptionType: null,
      })
    }
  }

  const onChangeSpentAmount = (evt) => {
    setFilter({
      ...filter,
      spentAmount: parseInt(evt.target.value),
    })
  }

  const search = () => {
    if (activeTab === 'advanced_search') {
      const advancedSearch = async () => {
        let fields = {
          startDate: filter.startDate,
          endDate: filter.endDate,
          subscriptionType: filter.subscriptionType,
          spentAmount: filter.spentAmount,
        }

        setLoading(true)
        try {
          const response = await findCustomerWithAdvancedFilter(
            advancedTableFilterValue,
            advancedPage,
            advancedItemsPerPage,
            fields,
          )
          setAdvancedPages(response.data.totalPages)
          setAdvancedCustomers(response.data.data)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          notify('Not found any data', 'e')
        }
      }
      advancedSearch()
    }
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const columnCustomButtons = (row) => {
    return (
      <ButtonGroup sizeclassname="btn-group-sm">
        <div>
          <button
            className="btn btn-success"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit Customer"
            disabled={row.customerStatus === 'not_active' ? true : false}
            onClick={(evt) => {
              handleEditCustomer(true, row)
            }}
          >
            <CIcon size="lg" name="cil-pencil" />
          </button>
        </div>
        <Link to={`/transactionDetail/${row.subscriptionId}`} key={row.subscriptionId}>
          <div>
            <button
              className="btn btn-primary"
              data-toggle="tooltip"
              data-placement="top"
              title="Customer Transactions"
            >
              <CIcon size="lg" name="cil-wallet" />
            </button>
          </div>
        </Link>
        <button
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Customer"
          disabled={row.customerStatus === 'not_active' ? true : false}
          size="sm"
          onClick={(evt) => {
            setSelectedDeleteRow(row)
            deleteShow()
          }}
        >
          <CIcon size="lg" name="cil-x-circle" />
        </button>
        <Link to={`/customerModifications/${row.subscriptionId}`} key={row._id}>
          <div>
            <button
              className="btn btn-info"
              data-toggle="tooltip"
              data-placement="top"
              title="Customer Change Log"
            >
              <CIcon size="lg" name="cilHistory" />
            </button>
          </div>
        </Link>
        <button
          className="btn btn-danger"
          data-toggle="tooltip"
          data-placement="top"
          title="Unsubscribe Mail"
          disabled={row.espStatus === 'sent' ? false : true}
          size="sm"
          onClick={(evt) => {
            setSelectedUnSubscribeRow(row)
            unsubscribeShow()
          }}
        >
          <CIcon size="lg" name="cil-user-unfollow" />
        </button>
      </ButtonGroup>
    )
  }

  const statusBadge = (row) => {
    if (row.customerStatus === 'active') {
      return <span className="badge badge-pill badge-success">ACTIVE</span>
    } else if (row.customerStatus === 'not_active') {
      return <span className="badge badge-pill badge-danger">DEACTIVE</span>
    }
  }

  const selectCustomerStatusOptions = {
    active: 'Active',
    not_active: 'Deactive',
  }

  const selectPaymentSystemOptions = {
    easypay: 'Easypay',
    stripe: 'Stripe',
  }

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Customers not found!')}
        </div>
      </div>
    )
  }

  const fields = [
    { key: 'firstName', _style: { width: '10%' } },
    { key: 'lastName', _style: { width: '10%' } },
    { key: 'email', _style: { width: '10%' } },
    { key: 'phone', _style: { width: '10%' } },
    { key: 'customerStatus', _style: { width: '10%' } },
    { key: 'paymentSystem', _style: { width: '10%' } },
    { key: 'spentAmount', _style: { width: '10%' } },
    {
      key: 'buttons',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ]

  return (
    <div className="ml-auto" style={{ padding: '40px' }}>
      <CTabs activeTab="customers" onActiveTabChange={setActiveTab}>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab="customers">Customers</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab="advanced_search">Advanced Search</CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <CTabPane data-tab="customers">
            <CCard>
              <CCardBody>
                <CDataTable
                  items={customers}
                  fields={fields}
                  loading={loading}
                  hover
                  cleaner
                  tableFilter={{ external: true }}
                  tableFilterValue={tableFilterValue}
                  onTableFilterChange={setTableFilterValue}
                  sorter
                  itemsPerPageSelect={{
                    label: 'Items per page:',
                    values: [10, 20, 50, 100],
                  }}
                  itemsPerPage={itemsPerPage}
                  onPaginationChange={setItemsPerPage}
                  scopedSlots={{
                    buttons: (row) => <td className="py-2">{columnCustomButtons(row)}</td>,
                    customerStatus: (row) => <td className="py-2">{statusBadge(row)}</td>,
                  }}
                  columnFilterSlot={{
                    customerStatus: selectFilter({
                      options: selectCustomerStatusOptions,
                    }),
                    paymentSystem: selectFilter({
                      options: selectPaymentSystemOptions,
                    }),
                  }}
                ></CDataTable>
                <CPagination
                  size="sm"
                  pages={pages}
                  activePage={page}
                  onActivePageChange={setPage}
                  className={pages < 2 ? 'd-none' : ''}
                />
              </CCardBody>
            </CCard>
          </CTabPane>
          <CTabPane data-tab="advanced_search">
            <CCard>
              <CCardHeader>
                <CForm inline>
                  <DateRangePicker
                    startDate={filter.startDate}
                    startDateId="startDate"
                    endDate={filter.endDate}
                    endDateId="endDate"
                    isOutsideRange={() => null}
                    onDatesChange={({ startDate, endDate }) =>
                      setFilter({
                        ...filter,
                        startDate: startDate,
                        endDate: endDate,
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  />
                  <CInputGroup className="input-prepend mr-2 ml-2">
                    <CInputGroupPrepend>
                      <CInputGroupText>$</CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      id="appendedPrependedInput"
                      size="16"
                      type="number"
                      placeholder="Enter spent amount.."
                      onChange={onChangeSpentAmount.bind()}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText>.00</CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <CSelect custom className="mr-5" onChange={onClickTypeChange.bind()}>
                    <option value="0">Select subscription type..</option>
                    <option value="onetime">Onetime</option>
                    <option value="membership">Membership</option>
                  </CSelect>
                  <CButton className="text-right" color="info" onClick={() => search()}>
                    Search
                  </CButton>
                </CForm>
              </CCardHeader>
              <CCardBody>
                <CButton
                  size="sm"
                  color="primary"
                  className="mb-2"
                  href={csvCode}
                  download="customers.csv"
                  target="_blank"
                >
                  <CIcon inline className="mr-1" name="cil-cloud-download" size="lg" />
                  Download current items (.csv)
                </CButton>
                <CDataTable
                  items={advancedCustomers}
                  fields={fields}
                  loading={loading}
                  hover
                  cleaner
                  tableFilter={{ external: true }}
                  tableFilterValue={advancedTableFilterValue}
                  onTableFilterChange={setAdvancedTableFilterValue}
                  sorter
                  itemsPerPageSelect={{
                    label: 'Items per page:',
                    values: [10, 20, 50, 100],
                  }}
                  itemsPerPage={advancedItemsPerPage}
                  onPaginationChange={setAdvancedItemsPerPage}
                  scopedSlots={{
                    buttons: (row) => <td className="py-2">{columnCustomButtons(row)}</td>,
                    customerStatus: (row) => <td className="py-2">{statusBadge(row)}</td>,
                    spentAmount: (row) => (
                      <td className="py-2">{numeral(row.spentAmont).format('$ 0.00 a')}</td>
                    ),
                  }}
                  columnFilterSlot={{
                    customerStatus: selectFilter({
                      options: selectCustomerStatusOptions,
                    }),
                    paymentSystem: selectFilter({
                      options: selectPaymentSystemOptions,
                    }),
                  }}
                ></CDataTable>
                <CPagination
                  size="sm"
                  pages={advancedPages}
                  activePage={advancedPage}
                  onActivePageChange={setAdvancedPage}
                  className={advancedPages < 2 ? 'd-none' : ''}
                />
              </CCardBody>
            </CCard>
          </CTabPane>
        </CTabContent>
      </CTabs>
      <ConfirmModal
        title="Delete Member"
        show={deleteModalShow}
        onHide={deleteModalClose}
        onClickYes={handleDeleteCustomer}
        onClickNo={deleteModalClose}
        body={<div className="container">Are you sure you want to do this?</div>}
      ></ConfirmModal>
      <ConfirmModal
        title="Unsubscribe Mail"
        show={unsubscribeModalShow}
        onHide={unsubscribeModalClose}
        onClickYes={handleUnsubscribeMail}
        onClickNo={unsubscribeModalClose}
        body={<div className="container">Are you sure you want to unsubscribe from ESP?</div>}
      ></ConfirmModal>{' '}
    </div>
  )
}

export default CustomerPage
