import axios from 'axios'
import * as PATH from './PathConstants'

export const changeLanguage = (language) => {
  axios.defaults.headers['accept-language'] = language
}

export const login = (creds) => {
  return axios.post(PATH.API_SIGNIN, creds, null)
}

export const signup = (body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_SIGNUP, body, null)
}

export const setAuthorizationHeader = ({ type, token, isLoggedIn }) => {
  if (isLoggedIn) {
    const authorizationHeaderValue = `${type} ${token}`
    axios.defaults.headers['Authorization'] = authorizationHeaderValue
  } else {
    delete axios.defaults.headers['Authorization']
  }
}

// Subscription API
export const getSubscriptions = () => {
  return axios.get(PATH.API_SUBSCRPTIONS)
}

export const findSubscriptionWithPagination = (search, page, size) => {
  return axios.get(
    `${PATH.API_SUBSCRPTIONS_PAGE_FILTER}?search=${search}&page=${page}&size=${size}`,
  )
}

export const findSubscriptionWithAdvancedFilter = (search, page, size, body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(
    `${PATH.API_SUBSCRPTIONS_PAGE_FILTER_ADVANCED}?search=${search}&page=${page}&size=${size}`,
    body,
  )
}

export const getSubscriptionsWithFilter = (body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_SUBSCRIPTION_FILTER, body)
}

export const getSubscriptionsForShipmentWithFilter = (body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_SUBSCRPTION_SHIPMENT_FILTER, body)
}

export const getSubscriptionsWithFilterForReports = (body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_SUBSCRIPTION_FILTER_REPORTS, body)
}

export const updateSubscription = (subscription) => {
  return axios.post(PATH.API_SUBSCRPTIONS, subscription)
}

export const subscriptionsUpload = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(PATH.API_UPLOAD_SUBSCRPTIONS, formData)
}

export const findSubscriptionById = (id) => {
  return axios.get(`${PATH.API_FIND_BY_ID}/${id}`)
}

export const cancelSubscriptionById = (id) => {
  return axios.delete(`${PATH.API_FIND_BY_ID}/${id}`)
}

export const findTransactionsBySubscriptionId = (subscriptionId) => {
  return axios.get(`${PATH.API_SUBSCRIPTION_TRANSACTIONS}/${subscriptionId}`)
}

// Shipstation API
export const sendSubscriptionToShipstation = (id) => {
  return axios.get(`${PATH.API_SHIPSTATION}?id=${id}`)
}

export const createShipStationMultipleOrder = (ids) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_SHIPSTATION_CREATE_MULTIPLE_ORDERS, ids, {
    timeout: 60 * 60 * 1000,
  })
}

// Customer API
export const getCustomersWithPagination = (search, page, size) => {
  return axios.get(`${PATH.API_CUSTOMERS_FILTER}?search=${search}&page=${page}&size=${size}`)
}

export const findCustomerWithAdvancedFilter = (search, page, size, body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(
    `${PATH.API_CUSTOMERS_FILTER_ADVANCED}?search=${search}&page=${page}&size=${size}`,
    body,
  )
}

export const findCustomer = (id) => {
  return axios.get(`${PATH.API_FIND_CUSTOMER}/${id}`)
}

export const updateCustomer = (customer) => {
  return axios.post(PATH.API_CUSTOMER_UPDATE, customer)
}

export const deleteCustomer = (id) => {
  return axios.delete(`${PATH.API_FIND_CUSTOMER}/${id}`)
}

export const findCustomerModifications = (subscriptionId) => {
  return axios.get(`${PATH.API_FIND_CUSTOMER_MODIFICATIONS}/${subscriptionId}`)
}

export const unsubscribeMail = (id) => {
  return axios.get(`${PATH.API_CUSTOMER_UNSUBSCRIBE_MAIL}/${id}`)
}

// Product API
export const createProduct = (product) => {
  return axios.put(PATH.API_PRODUCT_CREATE, product)
}
export const updateProduct = (product) => {
  return axios.post(PATH.API_PRODUCT_UPDATE, product)
}
export const labelUpdate = (product) => {
  return axios.post(PATH.API_PRODUCT_UPDATE_LABEL, product)
}
export const deleteProduct = (id) => {
  return axios.delete(`${PATH.API_PRODUCT_DELETE}/${id}`)
}
export const findProducts = () => {
  return axios.get(PATH.API_PRODUCTS)
}

// Funnel API
export const findFunnels = () => {
  return axios.get(PATH.API_FUNNEL_FIND_ALL)
}

export const updateFunnel = (funnel) => {
  return axios.post(PATH.API_FUNNEL_UPDATE, funnel)
}

export const uploadProducts = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(PATH.API_PRODUCTS_UPLOAD, formData)
}

export const downloadProducts = () => {
  return axios.get(PATH.API_PRODUCTS_DOWNLOAD)
}

export const findFunnelMapping = () => {
  return axios.get(PATH.API_PRODUCT_MAPPING)
}

export const productLabelOperation = (event, label) => {
  return axios.post(`${PATH.API_PRODUCT_LABEL}/${event}/${label}`)
}

// Sku API
export const insertSku = (sku) => {
  return axios.put(PATH.API_SKU_CREATE, sku)
}

export const findSkus = () => {
  return axios.get(PATH.API_SKU_FIND_ALL)
}

export const deleteSku = (id) => {
  return axios.delete(`${PATH.API_SKU_DELETE}/${id}`)
}

export const updateSku = (sku) => {
  return axios.post(PATH.API_SKU_UPDATE, sku)
}

// Scheduler API
export const scheduleJob = (scheduleRequest) => {
  return axios.post(PATH.API_SCHEDULER_JOB, scheduleRequest)
}

// Job API
export const findJobs = () => {
  return axios.get(PATH.API_JOB_FIND_JOBS)
}

export const findJobById = (id) => {
  return axios.get(`${PATH.API_JOB_FIND_BY_ID}/${id}`)
}

// Job History API
export const findJobHistory = () => {
  return axios.get(PATH.API_JOB_HISTORY_FIND_JOB_HISTORIES)
}

export const findJobHistoryById = (id) => {
  return axios.get(`${PATH.API_JOB_HISTORY_FIND_BY_ID}/${id}`)
}

export const deleteJobHistory = (id) => {
  return axios.delete(`${PATH.API_JOB_HISTORY_DELETE}/${id}`)
}

// Check Subscriptions API
export const updateView = (updateList) => {
  return axios.post(PATH.API_CHECK_SUBSCRIPTION_UPDATE_VIEW, updateList)
}

export const getCheckSubscriptions = () => {
  return axios.get(PATH.API_CHECK_SUBSCRIPTIONS)
}

export const findCheckSubscriptionWithPagination = (search, page, size) => {
  return axios.get(
    `${PATH.API_CHECK_SUBSCRIPTIONS_PAGE_FILTER}?search=${search}&page=${page}&size=${size}`,
  )
}

// Document API
export const uploadDocument = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(PATH.API_DOCUMENT_UPLOAD, formData)
}

// Report Api
export const downloadReports = (body) => {
  return axios.post(PATH.API_REPORT_DOWNLOAD_REPORTS, body, {
    responseType: 'blob',
    timeout: 60 * 60 * 1000,
  })
}

export const uploadReports = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(PATH.API_REPORT_UPLOAD_REPORTS, formData)
}

export const sendReportsViaMail = (body) => {
  return axios.post(PATH.API_SEND_REPORTS_VIA_MAIL, body)
}

export const updateMaintenanceReport = (body) => {
  return axios.post(PATH.API_REPORT_MAINTENANCE, body)
}

export const findUpdateValues = () => {
  return axios.post(PATH.API_REPORT_MAINTENANCE_LIST)
}

// Domain Mapping API
export const findMappings = () => {
  return axios.get(PATH.API_MAPPING)
}

export const updateMapping = (mapping) => {
  return axios.post(PATH.API_MAPPING_UPDATE, mapping)
}

// Sendlane API
export const findDomains = () => {
  return axios.get(PATH.API_SENDLANE_DOMAIN_FIND_ALL)
}

export const saveDomain = (domain) => {
  return axios.put(PATH.API_SENDLANE_DOMAIN_SAVE, domain)
}

export const saveFunnel = (funnel) => {
  return axios.put(PATH.API_SENDLANE_FUNNEL_SAVE, funnel)
}

// Klaviyo API
export const findKlaviyoListData = (account) => {
  return axios.get(`${PATH.API_KLAVIYO_LIST_FIND_ALL}/${account}/lists`)
}

// Active Campaign API
export const findActiveCampaignListData = () => {
  return axios.get(PATH.API_ACTIVE_CAMPAIGN_LIST_FIND_ALL)
}

// Maropost API
export const findMaropostListData = (account) => {
  return axios.get(`${PATH.API_MAROPOST_LIST_FIND_ALL}?account=${account}`)
}

// Dashboard API
export const searchDashboard = (body) => {
  axios.defaults.headers['Content-Disposition'] = 'form-data'
  axios.defaults.headers['Content-Type'] = 'application/json'
  return axios.post(PATH.API_DASHBOARD, body, { timeout: 60 * 60 * 1000 })
}

// Bucket API
export const uploadBuckets = (type, file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(`${PATH.API_BUCKET_UPLOAD}/${type}`, formData)
}

export const bucketJobOperation = (no, operation, changedSettings) => {
  return axios.post(`${PATH.API_BUCKET_JOB_OPERATION}/${no}/${operation}`, changedSettings)
}

export const deleteBucketsByBucketNo = (bucketNo) => {
  return axios.delete(`${PATH.API_BUCKET_DELETE_ALL_BUCKETS}/${bucketNo}`)
}

// Backup API
export const uploadBackup = (label, file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(`${PATH.API_BACKUP_UPLOAD}/${label}`, formData)
}

export const backupLabelOperation = (event, label) => {
  return axios.post(`${PATH.API_BACKUP_LABEL}/${event}/${label}`)
}

export const findBackupData = (query) => {
  return axios.post(PATH.API_BACKUP_FIND_ALL, query)
}

// Email Block API
export const findEmailBlockData = (query) => {
  return axios.post(PATH.API_EMAIL_BLOCK_FIND_ALL, query)
}

export const deleteBlockById = (id) => {
  return axios.delete(`${PATH.API_EMAIL_BLOCK_DELETE_BY_ID}/${id}`)
}

export const createBlockEmail = (data) => {
  return axios.put(PATH.API_EMAIL_BLOCK_INSERT, data)
}

export const uploadEmailBlock = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(`${PATH.API_EMAIL_BLOCK_UPLOAD}`, formData)
}

// Settings API
export const findSettings = () => {
  return axios.get(PATH.API_SETTINGS_FIND_ALL)
}

export const findBucketSettings = (key) => {
  return axios.get(`${PATH.API_SETTINGS_BUCKET_FIND_BY_KEY}/${key}`)
}

export const updateSettings = (setting) => {
  return axios.post(PATH.API_SETTINGS_UPDATE, setting)
}

export const findSettingsByType = (type) => {
  return axios.get(`${PATH.API_SETTINGS_FIND_BY_TYPE}/${type}`)
}

// Campaign API
export const createCampaign = (data) => {
  return axios.post(PATH.API_CAMPAIGN_CREATE_CAMPAIGN, data)
}

export const uploadContact = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(`${PATH.API_CAMPAIGN_CONTACT_UPLOAD}`, formData)
}

export const findMemberBySegment = (segment) => {
  return axios.get(`${PATH.API_CAMPAIGN_FIND_MEMBER_BY_SEGMENT}/${segment}`)
}

export const findCampaignData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_LIST_FIND_ALL, query)
}

export const findSmsDashboardData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_SMS_DASHBOARD, query)
}

// Contact API
export const findContactData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_CONTACT_FIND_ALL, query)
}

export const unsubscribeContact = (id) => {
  return axios.get(`${PATH.API_CAMPAIGN_CONTACT_UNSUBSCRIBE_CONTACT}/${id}`)
}

// ApeSurvival Campaign API
export const createApeSurvivalCampaign = (data) => {
  return axios.post(PATH.API_CAMPAIGN_APESURVIVAL_CREATE_CAMPAIGN, data)
}

export const uploadApeSurvivalContact = (file) => {
  let formData = new FormData()
  formData.append('file', file)
  return axios.post(`${PATH.API_CAMPAIGN_APESURVIVAL_CONTACT_UPLOAD}`, formData)
}

export const findApeSurvivalMemberBySegment = (segment) => {
  return axios.get(`${PATH.API_CAMPAIGN_APESURVIVAL_FIND_MEMBER_BY_SEGMENT}/${segment}`)
}

export const findApeSurvivalCampaignData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_APESURVIVAL_LIST_FIND_ALL, query)
}

export const findApeSurvivalSmsDashboardData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_APESURVIVAL_SMS_DASHBOARD, query)
}

// ApeSurvival Contact API
export const findApeSurvivalContactData = (query) => {
  return axios.post(PATH.API_CAMPAIGN_APESURVIVAL_CONTACT_FIND_ALL, query)
}

export const unsubscribeApeSurvivalContact = (id) => {
  return axios.get(`${PATH.API_CAMPAIGN_APESURVIVAL_CONTACT_UNSUBSCRIBE_CONTACT}/${id}`)
}

// Failed Transaction API
export const updateFailedTransactionAction = (id, action) => {
  return axios.post(`${PATH.API_FAILED_TRANSACTION_ACTION_UPDATE}/${id}/action/${action}`)
}

export const findFailedTransactionData = (filter) => {
  return axios.post(PATH.API_FAILED_TRANSACTION_FILTER, filter)
}

export const findActionCountsData = () => {
  return axios.get(PATH.API_FAILED_TRANSACTION_ACTION_COUNT)
}
