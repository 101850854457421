import React, { useEffect, useState } from 'react'
import Input from '../components/Input'
import { useParams } from 'react-router-dom'
import { findCustomer, updateCustomer } from '../api/apiCalls'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import Spinner from '../components/Spinner'
import ButtonWithProgress from '../components/ButtonWithProgress'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
const CustomerUpdatePage = (props) => {
  const [customer, setCustomer] = useState({})
  const routeParams = useParams()
  const customerId = routeParams.id
  const { history } = props
  const { push } = history

  useEffect(() => {
    findCustomerById()
  }, [])

  const pendingApiCall = useApiProgress(API.API_FIND_CUSTOMER)
  const pendingApiCall2 = useApiProgress(API.API_CUSTOMER_UPDATE)

  if (pendingApiCall || pendingApiCall2) {
    return <Spinner />
  }

  const findCustomerById = async () => {
    try {
      const response = await findCustomer(customerId)
      setCustomer(response.data.data)
      console.log(response)
    } catch (error) {}
  }

  const onChange = (event) => {
    const { name, value } = event.target
    setCustomer((customer) => ({ ...customer, [name]: value }))
  }

  const onClickUpdate = async () => {
    try {
      const response = await updateCustomer(customer)
      notify(response.data.message, 's')
      if (response.data.code === '0013') {
        push('/customer')
      }
    } catch (error) {}
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div className="container">
      <h2>Customer Details</h2>
      <form className="needs-validation">
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <Input
              name="firstName"
              label="First Name"
              onChange={onChange}
              defaultValue={customer.firstName}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input
              name="lastName"
              label="Last Name"
              onChange={onChange}
              defaultValue={customer.lastName}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              name="address1"
              label="Address 1"
              onChange={onChange}
              defaultValue={customer.address1}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Input
              name="address2"
              label="Address 2"
              onChange={onChange}
              defaultValue={customer.address2}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-3">
            <Input name="city" label="City" onChange={onChange} defaultValue={customer.city} />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="addressState"
              label="State"
              onChange={onChange}
              defaultValue={customer.addressState}
            />
          </div>
          <div className="col-md-3 mb-3">
            <Input
              name="country"
              label="Country"
              onChange={onChange}
              defaultValue={customer.country}
            />
          </div>
          <div className="col-md-6 mb-3">
            <Input name="phone" label="Phone" onChange={onChange} defaultValue={customer.phone} />
          </div>
          <div className="col-md-6 mb-3">
            <Input name="email" label="E-Mail" onChange={onChange} defaultValue={customer.email} />
          </div>
          <div className="col-md-6 mb-3">
            <Input name="zip" label="Zip" onChange={onChange} defaultValue={customer.zip} />
          </div>
        </div>
        <div>
          <ButtonWithProgress
            className="btn btn-primary d-inline-flex"
            onClick={onClickUpdate}
            disabled={pendingApiCall2}
            pendingApiCall={pendingApiCall2}
            text={<>Update Customer</>}
          ></ButtonWithProgress>
        </div>
      </form>
    </div>
  )
}

export default CustomerUpdatePage
