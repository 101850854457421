import React from 'react'
import { useSelector } from 'react-redux'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import TopBar from '../components/TopBar'
import LoginPage from '../pages/LoginPage'
import UserSignupPage from '../pages/UserSignupPage'
import SubscriptionsPage from '../pages/SubscriptionsPage'
import ShipstationUpdate from '../pages/ShipstationUpdate'
import SubscriptionsUpdatePage from '../pages/SubscriptionUpdate'
import TransactionDetailPage from '../pages/TransactionDetailPage'
import Dashboard from '../pages/Dashboard'
import CustomerPage from '../pages/CustomerPage'
import CustomerUpdatePage from '../pages/CustomerUpdatePage'
import CustomerModificationsPage from '../pages/CustomerModificationsPage'
import ProductPage from '../pages/ProductPage'
import SchedulerPage from '../pages/SchedulerPage'
import CheckSystemPage from '../pages/CheckSystemPage'
import ReportPage from '../pages/ReportPage'
import ReportsMaintenance from '../pages/ReportsMaintenance'
import FunnelCategoryMappingPage from '../pages/FunnelCategoryMappingPage'
import BucketPage from '../pages/BucketPage'
import EmailBackupPage from '../pages/EmailBackupPage'
import '../scss/style.scss'
import EmailBlockPage from '../pages/EmailBlockPage'
import { ROLE_ADMIN, ROLE_CUSTOMER_SERVICE } from '../constant/Roles'
import SmsCampaignPage from '../pages/SmsCampaignPage'
import FailedTransactionPage from '../pages/FailedTransaction'
import ContactUnsubscribePage from '../pages/ContactUnsubscribePage'
import SmsCampaignApeSurvivalPage from '../pages/SmsCampaignApeSurvivalPage'

const App = () => {
  const { isLoggedIn, roles } = useSelector((store) => ({
    isLoggedIn: store.isLoggedIn,
    roles: store.roles,
  }))

  return (
    <div>
      <Router>
        <TopBar />
        <Switch>
          <>
            {isLoggedIn && roles.indexOf(ROLE_ADMIN) !== -1 ? (
              <>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route path="/subscriptions" component={SubscriptionsPage} />
                <Route path="/shipstation" component={ShipstationUpdate} />
                <Route path="/subscriptionsUpdate/:id" component={SubscriptionsUpdatePage} />
                <Route
                  path="/transactionDetail/:subscriptionId"
                  component={TransactionDetailPage}
                />
                <Route path="/customer" component={CustomerPage} />
                <Route path="/customerUpdate/:id" component={CustomerUpdatePage} />
                <Route
                  path="/customerModifications/:subscriptionId"
                  component={CustomerModificationsPage}
                />
                <Route path="/product" component={ProductPage} />
                <Route path="/scheduler" component={SchedulerPage} />
                <Route path="/checkSystem" component={CheckSystemPage} />
                <Route path="/reportPage" component={ReportPage} />
                <Route path="/reportMaintenance" component={ReportsMaintenance} />
                <Route path="/funnelCategoryMapping" component={FunnelCategoryMappingPage} />
                <Route path="/bucket" component={BucketPage} />
                <Route path="/backup" component={EmailBackupPage} />
                <Route path="/emailblock" component={EmailBlockPage} />
                <Route path="/campaign" component={SmsCampaignPage} />
                <Route path="/apesurvivalCampaign" component={SmsCampaignApeSurvivalPage} />
                <Route path="/failedTransactions" component={FailedTransactionPage} />

                <Redirect to="/dashboard" />
              </>
            ) : (
              <>
                <Route path="/signup" component={UserSignupPage} />
                <Route path="/login" component={LoginPage} />
              </>
            )}

            {isLoggedIn && roles.indexOf(ROLE_CUSTOMER_SERVICE) !== -1 ? (
              <>
                <Route path="/emailblock" component={EmailBlockPage} />
                <Route path="/failedTransactions" component={FailedTransactionPage} />
                <Route path="/contact" component={ContactUnsubscribePage} />
                <Route path="/campaign" component={SmsCampaignPage} />
                <Route path="/apesurvivalCampaign" component={SmsCampaignApeSurvivalPage} />
                <Redirect to="/emailblock" />
              </>
            ) : (
              <Redirect to="/" />
            )}
          </>
        </Switch>
      </Router>
    </div>
  )
}

export default App
