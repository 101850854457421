import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

const ReviewMessage = (props) => {
  const { label, inputText, handleChange, rows, name, disabled } = props
  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Form>
            <Form.Group className="mb-3" controlId={name}>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                disabled={disabled}
                as="textarea"
                rows={rows}
                value={inputText}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ReviewMessage
