import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import {
  updateSubscription,
  sendSubscriptionToShipstation,
  createShipStationMultipleOrder,
  getSubscriptionsForShipmentWithFilter,
} from '../api/apiCalls'
import { useApiProgress } from '../shared/ApiProgress'
import * as API from '../api/PathConstants'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CCard, CCardBody, CCardHeader, CCardText, CRow, CFormGroup, CCol } from '@coreui/react'

toast.configure()
const ShipstationUpdate = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [notFound, setNotFound] = useState(false)
  const [apiMessage, setApiMessage] = useState()
  const [selectedRows, setSelectedRows] = useState([])

  const { ExportCSVButton } = CSVExport

  // Filter State
  const [filterDates, setFilterDates] = useState({
    startDate: moment().subtract(3, 'month'),
    endDate: moment(),
    shipstationStatus: 'waiting',
    subscriptionType: 'membership',
  })
  const [focusedInput, setFocusedInput] = useState()

  useEffect(() => {
    setNotFound(false)
    setSelectedRows([])
  }, [subscriptions])

  const pendingApiCall = useApiProgress(API.API_SUBSCRPTIONS)
  const pendingApiCall2 = useApiProgress(API.API_SHIPSTATION_CREATE_MULTIPLE_ORDERS)
  const pendingApiCall3 = useApiProgress(API.API_SUBSCRPTION_SHIPMENT_FILTER)

  useEffect(() => {
    handleFilterApply()
  }, [filterDates])

  const { t } = useTranslation()

  if (pendingApiCall || pendingApiCall2 || pendingApiCall3) {
    return <Spinner />
  }

  const updateSubscriptionRow = (row, column) => {
    if (column.dataField === 'warehouseDescription') {
      const update = async () => {
        try {
          const response = await updateSubscription(row)
          notify(response.data.message, 's')
          setApiMessage(response.data.message)
        } catch (error) {
          // Notification atılacak
          setNotFound(true)
        }
      }
      update()
    }
  }

  const sendMultipleToShipStation = async () => {
    try {
      const response = await createShipStationMultipleOrder(selectedRows)
      notify(response.data.message, 's')
      setApiMessage(response.data.message)
    } catch (error) {
      setNotFound(true)
    }
  }

  const handleSendShipStation = (clickStatus, row) => {
    if (clickStatus) {
      const sendShipStation = async () => {
        try {
          const response = await sendSubscriptionToShipstation(row.id)
          notify(response.data.message, 's')
          setApiMessage(response.data.message)
        } catch (error) {
          // Notification atılacak
          setNotFound(true)
        }
      }
      sendShipStation()
    }
  }

  const getActionButtonFormat = (cell, row) => {
    return (
      <div>
        <button
          type="button"
          disabled={
            row.shipstationStatus === 'sent' || row.shipstationStatus === 'delivery' ? true : false
          }
          className="btn btn-outline-primary btn-sm ts-buttom"
          size="sm"
          onClick={(evt) => {
            handleSendShipStation(true, row)
          }}
        >
          Send Shipstation
        </button>
      </div>
    )
  }

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row.id])
    } else {
      const index = selectedRows.indexOf(row.id)
      if (index > -1) {
        selectedRows.splice(index, 1)
      }
      setSelectedRows([...selectedRows])
    }
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id)
    if (isSelect) {
      setSelectedRows(ids)
    } else {
      setSelectedRows([])
    }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelectAll: handleOnSelectAll,
    onSelect: handleOnSelect,
  }

  const handleFilterApply = () => {
    const applyFilter = async () => {
      try {
        const response = await getSubscriptionsForShipmentWithFilter(filterDates)
        notify(response.data.message, 's')
        setSubscriptions(response.data.data)
      } catch (error) {}
    }
    applyFilter()
  }

  const handleShipstationChange = (event) => {
    const value = event.target.value
    setFilterDates({ ...filterDates, shipstationStatus: value })
  }

  const handleSubscriptionTypeChange = (event) => {
    const value = event.target.value
    setFilterDates({ ...filterDates, subscriptionType: value })
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const selectPaymentSystemOptions = {
    easypay: 'Easypay',
    stripe: 'Stripe',
  }

  const columns = [
    {
      dataField: 'id',
      text: 'No',
      editable: false,
      sort: true,
      headerStyle: (column, colIndex) => {
        return { width: '50px' }
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created Date',
      sort: true,
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      formatter: (cell) => {
        let dateObj = cell
        if (dateObj) {
          if (typeof cell !== 'object') {
            dateObj = new Date(cell)
          }
          return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${(
            '0' + dateObj.getUTCDate()
          ).slice(-2)}/${dateObj.getUTCFullYear()}`
        }
      },
      editor: {
        type: Type.DATE,
      },
    },
    {
      dataField: 'subscriptionId',
      text: 'Subscription Id',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '150px' }
      },
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
    },
    {
      dataField: 'productNames',
      text: 'Product',
      editable: false,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '200px' }
      },
    },
    {
      dataField: 'transactionFrom',
      text: 'Payment System',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      filter: selectFilter({
        options: selectPaymentSystemOptions,
      }),
    },
    {
      dataField: 'month',
      text: 'Month',
      editable: true,
      filter: textFilter(),
      headerStyle: (column, colIndex) => {
        return { width: '70px' }
      },
    },
    {
      dataField: 'sendShipStationbutton',
      text: '',
      editable: false,
      headerStyle: (column, colIndex) => {
        return { width: '100px' }
      },
      formatter: getActionButtonFormat,
    },
  ]

  const options = {
    sizePerPageList: [
      {
        text: '100',
        value: 100,
      },
      {
        text: '300',
        value: 300,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
      {
        text: 'All',
        value: subscriptions != null ? subscriptions.length : 0,
      },
    ],
  }

  if (notFound) {
    return (
      <div className="container">
        <div className="alert alert-danger text-center">
          <div>
            <i className="material-icons" style={{ fontSize: '48px' }}>
              error
            </i>
          </div>
          {t('Subscriptions not found!')}
        </div>
      </div>
    )
  }

  return (
    <div className="ml-auto" style={{ padding: '20px' }}>
      <div>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="3" style={{ width: '17%' }}>
                <CCardText>Date: </CCardText>
                <DateRangePicker
                  startDate={filterDates.startDate}
                  startDateId="startDate"
                  endDate={filterDates.endDate}
                  endDateId="endDate"
                  isOutsideRange={() => null}
                  onDatesChange={({ startDate, endDate }) =>
                    setFilterDates({
                      ...filterDates,
                      startDate: startDate,
                      endDate: endDate,
                    })
                  }
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                />
              </CCol>
              <CCol sm="2" style={{ width: '9%' }}>
                <CCardText>Shipstation Status: </CCardText>
                <select
                  className="btn btn-outline-primary btn-sm"
                  value={filterDates.shipstationStatus}
                  onChange={handleShipstationChange}
                >
                  <option value={'waiting'}>Waiting</option>
                  <option value={'sent'}>Sent</option>
                  <option value={'delivery'}>Delivery</option>
                </select>
              </CCol>
              <CCol sm="2">
                <CCardText>Order Type: </CCardText>
                <select
                  className="btn btn-outline-primary btn-sm"
                  value={filterDates.subscriptionType}
                  onChange={handleSubscriptionTypeChange}
                >
                  <option value={'onetime'}>Onetime</option>
                  <option value={'membership'}>Membership</option>
                </select>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <ToolkitProvider keyField="id" data={subscriptions} columns={columns} exportCSV search>
              {(props) => (
                <div>
                  <div className="row">
                    <div className="col-sm text-left">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={sendMultipleToShipStation}
                      >
                        Multiple Send Shipstation
                      </button>
                    </div>
                    <div className="col-sm text-right">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleFilterApply}
                      >
                        Refresh List
                      </button>
                    </div>
                  </div>

                  <hr />
                  <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
                  <hr />
                  <BootstrapTable
                    striped={true}
                    hover={true}
                    condensed={true}
                    pagination={paginationFactory(options)}
                    selectRow={selectRow}
                    filter={filterFactory()}
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      onStartEdit: (row, column, rowIndex, columnIndex) => {
                        console.log('start to edit!!!')
                      },
                      beforeSaveCell: (event, oldValue, newValue, row, column) => {
                        // Değişiklik yapmak istediğinize emin misiniz şeklinde onay alınabilecek bir süreç işler.
                        console.log('Before Saving Cell!!')
                      },
                      afterSaveCell: (oldValue, newValue, row, column) => {
                        updateSubscriptionRow(row, column)
                      },
                    })}
                    {...props.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </CCardBody>
        </CCard>
      </div>
    </div>
  )
}

export default ShipstationUpdate
