import React, { useState } from 'react'
import {
  subscriptionsUpload,
  uploadBuckets,
  uploadDocument,
  uploadProducts,
  uploadReports,
} from '../api/apiCalls'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
const UploadFiles = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [currentFile, setCurrentFile] = useState(undefined)
  const [progress, setProgress] = useState(0)
  const [message, setMessage] = useState('')
  const { page } = props

  /*constructor(props) {
		super(props);
		this.selectFile = this.selectFile.bind(this);
		this.upload = this.upload.bind(this);
	}*/

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
  }

  const upload = async () => {
    let currentFile = selectedFiles[0]
    setCurrentFile(currentFile)

    try {
      let response
      if (page === 'CheckSystemPage') {
        response = await uploadDocument(currentFile)
      } else if (page === 'SubscriptionsPage') {
        response = await subscriptionsUpload(currentFile)
      } else if (page === 'ProductPage') {
        response = await uploadProducts(currentFile)
      } else if (page === 'ReportPage') {
        response = await uploadReports(currentFile)
      } else if (page === 'BucketPage') {
        response = await uploadBuckets(currentFile)
      }
      setMessage(response.data.message)
      notify(response.data.message, 's')
    } catch (error) {
      setMessage('Could not upload the file!')
      notify('Could not upload the file!', 'e')
      setCurrentFile(undefined)
    }

    setCurrentFile(undefined)
  }

  const notify = (message, type) => {
    if (type === 's') {
      toast.success('Success: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'w') {
      toast.warn('Warning: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'e') {
      toast.error('Error: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (type === 'i') {
      toast.info('Info: ' + message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  return (
    <div>
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + '%' }}
          >
            {progress}%
          </div>
        </div>
      )}

      <label className="btn btn-default">
        <input type="file" onChange={selectFile} />
      </label>

      <button className="btn btn-success" disabled={!selectedFiles} onClick={upload}>
        Upload
      </button>
    </div>
  )
}

export default UploadFiles
