import * as ACTIONS from './Constants'

const defaultState = {
  isLoggedIn: false,
  username: undefined,
  password: undefined,
  email: undefined,
  token: undefined,
  type: undefined,
  roles: undefined,
}

// redux için state-reducer tanımı yapılıyor
// redux'a state gönderilmediğinde state = { ... defaultState} default atanıyor.
const authReducer = (state = { ...defaultState }, action) => {
  if (action.type === ACTIONS.LOGOUT_SUCCESS) {
    return defaultState
  } else if (action.type === ACTIONS.LOGIN_SUCCESS) {
    return {
      ...action.payload,
      isLoggedIn: true,
      roles: action.payload.roles,
      token: action.payload.token,
      type: action.payload.type,
    }
  }
  return state
}

export default authReducer
